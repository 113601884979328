import { Box, Tooltip } from '@mui/material';
import { VariantResponse } from 'common';
import { useTranslation } from 'react-i18next';

interface TableSingleWeightBodyProps {
	variant: VariantResponse;
	estimated?: boolean;
	remainingWeight?: number;
	weight: number;
	quantity: number;
}

export const TableSingleWeightBody = ({
	variant,
	estimated,
	remainingWeight,
	weight,
	quantity,
}: TableSingleWeightBodyProps) => {
	const { t } = useTranslation();

	if (!variant) return <div>0</div>;
	// const arrivedQty = rowData.orderProduct?.arrivedQty ?? 1;
	const disableTooltip = !estimated && !variant.variable;

	const singleWeight = (): number => {
		if (!variant.variable) {
			return weight;
		}

		return (remainingWeight ?? 1) / quantity;
	};

	return (
		<Tooltip
			title={t('inventory.estimatedWeight')}
			disableFocusListener={disableTooltip}
			disableHoverListener={disableTooltip}
			disableTouchListener={disableTooltip}
			placement='top'
		>
			<Box
				sx={{
					position: 'relative',
					'&:after':
						estimated || variant.variable
							? {
									content: '"*"',
									position: 'absolute',
									top: 0,
									right: '-0.5rem',
									display: 'flex',
							  }
							: {},
					cursor: disableTooltip ? 'default' : 'help',
				}}
			>
				{singleWeight().toLocaleString('ja-JP')}g
			</Box>
		</Tooltip>
	);
};

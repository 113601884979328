import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTask } from '@contexts/index';
import useOrders from '@hooks/useOrders';
import {
	ModifyOrderFieldValue,
	ModifyOrderFieldValues,
} from '@contexts/taskContext/config/modifyOrderFieldConfig';
import { InboundModifyOrderFieldItem } from './InboundModifyOrderFieldItem';

import { List, ListItem } from '@mui/material';

import useProducts from '@hooks/useProducts';
import { ContainerComponentProps } from '@components/taskworkflow/tasks/config';

export const InboundModifyOrderFieldContainer = ({ taskFieldId }: ContainerComponentProps) => {
	const { cachedValues, setTaskFieldValues, getTaskFieldValues, targetId } = useTask();
	const { orderList } = useOrders();
	const { variantList } = useProducts();

	const [init, setInit] = useState(false);

	const [values, setValues] = useState<Record<number, ModifyOrderFieldValue>>([]);

	const order = orderList.find((order) => order.id === targetId);

	/**
	 * This effect is used to set the initial values of the field
	 * If we have a stub value we use that, otherwise we calculate the total weight
	 * Note that the "totalWeight" is the weight of a single piece if we have a non variable product
	 * but it will be the expected total weight if we have a variable product (this can be modified here
	 * according to the receipt we get from the seller, that states expiration date and weight)
	 */
	useEffect(() => {
		const stubData = getTaskFieldValues<ModifyOrderFieldValue>(taskFieldId);
		if (stubData.length > 0 && !init) {
			setInit(true);
			const newValues: ModifyOrderFieldValues = [];
			stubData.forEach((stub) => {
				newValues[stub.orderProductId] = stub;
			});
			setValues(newValues);
		} else if (stubData.length <= 0) {
			if (order) {
				const newValues: ModifyOrderFieldValues = [];
				order.content?.forEach((content) => {
					let newValue: ModifyOrderFieldValue | null = null;
					const stub = stubData.find((s) => +s.orderProductId === content.variantId);
					if (stub !== undefined) {
						// If we have a stub value we need to use that
						console.log(content);
						newValue = {
							orderProductId: content.id ? +content.id : -1,
							quantity: content.orderQty ?? 0,
							expirationDate: stub.expirationDate ? stub.expirationDate : null,
							totalWeight: stub.totalWeight ?? content.weight ?? 0,
						};
					} else {
						// If we don't have a stub value we need to calculate the total weight
						// expiresAt is null by default since this will be the first time we set it
						const variant = variantList.find((v) => v.id === content.variantId);
						let totalWeight = 0;
						if (variant) {
							totalWeight = variant.variable
								? variant.weight * (content.orderQty ?? 1)
								: variant.weight;
						}
						newValue = {
							expirationDate: null,
							totalWeight,
							quantity: content.orderQty ?? 0,
							orderProductId: content.id ? +content.id : -1,
						};
						console.log('not stub: ', newValue);
					}

					if (content.id && typeof content.id === 'number') {
						newValues[content.id] = newValue;
					}
				});
				setValues(newValues);
			}
		}
	}, [cachedValues, order]);

	useEffect(() => {
		if (order) {
			const convertedNewValues: ModifyOrderFieldValues = Object.entries(values).map(
				([key, value]) => ({
					orderProductId: +key,
					quantity: value.quantity ?? 0,
					expirationDate: value.expirationDate
						? moment(value.expirationDate).startOf('day').toDate()
						: null,
					totalWeight: value.totalWeight ?? null,
				}),
			);
			setTaskFieldValues(taskFieldId, convertedNewValues);
		}
	}, [order, values]);

	const setValuesInContext = (values: ModifyOrderFieldValue) => {
		setValues((oldValues) => {
			oldValues[values.orderProductId ?? -1] = values;
			return { ...oldValues };
		});
	};

	return (
		<div style={{ padding: '1rem' }}>
			<List>
				{order?.content
					?.filter((c) => c.variantId !== undefined && c.variantId !== null)
					.map((content, it) => (
						<InboundModifyOrderFieldItem
							key={it}
							iterator={it}
							content={content}
							values={values[content.id ? +content.id : -1]}
							setValuesInContext={setValuesInContext}
						/>
					))}
				{/* <ListItem>
					<p>test</p>
				</ListItem> */}
			</List>
		</div>
	);
};

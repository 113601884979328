import { useLayout } from '@contexts/layoutContext/LayoutContext';
import useUsers from '@hooks/useUsers';
import { Button } from '@mui/material';
import { UserForm } from '@pages/Users/UserForm';
import { getI18n } from 'react-i18next';

interface Props {
	userId: number;
}

export const SelectableUserRowBodyTemplate = ({ userId }: Props) => {
	const { userList } = useUsers();
	const { pushPopup } = useLayout();

	const renderConditionalName = (name?: string, surname?: string) => {
		if (name != undefined && surname != undefined) {
			return getI18n().language === 'jp' ? `${surname} ${name}` : `${name} ${surname}`;
		} else if (name != undefined && surname == undefined) {
			return name;
		} else if (name == undefined && surname != undefined) {
			return surname;
		} else {
			return '';
		}
	};

	const user = userList.find((x) => x.id === userId);
	if (user != undefined) {
		const name = user.person?.name ?? 'name';
		const surname = user.person?.surname ?? 'surname';
		return (
			<Button
				onClick={() => {
					pushPopup(<UserForm forcedId={user.id} formAction='view' />, false, true);
				}}
			>
				{renderConditionalName(name, surname)}
			</Button>
		);
	}
	return <></>;
};

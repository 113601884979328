import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useOrders, useWarehouses, useTasks } from '@hooks/index';
import {
	ChainArchetypeResponse,
	OrderBaseChildRequest,
	OrderBaseChildrenRequest,
	OrderBaseChildrenSchema,
	OrderBaseCreateSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderDirectionType,
	OrderType,
	ShippingType,
	StorageType,
	TriggerRequest,
} from 'common';
import _, { uniqueId } from 'lodash';
import { Control, UseFormGetValues, UseFormHandleSubmit, useForm } from 'react-hook-form';
import {
	EnhancedOrderBaseChildRequest,
	OrderElement,
	OrderTypeConfig,
	SubOrderElement,
	orderConfigMap,
} from '@components/orders/config/orderTypeConfig';
import { OrderElementReturnItem } from '@components/orders/config/OrderElementReturnItem';
import moment from 'moment';
import { useAppSelector } from '@store/hooks';
import {
	ConfigValueType,
	DateArchetype,
	EnhancedOrderProduct,
	EnhancedOrderProducts,
	ParsedOffset,
	SubOrderPropertiesRequest,
} from './types';
import { parseOffset, updateProperty } from './utils';

interface OrderEditorContextType {
	renderType: 'standalone' | 'popup';
	popupId?: string;
	orderType: OrderType | null;
	control: Control<OrderBaseRequest>;
	subOrders: EnhancedOrderBaseChildRequest[];
	config: OrderTypeConfig | null;
	configValues: ConfigValueType | null;
	loading: boolean;
	currentChainArchetype: ChainArchetypeResponse | null;
	subOrdersChainData: Record<string, ChainArchetypeResponse | null>;
	infoElements: Record<string, JSX.Element>;
	infoElementsStatus: Record<string, boolean>;
	getValues: UseFormGetValues<OrderBaseRequest>;
	handleSubmit: UseFormHandleSubmit<OrderBaseRequest>;
	loadTemplate: (templateId: string | number | undefined) => void;
	changeOrderProduct: (data: EnhancedOrderProduct, subOrderCode: string, index: number) => void;
	changeVariants: (data: EnhancedOrderProducts, subOrderCode: string) => void;
	setSuborderProperties: (data: SubOrderPropertiesRequest) => void;
	deleteVariant: (index: number, subOrderCode: string) => void;
	addEmptyVariant: (subOrderCode: string) => void;
	addSubOrder: (subOrder: OrderBaseChildRequest) => void;
	removeSubOrder: (subOrderCode: string) => void;
	setInfoElementStatus: (uuid: string, status: boolean) => void;
}

interface OrderEditorContextProps {
	children: React.ReactNode;
	orderType: OrderType;
	// TODO: we should unify this to a single type like RenderType and use it in the form/table context etc...
	renderType?: 'standalone' | 'popup';
	popupId?: string;
}

const defaultContext: OrderEditorContextType = {
	renderType: 'standalone',
	orderType: null,
	control: {} as Control<OrderBaseRequest>,
	subOrders: [],
	config: null,
	configValues: null,
	loading: false,
	currentChainArchetype: null,
	subOrdersChainData: {},
	infoElements: {},
	infoElementsStatus: {},
	getValues: {} as UseFormGetValues<OrderBaseRequest>,
	handleSubmit: {} as UseFormHandleSubmit<OrderBaseRequest>,
	loadTemplate: () => console.warn('OrderEditorContext: no provider'),
	changeOrderProduct: () => console.warn('OrderEditorContext: no provider'),
	changeVariants: () => console.warn('OrderEditorContext: no provider'),
	setSuborderProperties: () => console.warn('OrderEditorContext: no provider'),
	deleteVariant: () => console.warn('OrderEditorContext: no provider'),
	addEmptyVariant: () => console.warn('OrderEditorContext: no provider'),
	addSubOrder: () => console.warn('OrderEditorContext: no provider'),
	removeSubOrder: () => console.warn('OrderEditorContext: no provider'),
	setInfoElementStatus: () => console.warn('OrderEditorContext: no provider'),
};

const OrderEditorContext = createContext<OrderEditorContextType>(defaultContext);

export const OrderEditorProvider: React.FC<OrderEditorContextProps> = ({
	children,
	orderType,
	renderType = 'standalone',
	popupId,
}) => {
	const chainArchetypes = useAppSelector((state) => state.tasks.chainArchetypes);

	const { templateList, orderList } = useOrders();
	const { warehouseList } = useWarehouses();
	const { getMostSpecificTrigger } = useTasks();

	const [config, setConfig] = useState<OrderTypeConfig | null>(null);
	const [configValues, setConfigValues] = useState<ConfigValueType>({});
	const [oldFormValues, setOldFormValues] = useState<OrderBaseRequest | null>(null);
	const [subOrders, setSubOrders] = useState<OrderBaseChildrenRequest>([]);

	const [subOrdersChainData, setSubOrderChainData] = useState<
		Record<string, ChainArchetypeResponse | null>
	>({});

	const [infoElements, setInfoElements] = useState<Record<string, JSX.Element>>({});
	const [infoElementsStatus, setInfoElementsStatus] = useState<Record<string, boolean>>({});

	const [triggerValues, setTriggerValues] = useState<TriggerRequest>({});
	const [currentChainArchetype, setCurrentChainArchetype] = useState<ChainArchetypeResponse | null>(
		null,
	);
	const [loading, setLoading] = useState(false);

	const { control, reset, getValues, setValue, handleSubmit, watch } = useForm<OrderBaseRequest>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			code: '000000',
			orderType: orderType ?? undefined,
			shippingType: ShippingType.notdefined,
			fromType: OrderDirectionType.merchant,
			fromId: undefined,
		},
	});

	/**
	 * Fetches the chain archetype of the current order.
	 * - This is used to calculate the Estimated Arrival Date of the order.
	 * - Finds the trigger that is most specific to the current order and returns
	 * the archetype that is associated with that trigger.
	 */
	const fetchChainArchetype = async () => {
		const values = getValues();
		setLoading(true);
		values.fromId = values.pickingSite ?? values.fromId;
		const trigger = await getMostSpecificTrigger({ ...values, id: -1, subtype: orderType });
		if (trigger) {
			const archetype = chainArchetypes.find((c) => c.triggerId === trigger.id);
			if (archetype) {
				if (archetype.estimateCompletionTime) {
					const offset = parseOffset(archetype.estimateCompletionTime);
					const newDate = new Date();
					if (offset.months) newDate.setMonth(newDate.getMonth() + offset.months);
					if (offset.weeks) newDate.setDate(newDate.getDate() + offset.weeks * 7);
					if (offset.days) newDate.setDate(newDate.getDate() + offset.days);
					// TODO: we need to have "start of day" in JST
					if (offset.hours) newDate.setHours(newDate.getHours() + offset.hours);
					if (offset.minutes) newDate.setMinutes(newDate.getMinutes() + offset.minutes);
					if (offset.seconds) newDate.setSeconds(newDate.getSeconds() + offset.seconds);

					if (offset.weekDay) {
						const weekDay = moment(newDate).isoWeekday(offset.weekDay);
						// if (weekDay.isBefore(moment(newDate))) {
						// 	weekDay.add(1, 'week');
						// }
						newDate.setDate(weekDay.date());
					}

					setValue('ead', newDate);
				}
				setCurrentChainArchetype(archetype);
			} else {
				setCurrentChainArchetype(null);
			}
		} else {
			setCurrentChainArchetype(null);
		}
		setLoading(false);
	};

	// TODO: fix the orderType thing
	const fetchSubChainArchetype = async (
		subOrder: EnhancedOrderBaseChildRequest,
	): Promise<DateArchetype | null> => {
		const fromType = getValues('toType');
		const fromId = getValues('toId');
		const toType = subOrder.toType;
		const toId = subOrder.toId;
		const subOrderCode = subOrder.code ?? '';

		if (toId === -1 || fromId === -1) {
			return { date: null, archetype: null };
		}

		const trigger = await getMostSpecificTrigger({
			fromType,
			fromId,
			toType,
			toId,
			id: -1,
			subtype: OrderType.Internal,
		});
		const subOrderChainData = subOrdersChainData[subOrderCode];
		const archetype = chainArchetypes.find((c) => c.triggerId === trigger?.id);

		if (archetype && trigger && subOrder && subOrderChainData?.triggerId !== trigger.id) {
			if (archetype.estimateCompletionTime) {
				const offset = parseOffset(archetype.estimateCompletionTime);
				const addOffset = (date: Date, offset: ParsedOffset) => {
					const newDate = new Date(date);
					if (offset.months) newDate.setMonth(newDate.getMonth() + offset.months);
					if (offset.weeks) newDate.setDate(newDate.getDate() + offset.weeks * 7);
					if (offset.days) newDate.setDate(newDate.getDate() + offset.days);
					if (offset.hours) newDate.setHours(newDate.getHours() + offset.hours);
					if (offset.minutes) newDate.setMinutes(newDate.getMinutes() + offset.minutes);
					if (offset.seconds) newDate.setSeconds(newDate.getSeconds() + offset.seconds);
					return newDate;
				};

				const mainDate = getValues('ead');
				let newDate;
				if (mainDate) {
					newDate = addOffset(new Date(mainDate), offset);
				} else {
					newDate = addOffset(new Date(), offset);
				}

				return { date: newDate, archetype };
			}
			return { date: null, archetype };
		}
		return null;
	};

	/**
	 * Fetches the info elements associated with the current order type.
	 * @param {OrderTypeConfig | null} config - the config of the current order type
	 * @returns {void}
	 * @sideeffect sets the infoElements and infoElementsStatus
	 */
	const fetchInfoElements = (config: OrderTypeConfig | null): void => {
		if (!config || !config.infoElements) {
			// console.log('no info elements');
			setInfoElements({});
			setInfoElementsStatus({});
			return;
		}
		const tempElements: Record<string, JSX.Element> = {};
		config.infoElements.forEach((El) => {
			if (El) {
				const uuid = uniqueId('info-');
				tempElements[uuid] = <El key={uuid} uuid={uuid} />;
			}
		});
		setInfoElements(tempElements);
	};

	/**
	 * Sets the info elements status.
	 * @param {string} uuid - the unique identifier of the info element.
	 * @param {boolean} status - whether the info element has entries or not.
	 */
	const setInfoElementStatus = (uuid: string, status: boolean) => {
		setInfoElementsStatus((oldStatus) => {
			const newStatus = { ...oldStatus, [uuid]: status };
			if (_.isEqual(oldStatus, newStatus)) {
				return oldStatus;
			}
			return newStatus;
		});
	};

	// Where all the bad things happen.
	// TODO: partially deprecate
	useEffect(() => {
		// console.log(watch());
		// First let's check if the form values have changed at all.
		if (_.isEqual(oldFormValues, getValues())) {
			return;
		}
		const newValues = getValues();

		// Let's start the dance to try and detach us from the old system.
		if (orderType === OrderType.Internal) {
			setOldFormValues(newValues);
			return;
		}

		// Do we have an Estimated Arrival Date for this order?
		const newTriggerValues: TriggerRequest = {
			fromType: newValues.fromType,
			fromId: newValues.pickingSite ?? newValues.fromId,
			toType: newValues.toType,
			toId: newValues.toId,
		};
		if (!_.isEqual(triggerValues, newTriggerValues)) {
			setTriggerValues(newTriggerValues);
			if (orderType === OrderType.Inbound || orderType === OrderType.Outbound) {
				fetchChainArchetype();
			}
		}

		// If shippingType or fromType has changed, we need to update the toSubType
		// element in the configValues.
		// This means that we need to understand the OrderDirectionType of 'toType' and
		// the StorageType of 'toId' to get the correct values for the toSubType.
		// TODO: in the future this will support multiple return types but for now we assume
		// that there is only one.
		if (
			newValues.shippingType !== oldFormValues?.shippingType ||
			newValues.fromType !== oldFormValues?.fromType
		) {
			setValue('toId', null);
			const shippingType = newValues.shippingType;
			const fromType = newValues.fromType;
			const subTypeElement = config?.headerElements[OrderElement.toSubType];
			if (subTypeElement && subTypeElement.isToSubTypeFunction() && fromType && shippingType) {
				const values = subTypeElement.value(fromType, shippingType);
				if (values) {
					setConfigValues((oldValues) => {
						return {
							...oldValues,
							[OrderElement.toSubType]: new OrderElementReturnItem(values),
						};
					});
				}
			}
			if (newValues.toId) {
				setValue('toId', newValues.toId);
			}
		}
		// We need to reset suborders in case the fromId has changed.
		// The available products will change depending on the fromId.
		// Also: if the fromType has changed, we need to update the shippingType.
		if (newValues.fromId !== oldFormValues?.fromId) {
			const shippingTypeElement = config?.headerElements[OrderElement.shippingType];
			let shippingValues: ShippingType[] | null = null;
			if (
				shippingTypeElement &&
				shippingTypeElement.isShippingTypeFunction() &&
				newValues.fromType
			) {
				// If the fromType is warehouse, we need to get the storageType of the warehouse
				// and feed it to the shippingTypeFunction
				if (newValues.fromType === OrderDirectionType.warehouse) {
					const warehouse = warehouseList.find((w) => w.id === newValues.fromId);
					if (warehouse) {
						shippingValues = shippingTypeElement.value(newValues.fromType, warehouse.storageType);
					}
				} else {
					// in other cases (e.g. merchant) we can just use the fromType
					shippingValues = shippingTypeElement.value(newValues.fromType);
				}
			}
			if (shippingValues) {
				// Let's set the first shipping type available to avoid shenanigans with
				// the select form.
				if (shippingValues.length > 0) {
					setValue('shippingType', shippingValues[0]);
				}
				setConfigValues((oldValues) => {
					return {
						...oldValues,
						[OrderElement.shippingType]: new OrderElementReturnItem(shippingValues),
					};
				});
			}
			setSubOrders([]);
		}
		// If the toType or toId has changed, we need to update the subOrderTypes
		if (newValues.toType !== oldFormValues?.toType || newValues.toId !== oldFormValues?.toId) {
			const toType = newValues.toType;
			const toId = newValues.toId;
			// TODO: let's assume for a moment that this is a warehouse type (it won't always be)
			// please come back and add more types depending on the toType
			const storageType = warehouseList.find((w) => w.id === toId)?.storageType;
			const subOrderTypesElement = config?.headerElements[OrderElement.subOrderTypes];
			if (
				subOrderTypesElement &&
				subOrderTypesElement.isSubOrderTypeFunction() &&
				toType &&
				storageType
			) {
				const values = subOrderTypesElement.value(toType, storageType);
				if (values) {
					setConfigValues((oldValues) => {
						return {
							...oldValues,
							[OrderElement.subOrderTypes]: new OrderElementReturnItem(values),
						};
					});
				}
			}
		}
		setOldFormValues(newValues);
	}, [watch()]);

	// Where other bad things happen.
	// TODO: partially deprecate
	useEffect(() => {
		reset();
		setSubOrders([]);

		const newConfig = orderType ? orderConfigMap[orderType] : null;
		setConfig(newConfig);
		fetchInfoElements(newConfig);

		let code = false;
		let bottomBorder = true;

		const bottomBorderElement = newConfig?.subOrderElements?.[SubOrderElement.borderBottom];
		if (newConfig && bottomBorderElement && bottomBorderElement.isBoolean()) {
			bottomBorder = bottomBorderElement.value;
		}

		// First let's check if this type of order needs a code or not.
		const codeElement = newConfig?.headerElements[OrderElement.code];
		if (newConfig && codeElement && codeElement.isBoolean()) {
			code = codeElement.value;
		}
		// Now let's check which OrderDirectionTypes are allowed for this orderType.
		let fromType: OrderDirectionType[] | undefined | null = [];
		let toType: OrderDirectionType[] | undefined | null = [];
		const fromTypeElement = newConfig?.headerElements[OrderElement.fromType];
		if (newConfig && fromTypeElement && fromTypeElement.isArrayofOrderDirectionType()) {
			fromType = fromTypeElement.value;
			// In case we have only one fromType, we can set it as the default value.
			if (fromType.length === 1) {
				setValue('fromType', fromType[0]);
				// Now let's check which OrderDirectionTypes are allowed for the toType.
				// We can do this only if we have a single fromType.
				// We'll leave the fromType and toType empty if we have multiple fromTypes.
				const toTypeElement = newConfig?.headerElements[OrderElement.toType];
				if (toTypeElement && toTypeElement.isToTypeFunction()) {
					// a bunch of shenanigans for type safety
					const tt =
						(toTypeElement.value(fromType[0]) as OrderDirectionType[]) ??
						([] as OrderDirectionType[]);
					toType = tt ?? ([] as OrderDirectionType[]);
					// In case we have only one toType, we can set it as the default value.
					if (toType.length === 1) {
						setValue('toType', toType[0]);
					}
				} else if (toTypeElement && toTypeElement.isArrayofOrderDirectionType()) {
					toType = toTypeElement.value;
					if (toType.length === 1) {
						setValue('toType', toType[0]);
					}
				}
			}
		}
		// Finally we set the values we already have in the form and the new values
		// we got from the config.
		setConfigValues({
			[OrderElement.code]: new OrderElementReturnItem(code),
			[OrderElement.fromType]: new OrderElementReturnItem(fromType),
			[OrderElement.toType]: new OrderElementReturnItem(toType),
			[SubOrderElement.borderBottom]: new OrderElementReturnItem(bottomBorder),
		});

		// Reset suborders since they wouldn't have the same config values anyway.
		if (orderType !== OrderType.Modify) {
			setSubOrders([]);
		}
	}, [orderType]);

	useEffect(() => {
		// TODO: testing the new inbound code system, this file is already cluttered
		// and needs cleaning, please move/think what to do later on
		if (orderType === OrderType.Inbound || orderType === OrderType.Outbound) {
			const latest = orderList
				.filter((o) => o.orderType === orderType)
				.reduce<OrderBaseResponse | null>(
					(max, current) => (max === null || current.id > max.id ? current : max),
					null,
				);

			let oldCode = 0;
			if (latest && latest.code) {
				const tempCode = parseInt(latest.code);
				oldCode = !isNaN(tempCode) ? tempCode : 0;
			}
			const newCode = oldCode + 1;

			setValue('code', newCode.toString());
		}
	}, [orderType, orderList]);

	// #region SubOrders
	// TODO: this should be called "content" and not "suborder" (suborder is a different thing
	// in the current system)
	/**
	 * Adds a suborder to the list of suborders.
	 * @param {OrderBaseChildRequest} subOrder - the suborder to add to the list
	 */
	const addSubOrder = (subOrder: OrderBaseChildRequest) => {
		subOrder.fromType = getValues('toType');
		if (!subOrder.toType) {
			const available = configValues[OrderElement.toType];
			if (available && available.isValueOfType<OrderDirectionType[]>()) {
				if (available.value.length > 0) {
					subOrder.toType = available.value[0];
				}
			}
		}
		setSubOrderChainData((oldData) => {
			if (subOrder.code) {
				const newChainData = { ...oldData };
				newChainData[subOrder.code] = null;
				return newChainData;
			}
			return oldData;
		});
		setSubOrders((oldSubOrders) => [...oldSubOrders, subOrder]);
	};

	// TODO: as above as below
	/**
	 * Removes a suborder from the list of suborders.
	 * @param {string} subOrderCode - the code of the suborder to remove
	 */
	const removeSubOrder = (subOrderCode: string) => {
		setSubOrders((oldSubOrders) => oldSubOrders.filter((so) => so.code !== subOrderCode));
		setSubOrderChainData((oldData) => {
			const newChainData = { ...oldData };
			delete newChainData[subOrderCode];
			return newChainData;
		});
	};

	// TODO: yup, change the name here too
	/**
	 * Sets properties of a suborder.
	 * @param {SubOrderPropertiesRequest} data - the properties to set.
	 * Uses the subOrderCode to find the suborder to update
	 */
	const setSuborderProperties = (data: SubOrderPropertiesRequest) => {
		let tempSubOrderData: EnhancedOrderBaseChildRequest | null = null;
		setSubOrders((oldSubOrders) => {
			const subOrderIndex = oldSubOrders.findIndex((so) => so.code === data.subOrderCode);
			if (subOrderIndex === -1) {
				return oldSubOrders;
			}
			const updatedSubOrders = [...oldSubOrders];
			const updatedSubOrder: EnhancedOrderBaseChildRequest = { ...updatedSubOrders[subOrderIndex] };

			if (!updatedSubOrder.code) {
				return oldSubOrders;
			}

			(Object.keys(data) as Array<keyof SubOrderPropertiesRequest>).forEach((key) => {
				// TODO: I'll keep this here, we actually have values that might be undefined
				// so it is better to leave the code like it is.
				// check if there are keys that are subject to problems when set to undefined
				// and maybe create a map of keys that cannot be set to undefined
				// if (key !== 'subOrderCode' && data[key] !== undefined) {
				if (key !== 'subOrderCode') {
					updateProperty(updatedSubOrder, key, data[key]);
				}
			});

			tempSubOrderData = updatedSubOrder;
			updatedSubOrders[subOrderIndex] = updatedSubOrder;
			return updatedSubOrders;
		});

		if (tempSubOrderData) {
			fetchSubChainArchetype(tempSubOrderData).then((result) => {
				if (!result) {
					return;
				}
				setSubOrders((oldSubOrders) => {
					const subOrderIndex = oldSubOrders.findIndex((so) => so.code === tempSubOrderData?.code);
					if (subOrderIndex === -1) {
						return oldSubOrders;
					}
					const updatedSubOrders = [...oldSubOrders];
					const updatedSubOrder: EnhancedOrderBaseChildRequest = {
						...updatedSubOrders[subOrderIndex],
					};
					if (result) {
						updatedSubOrder.ead = result.date;
					}
					updatedSubOrders[subOrderIndex] = updatedSubOrder;
					return updatedSubOrders;
				});
				setSubOrderChainData((oldData) => {
					const newChainData = { ...oldData };
					newChainData[tempSubOrderData?.code ?? ''] = result?.archetype ?? null;
					return newChainData;
				});
			});
		}
	};
	// #endregion

	// #region Variants
	/**
	 * Changes one or more properties of the order product. (we call them variants here but the name must be changed)
	 * @param {EnhancedOrderProduct} data - the data to change.
	 * @param {string} subOrderCode - the code of the "suborder" to change. (this will be called content in the future)
	 * @param {number} index - the index of the "variant" to change. (this will be called orderProduct in the future)
	 */
	const changeOrderProduct = (data: EnhancedOrderProduct, subOrderCode: string, index: number) => {
		setSubOrders((oldSubOrders) => {
			const subOrder = oldSubOrders.find((so) => so.code === subOrderCode);
			if (!subOrder) {
				return oldSubOrders;
			}
			const newSubOrder = { ...subOrder };
			const oldContent = subOrder.content ?? [];
			const orderProduct = oldContent[index];
			if (!orderProduct) {
				if (newSubOrder.content) {
					newSubOrder.content?.push(data);
				} else {
					newSubOrder.content = [data];
				}
			} else {
				newSubOrder.content = oldContent.map((vc, i) => (i === index ? { ...vc, ...data } : vc));
			}
			return oldSubOrders.map((so) => (so.code === subOrderCode ? newSubOrder : so));
		});
	};

	// ALL VARIANTS (not selective)
	/**
	 * Bulk change of the order products. (we call them variants here but the name must be changed)
	 * @param {EnhancedOrderProducts} data - the data to change.
	 * @param subOrderCode - the code of the "suborder" to change. (this will be called content in the future)
	 */
	const changeOrderProducts = (data: EnhancedOrderProducts, subOrderCode: string) => {
		setSubOrders((oldSubOrders) => {
			const subOrder = oldSubOrders.find((so) => so.code === subOrderCode);
			if (!subOrder) {
				return oldSubOrders;
			}
			const newSubOrder = { ...subOrder };
			newSubOrder.content = data;
			return oldSubOrders.map((so) => (so.code === subOrderCode ? newSubOrder : so));
		});
	};

	/**
	 * Deletes a variant from the list of variants.	(we call them variants here but the name must be changed)
	 * @param {number} index - the index of the variant to delete. (variant => orderProduct)
	 * @param {string} subOrderCode - the code of the "suborder" to change. (this will be called content in the future)
	 */
	const deleteVariant = (index: number, subOrderCode: string) => {
		setSubOrders((oldSubOrders) => {
			const subOrder = oldSubOrders.find((so) => so.code === subOrderCode);
			if (!subOrder) {
				return oldSubOrders;
			}
			const newSubOrder: OrderBaseChildRequest = {
				...subOrder,
				content: (subOrder.content ?? []).filter((_, i) => i !== index),
			};
			return oldSubOrders.map((so) => (so.code === subOrderCode ? newSubOrder : so));
		});
	};

	/**
	 * Adds an empty variant to the list of variants. (we call them variants here but the name must be changed to orderProduct)
	 * @param {string} subOrderCode - the code of the "suborder" to change. (this will be called content in the future)
	 */
	const addEmptyVariant = (subOrderCode: string) => {
		setSubOrders((oldSubOrders) => {
			const subOrder = oldSubOrders.find((so) => so.code === subOrderCode);
			if (!subOrder) {
				return oldSubOrders;
			}
			const newSubOrder: OrderBaseChildRequest = {
				...subOrder,
				content: [
					...(subOrder.content ?? []),
					{
						variantId: -1,
						orderQty: 0,
						expiresAt: moment().toDate(),
						notes: '',
					},
				],
			};
			return oldSubOrders.map((so) => (so.code === subOrderCode ? newSubOrder : so));
		});
	};
	// #endregion

	/**
	 * Loads a template into the form.
	 * NEEDS REWORK
	 */
	const loadTemplate = useCallback(
		(templateId: string | number | undefined) => {
			const template = templateList.find((t) => t.id === templateId);
			if (!template) {
				return;
			}
			console.log(template);
			switch (template.orderType) {
				case OrderType.Inbound: {
					const warehouse = warehouseList.find((w) => w.id === template.toId);
					if (
						warehouse &&
						(warehouse.storageType === StorageType.airport ||
							warehouse.storageType === StorageType.port)
					)
						break;
				}
			}

			const templateWithoutId = _.omit(template, 'id');
			const parsedTemplate = OrderBaseCreateSchema.safeParse(templateWithoutId);
			if (parsedTemplate.success) {
				reset(parsedTemplate.data);
			}

			const parsed = OrderBaseChildrenSchema.safeParse(template.children);
			if (parsed.success) {
				parsed.data.forEach((so) => {
					so.code = _.uniqueId('subOrder-');
				});
				setSubOrders(parsed.data);
			}

			// setValue('fromId', template.fromId);
			// if (template.shippingType) {
			// 	setValue('shippingType', template.shippingType);
			// }
			// setValue('toId', template.toId);
		},
		[templateList],
	);

	const contextValue = {
		renderType,
		popupId,
		orderType,
		control,
		subOrders,
		config,
		configValues,
		loading,
		currentChainArchetype,
		subOrdersChainData,
		infoElements,
		infoElementsStatus,
		getValues,
		handleSubmit,
		loadTemplate,
		setSuborderProperties,
		changeOrderProduct,
		changeVariants: changeOrderProducts,
		deleteVariant,
		addEmptyVariant,
		addSubOrder,
		removeSubOrder,
		setInfoElementStatus,
	};

	return <OrderEditorContext.Provider value={contextValue}>{children}</OrderEditorContext.Provider>;
};

export const useOrderEditor = () => useContext(OrderEditorContext);

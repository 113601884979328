import { QuantityWeightChip } from '@components/common/chips/QuantityWeightChip';
import { WeightChip } from '@components/common/chips/WeightChip';
import { TableTotalWeightBody } from '@components/common/tablecells/TableTotalWeightBody';
import useLocale from '@hooks/useLocale';
import useProducts from '@hooks/useProducts';
import { Grid, Typography } from '@mui/material';
import { AppFunction, OrderProductResponse, TranslationTypes } from 'common';

interface Props {
	content: OrderProductResponse;
	selected?: boolean;
}

export const InboundItem = ({ content, selected }: Props) => {
	const { getTranslatedString } = useLocale();
	const { variantList } = useProducts();

	const variant = variantList.find((variant) => variant.id === content.variantId);

	if (!variant) return null;

	return (
		<Grid
			item
			xs={12}
			container
			gap={1}
			p={1}
			sx={{
				backgroundColor: selected ? 'yellow' : 'white',
				transition: 'background-color 0.3s',
				'&:hover': {
					backgroundColor: selected ? 'yellow' : '#f0f0f0',
				},
			}}
		>
			<Grid item flexGrow={3} display='flex' gap={1}>
				<Typography variant='body1'>
					{getTranslatedString(
						AppFunction.Product,
						variant?.productId ?? -1,
						TranslationTypes.name,
					)}
				</Typography>
				<Typography variant='body1'>
					{getTranslatedString(AppFunction.Variant, content.variantId ?? -1, TranslationTypes.name)}
				</Typography>
			</Grid>
			<Grid item flexGrow={1}>
				<Typography variant='body1'>{content.orderQty}</Typography>
			</Grid>
			<Grid item flexGrow={1}>
				<Typography variant='body1'>{content.arrivedQty}</Typography>
			</Grid>
			<Grid item flexGrow={1}>
				<WeightChip weight={content.weight} />
			</Grid>
			<Grid item flexGrow={1}>
				<QuantityWeightChip
					quantity={content.arrivedQty ?? content.orderQty}
					weight={content.weight ?? 0}
					sx={{
						width: '100%',
					}}
				/>
			</Grid>
		</Grid>
	);
};

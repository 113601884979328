import { z } from 'zod';
import {
	TaskApiActionData,
	TaskFieldConfig,
	parseObjectToValues,
	parseValuesToString,
} from '../taskFieldConfig';
import { doSetTaskValues } from '@store/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	AffectedRowsResponse,
	OrderProductsUpdateRequest,
	TaskFieldValueResponse,
	TaskFieldValuesResponse,
} from 'common';
import { doSetOrderProductsData } from '@store/order';

const PickingFieldSchema = z.object({
	orderProductId: z.number(),
	picked: z.boolean(),
	pickedQty: z.number(),
	pickedWeight: z.number().nullable().optional(),
});
const PickingFieldsSchema = z.array(PickingFieldSchema);

export type PickingFieldValue = z.infer<typeof PickingFieldSchema>;
export type PickingFieldValues = z.infer<typeof PickingFieldsSchema>;

async function saveTaskValues(data: TaskApiActionData): Promise<void> {
	const stringified = parseValuesToString(PickingFieldsSchema, data.values);
	const setValuesRequest = [
		{
			taskFieldArchetypeId: data.fieldId,
			value: stringified,
			taskId: data.taskId,
		},
	];
	console.log(setValuesRequest);
	const setValuesResult = (await data.dispatch(doSetTaskValues(setValuesRequest))) as PayloadAction<
		TaskFieldValuesResponse | TaskFieldValueResponse
	>;
	if (setValuesResult.type !== 'tasks/setValues/fulfilled') {
		return Promise.reject('Set task values API call failed');
	}
}

export const pickingFieldConfig: TaskFieldConfig = {
	stringToValues: (inputString: string): PickingFieldValues =>
		parseObjectToValues<PickingFieldValues>(PickingFieldsSchema, inputString),
	valuesToString: (values: unknown[]): string => parseValuesToString(PickingFieldsSchema, values),
	validateStructure: (values: unknown[]): boolean => {
		const parsed = PickingFieldsSchema.safeParse(values);

		if (parsed.success) {
			return !parsed.data.some((value) => !value.picked);
		}
		console.log(parsed.error);
		return false;
	},
	executeApiAction: async (data: TaskApiActionData): Promise<void> => {
		// Save Values
		// await saveTaskValues(data);

		// Perform API action
		const parsedValues = PickingFieldsSchema.safeParse(data.values);
		if (!parsedValues.success) {
			return Promise.reject('Invalid task values');
		}
		console.log(parsedValues.data);
		parsedValues.data.forEach(async (value) => {
			if (value.pickedWeight !== null && value.pickedWeight !== undefined) {
				const entry = data.orderProducts?.find((entry) => entry.id === value.orderProductId);
				console.log('entry', entry);
				if (!entry) {
					console.warn('wut?');
				} else {
					console.log(entry);
					const newEntry = {
						...entry,
						pickedWeight: value.pickedWeight,
						arrivedQty: value.pickedQty,
					};
					const setProductData: OrderProductsUpdateRequest = {
						entries: [newEntry],
						customUpdateFields: ['pickedWeight', 'arrivedQty', 'weight'],
					};

					// console.log(setProductData);

					const setProductDataResult = (await data.dispatch(
						doSetOrderProductsData(setProductData),
					)) as PayloadAction<AffectedRowsResponse>;
					if (setProductDataResult.type !== 'orders/products/set/fulfilled') {
						return Promise.reject(new Error('Set products data API call failed'));
					}
				}
			}
		});

		// return Promise.reject('test keppin');
		return Promise.resolve();
	},
	saveStub: async () => {
		return Promise.reject('not implemented');
	},
};

import { CombinedIcon } from '@components/common/CombinedIcon';
import { FormAction } from '@contexts/formContext/types';
import { OrderEditorProvider, useLayout } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import useOrders from '@hooks/useOrders';
import { Edit, Sell } from '@mui/icons-material';
import { AppFunction } from 'common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

interface Props {
	formAction: FormAction;
}

export const OrderModify = ({ formAction }: Props) => {
	const { setBreadCrumbs } = useLayout();
	const id = useParams()?.id;
	const { t } = useTranslation();
	const { orderList } = useOrders();
	const { getIcon } = useAppFunctions();

	const order = orderList.find((order) => order.id === (id ? parseInt(id) : 0));

	useEffect(() => {
		console.log(order);
		if (!order || !order.orderType) return;
		setBreadCrumbs([
			{
				label: t(`appFunctions.${AppFunction.Order}`),
				icon: <Sell />,
				route: '/orders',
			},
			{
				label: t('operations.editItem', {
					item: order.code,
				}),
				icon: (
					<CombinedIcon
						main={getIcon(AppFunction.Order, order.orderType) ?? <Sell />}
						sub={<Edit />}
					/>
				),
			},
		]);
	}, [order]);

	if (formAction === 'create') {
		return <></>;
	}

	if (!order || !order.orderType) {
		return <></>;
	}

	return (
		<OrderEditorProvider orderType={order.orderType} renderType='standalone'>
			<div>{order.code}</div>
		</OrderEditorProvider>
	);
};

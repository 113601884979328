import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { Chip, ChipProps, SxProps, Theme, Tooltip } from '@mui/material';
import { CalendarMonth, Edit } from '@mui/icons-material';

import { CombinedIcon } from '../CombinedIcon';
import { useTranslation } from 'react-i18next';
import { AppFunction } from 'common';
import { useLocationTimeZone } from '@contexts/locationTimezoneContext/LocationTimeZoneContext';

/**
 * @description The properties of the expiration date chip
 * @property {Date | null | undefined} [expiresAt] - The expiration date of the product
 * @property {Date | null | undefined} [actualExpiration] - The actual expiration date of the product (if it was modified)
 */
interface ExpirationProps {
	expiresAt?: string | Date | null;
	actualExpiration?: string | Date | null;
	sx?: SxProps<Theme>;
}

/**
 * @description A chip component that displays the expiration date and changes color based on it
 *
 * The color changes based on the expiration date:
 * - **Red**: If the expiration date is in the past
 * - **Orange**: If the expiration date is in the next 2 days
 * - **Blue**: If the expiration date is in the next 7 days
 * - **Green**: If the expiration date is in more than 7 days
 *
 * @param {ExpirationProps} props - Accepts {@link ExpirationProps} or classes that extend it
 * @returns {JSX.Element | null} - Returns a Chip component with the expiration date
 */
const ExpiresAtChip = <T extends ExpirationProps>({
	expiresAt,
	actualExpiration,
	sx,
}: T): JSX.Element | null => {
	const [calendarColor, setCalendarColor] = useState<ChipProps['color']>();
	const { timeZone, dateFormat } = useLocationTimeZone();
	const expirationDate = actualExpiration || expiresAt;
	const { t } = useTranslation();

	useEffect(() => {
		if (!expirationDate) return;

		const exp = new Date(expirationDate);
		const now = new Date();
		let tempColor: ChipProps['color'] = 'default';

		if (isNaN(exp.getTime())) {
			console.error('Invalid expiration date');
			return;
		}

		const diffInMilliseconds = exp.getTime() - now.getTime();
		const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

		if (diffInMilliseconds < 0) {
			tempColor = 'error';
		} else if (diffInDays < 2) {
			tempColor = 'warning';
		} else if (diffInDays < 7) {
			tempColor = 'info';
		} else {
			tempColor = 'success';
		}

		setCalendarColor(tempColor);
	}, [expirationDate]);

	if (!expirationDate) return null;

	const modified = actualExpiration && actualExpiration !== expiresAt;

	return (
		<Tooltip
			title={`${t(`${AppFunction.Inventory}.originalExpiration`)}: ${moment(expiresAt)
				.tz(timeZone)
				.format(dateFormat)}`}
			disableFocusListener={!modified}
			disableHoverListener={!modified}
			disableTouchListener={!modified}
			placement='left'
			color={calendarColor}
			disableInteractive
		>
			<Chip
				label={moment(expirationDate).tz(timeZone).format(dateFormat)}
				color={calendarColor}
				icon={
					modified ? <CombinedIcon main={<CalendarMonth />} sub={<Edit />} /> : <CalendarMonth />
				}
				variant={modified ? 'outlined' : undefined}
				size='small'
				sx={sx}
			/>
		</Tooltip>
	);
};

ExpiresAtChip.displayName = 'ExpiresAtChip';
export default ExpiresAtChip;

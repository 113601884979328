import { useMemo } from 'react';
import { Autocomplete, TextField, Popper, styled, InputAdornment } from '@mui/material';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { AppFunction, TranslationTypes, WarehouseResponse } from 'common';
import { useTranslation } from 'react-i18next';
import { Warehouse } from '@mui/icons-material';

interface Props {
	value: number | null;
	onChange: (value: number | null) => void;
}

interface Option {
	group: number | null;
	warehouse: WarehouseResponse;
}

type GroupCount = Record<number, number>;

const CustomPopper = styled(Popper)(({ theme }) => ({
	zIndex: '9999 !important',
	pointerEvents: 'auto',
}));

const GroupedWarehouseAutocomplete = ({ value, onChange }: Props): JSX.Element => {
	const { warehouseList, getWarehouseIdPath } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const options = useMemo<Option[]>(() => {
		// Create a map to count occurrences of each path[0]
		const groupCount: GroupCount = warehouseList.reduce((acc, warehouse) => {
			const path = getWarehouseIdPath(warehouse.id);
			const group = path[0];
			acc[group] = (acc[group] || 0) + 1;
			return acc;
		}, {} as GroupCount);

		return warehouseList
			.map((warehouse) => {
				const path = getWarehouseIdPath(warehouse.id);
				let group: number | null = path[0];
				if (path[0] === warehouse.id) {
					group = groupCount[path[0]] > 1 ? path[0] : null;
				}
				return {
					group,
					warehouse,
				};
			})
			.sort((a, b) => {
				if (a.group === null && b.group !== null) {
					return 1;
				}
				if (a.group !== null && b.group === null) {
					return -1;
				}
				return 0;
			});
	}, [warehouseList, getWarehouseIdPath]);

	// TODO: add chips, correct visualization
	return (
		<Autocomplete
			id='grouped-warehouse-autocomplete'
			options={options}
			value={options.find((option) => option.warehouse.id === value) || null}
			onChange={(_, newValue) => {
				onChange(newValue ? newValue.warehouse.id : null);
			}}
			groupBy={(option) =>
				option.group
					? getTranslatedString(AppFunction.Location, option.group, TranslationTypes.name)
					: 'external'
			}
			getOptionLabel={(option) =>
				getTranslatedString(AppFunction.Location, option.warehouse.id, TranslationTypes.name)
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t(`appFunctions.${AppFunction.Location}`)}
					variant='outlined'
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position='end'>
								<Warehouse />
							</InputAdornment>
						),
					}}
				/>
			)}
			fullWidth
			size='small'
			PopperComponent={CustomPopper}
		/>
	);
};

GroupedWarehouseAutocomplete.displayName = 'GroupedWarehouseAutocomplete';

export default GroupedWarehouseAutocomplete;

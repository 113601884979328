import { useTable } from '@contexts/index';
import useAppFunctions from '@hooks/useAppFunctions';
import {
	CloseFullscreen,
	FilterAlt,
	FilterAltOff,
	OpenInFull,
	Settings as SettingsIcon,
} from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CsvImporter } from '@components/CsvImporter/CsvImporter';

export const TableHeader = () => {
	const { i18n, t } = useTranslation();
	const {
		pageIdentifier,
		subIdentifier,
		setHiddenColumn,
		toggleFilters,
		toggleExpanded,
		customHeader,
		hiddenColumns,
		hasFilters,
		showFilters,
		expanded,
	} = useTable();

	const { getIcon } = useAppFunctions();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [menuItems, setMenuItems] = useState<JSX.Element[]>([]);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toggleColumn = async (columnName: string) => {
		const oldColumnValue = hiddenColumns?.[columnName];
		setHiddenColumn?.(columnName, !oldColumnValue);
	};

	useEffect(() => {
		const tempMenuItems: JSX.Element[] = [];
		Object.entries(hiddenColumns ?? {}).forEach(([key, value]) => {
			tempMenuItems.push(
				<MenuItem
					key={key}
					sx={{ display: 'flex', justifyContent: 'space-between' }}
					onClick={() => toggleColumn?.(key)}
				>
					{t(key)}
					<Switch id={`${key}-switch`} checked={!value} size='small' />
				</MenuItem>,
			);
		});
		setMenuItems(tempMenuItems);
	}, [i18n.language, hiddenColumns, showFilters, pageIdentifier, subIdentifier]);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'right',
				alignItems: 'center',
				padding: '1rem',
			}}
		>
			<Typography
				sx={{
					flexGrow: customHeader ? 0 : 1,
					display: 'flex',
					alignItems: 'center',
					color: 'white',
					textShadow: '0px 0px 4px rgba(255, 255, 255, 0.5)',
					userSelect: 'none',
				}}
				fontSize='2rem'
			>
				{pageIdentifier &&
					getIcon(pageIdentifier, subIdentifier, undefined, {
						fontSize: '3rem',
						marginRight: '1rem',
						textShadow: '0px 0px 4px rgba(255, 255, 255, 0.5)',
					})}
				{t('general.list', { listElement: t(`appBar.${pageIdentifier}`) })}
			</Typography>
			{customHeader && (
				<Box
					flexGrow={1}
					p={1}
					sx={{
						backgroundColor: 'white',
						borderRadius: '1.5rem',
						margin: '0 1rem 0 1rem',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{customHeader}
				</Box>
			)}
			<div>
				<Stack direction='row' spacing={1}>
					<Tooltip
						title={expanded ? t('operations.contract') : t('operations.expand')}
						placement='top'
						arrow
					>
						<IconButton
							sx={{
								color: '#6200ea',
								backgroundColor: 'white',
								transform: 'scale(1)',
								transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
								'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
							}}
							onClick={() => toggleExpanded?.()}
						>
							{expanded ? <CloseFullscreen /> : <OpenInFull />}
						</IconButton>
					</Tooltip>
					{
						// TODO: this is a shitty hack because I have no time
						// implement a proper customButtons or something like that in the props
						pageIdentifier === 'customer' && <CsvImporter appFunction={pageIdentifier} />
					}
					{hasFilters && (
						<Tooltip
							title={showFilters ? t('operations.hideFilters') : t('operations.showFilters')}
							placement='top'
							arrow
						>
							<div>
								<IconButton
									color={showFilters ? 'success' : 'error'}
									onClick={() => toggleFilters?.()}
									sx={{
										backgroundColor: 'white',
										transform: 'scale(1)',
										transition: 'transform 0.3s cubic-bezier(0.34, 2, 0.84, 1)',
										'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
									}}
								>
									{showFilters ? <FilterAlt /> : <FilterAltOff />}
								</IconButton>
							</div>
						</Tooltip>
					)}
					{hiddenColumns && (
						<Tooltip title={t('appBar.settings')} placement='top' arrow>
							<div>
								<IconButton
									onClick={handleClick}
									color='info'
									sx={{
										backgroundColor: 'white',
										transform: 'scale(1)',
										transition: 'transform 0.4s cubic-bezier(0.34, 2, 0.84, 1)',
										'&:hover': { backgroundColor: 'white', transform: 'scale(1.1)' },
									}}
								>
									<SettingsIcon />
								</IconButton>
							</div>
						</Tooltip>
					)}
				</Stack>
			</div>
			<Menu
				id='column-settings-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{menuItems}
			</Menu>
		</div>
	);
};

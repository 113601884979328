import {
	BuyingPricesRequest,
	GenericIdsRequest,
	PriceCustomersRequest,
	PriceTypesRequest,
} from 'common';
import { useAppDispatch } from '../store/hooks';
import {
	doDeleteBuyingPrices,
	doEditBuyingPrices,
	doDeleteCustomerTypePrices,
	doEditCustomerTypePrices,
	doEditCustomerPrices,
	doDeleteCustomerPrices,
} from '../store/products';
import useProducts from './useProducts';
import useMerchants from './useMerchants';

function usePrices() {
	const dispatch = useAppDispatch();
	const { variantList } = useProducts();
	const { merchantList } = useMerchants();

	const editBuyingPrices = async (data: BuyingPricesRequest) => {
		return await dispatch(doEditBuyingPrices(data));
	};

	const deleteBuyingPrices = async (data: GenericIdsRequest) => {
		return await dispatch(doDeleteBuyingPrices(data));
	};

	const editCustomerTypePrices = async (data: PriceTypesRequest) => {
		return await dispatch(doEditCustomerTypePrices(data));
	};

	const deleteCustomerTypePrices = async (data: GenericIdsRequest) => {
		return await dispatch(doDeleteCustomerTypePrices(data));
	};

	const editCustomerPrices = async (data: PriceCustomersRequest) => {
		return await dispatch(doEditCustomerPrices(data));
	};

	const deleteCustomerPrices = async (data: GenericIdsRequest) => {
		return await dispatch(doDeleteCustomerPrices(data));
	};

	const getBuyingPrice = (variantId: number, amount: number, sellerId: number): number | null => {
		const variant = variantList.find((variant) => variant.id === variantId);
		const merchant = merchantList.find((merchant) => merchant.id === sellerId);

		if (!variant || !merchant) {
			return null;
		}

		const buyingPrice = variant.merchantBuyingPrices?.find(
			(price) => price.merchantId === sellerId && price.variantId === variantId,
		);
		const unit = variant.purchaseUnitWeight;

		if (!buyingPrice || unit === null || unit === undefined) {
			return null;
		}

		return buyingPrice.price * amount;
	};

	return {
		editBuyingPrices,
		deleteBuyingPrices,
		editCustomerTypePrices,
		deleteCustomerTypePrices,
		editCustomerPrices,
		deleteCustomerPrices,
		getBuyingPrice,
	};
}

export default usePrices;

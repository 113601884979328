import { useOrderEditor } from '@contexts/index';
import useInventory from '@hooks/useInventory';
import useProducts from '@hooks/useProducts';
import {
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	useTheme,
} from '@mui/material';
import { AppFunction, OrderDirectionType, OrderProductResponse, ProductType } from 'common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { OrderProductRowWrapper } from '../../common/OrderProductRowWrapper';
import ProductAutocomplete from '@components/common/inputs/products/ProductAutocomplete';
import usePrices from '@hooks/usePrices';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { EnhancedVariant, EnhancedVariants } from '@contexts/orderEditorContext/types';

interface Props {
	index: number;
	data: OrderProductResponse;
	subOrderCode: string;
	unavailableVariantIds: number[];
}

export const InboundSuborderProduct = ({
	index,
	data,
	subOrderCode,
	unavailableVariantIds,
}: Props) => {
	const theme = useTheme();
	const { productList, variantList, getTotalWeight } = useProducts();
	const { getBuyingPrice } = usePrices();
	const { inventories } = useInventory();
	const { t } = useTranslation();
	const [loaded, setLoaded] = useState(false);

	// True when we are not purchasing in boxes but in pieces
	const [editPiecesMode, setEditPiecesMode] = useState(false);

	const { configValues, getValues, changeOrderProduct } = useOrderEditor();

	const fromType = getValues('fromType');
	const toType = getValues('toType');

	const availableVariants = useMemo((): EnhancedVariants => {
		const tempVariants: EnhancedVariants = [];

		if (!fromType) return [];

		productList.forEach((pr) => {
			if (pr.productType !== ProductType.product) {
				return;
			}
			pr.variants
				?.filter(
					(vr) =>
						(vr.shipBy.toString() === getValues('shippingType') ||
							vr.shipBy.toString() === 'both') &&
						vr.merchantBuyingPrices?.some((mbp) => mbp.merchantId === getValues('fromId')),
					// TODO: decide when to filter and when not to filter
					// in import orders we shouldn't but in other orders we should
					// && (!unavailableVariantIds.includes(vr.id) || vr.id === data.variantId),
				)
				.map((vr) => tempVariants.push({ ...vr, unit: 'pieces' }));
		});

		setLoaded(true);
		return tempVariants;
	}, [productList, configValues, unavailableVariantIds, inventories, fromType, getValues]);

	const value = useMemo((): EnhancedVariant | null => {
		if (!fromType) return null;

		let foundVariant = null;

		switch (fromType) {
			case OrderDirectionType.warehouse: {
				const variant = availableVariants.find(
					(vr) => vr.id === data.variantId && vr.expiresAt === data.expiresAt,
				);
				if (variant) {
					foundVariant = variant;
				}
				break;
			}
			case OrderDirectionType.merchant: {
				for (const product of productList) {
					const variant = product.variants?.find((vr) => vr.id === data.variantId);
					if (variant) {
						foundVariant = variant;
						break;
					}
				}
				break;
			}
		}
		return foundVariant;
	}, [productList, variantList, availableVariants, fromType]);

	/**
	 * Calculates the price for the client.
	 * @returns {JSX.Element}
	 */
	// TODO: add price for client > price for client type > normal price (and time limited prices)
	// TODO: WRONG, create a new function inside usePrices that calculates the price for the client
	// TODO: DO THIS
	const sellingPrice = (): JSX.Element => {
		const merchant = getValues('fromId');
		if (!data.variantId || !data.orderQty || !merchant) return <></>;
		const price = getBuyingPrice(data.variantId, data.orderQty ?? 0, merchant);
		if (price === null) return <></>;

		return (
			<Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
				{price.toLocaleString('ja-JP', { style: 'currency', currency: 'EUR' })}
			</Grid>
		);
	};

	/**
	 * Generates the buying price for the currently selected merchant.
	 * - If the buying price is not found, an empty JSX element is returned.
	 * - If the currency code is not found, an empty JSX element is returned.
	 * - Otherwise, the buying price is calculated (using buying price * order quantity) and returned in a JSX element.
	 * @returns {JSX.Element} The buying price for the currently selected merchant in a JSX element.
	 */
	const buyingPrice = (): JSX.Element => {
		const merchant = getValues('fromId');
		if (!data.variantId || !data.orderQty || !merchant) {
			return <Grid item flexGrow={1} flexBasis={0} />;
		}
		const price = getBuyingPrice(data.variantId, data.orderQty ?? 0, merchant);

		let priceLabel = '';

		if (price === null) {
			priceLabel = t(`feedbacks.${AppFunction.Order}.shared.noBuyPrice`);
		} else {
			priceLabel = price.toLocaleString('ja-JP', { style: 'currency', currency: 'EUR' });
		}

		return (
			<Grid
				item
				flexGrow={1}
				flexBasis={0}
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
			>
				{priceLabel}
			</Grid>
		);
	};

	const totalWeight = (): JSX.Element => {
		const weight = getTotalWeight(data.variantId ?? -1, data.orderQty ?? 0);

		if (!weight) return <Grid flexGrow={1} flexBasis={0} item />;

		return (
			<InputGridField
				flexGrow={1}
				flexBasis={0}
				tooltipWhenDisabled={weight.estimated ? t('product.weight.estimated') : undefined}
				type={ComponentType.TextField}
			>
				<TextField
					id={`order-weight-idx-${index}`}
					fullWidth
					variant='outlined'
					size='small'
					label={t(`${AppFunction.Product}.totalWeight`)}
					type='number'
					value={weight.weight}
					disabled={true}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{t('product.units.grams')}
								{weight.estimated ? '*' : ''}
							</InputAdornment>
						),
					}}
				/>
			</InputGridField>
		);
	};

	/**
	 * Returns either the order quantity or the override value multiplied by the purchase unit weight or units per box.
	 * - If the override value is provided, the override value is multiplied by the purchase unit weight or units per box.
	 * - If the override value is not provided, the order quantity is divided by the purchase unit weight or units per box.
	 * @param {number | undefined} override - the override value for the order quantity, used to force a number to calculate the order quantity.
	 * This is used when we want to order a certain amount of pieces and override the box content calculation.
	 * @returns {number} the order quantity
	 */
	const orderQuantity = (override?: number): number => {
		if (override) {
			return override * (value?.unitsPerBox ?? 1);
		} else {
			if (!data.orderQty) return 0;
			return data.orderQty / (value?.unitsPerBox ?? 1);
		}
	};

	/**
	 * Renders the row for the product.
	 * Updated only when {@link variantList}, {@link data} or {@link availableVariants} change.
	 * @returns {JSX.Element} The product row
	 */
	const ProductContent = useMemo(() => {
		if (variantList.length > 0) {
			return (
				<OrderProductRowWrapper
					index={index}
					subOrderCode={subOrderCode}
					data={data}
					hasValue={!!value}
					hideNotes={value ? false : true}
				>
					<Grid item xs={12}>
						<ProductAutocomplete
							value={value?.id ?? null}
							availableIds={availableVariants.map((v) => v.id)}
							onChange={(newValue) => {
								if (newValue) {
									const expiresAt = newValue.expiresAt ?? null;
									changeOrderProduct(
										{
											variantId: newValue.id,
											expiresAt: expiresAt,
											unit: newValue.unit,
											sourceId: newValue.orderProductId,
											weight: newValue.weight,
											orderQty: newValue.unitsPerBox ?? 1,
										},
										subOrderCode,
										index,
									);
								} else {
									changeOrderProduct({ variantId: -1 }, subOrderCode, index);
								}
							}}
						/>
					</Grid>
					{value && (
						<>
							<InputGridField flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
								<TextField
									id={`order-quantity-idx-${index}`}
									fullWidth
									variant='outlined'
									size='small'
									label={t(`${AppFunction.Product}.quantity`)}
									type='number'
									value={orderQuantity()}
									disabled={editPiecesMode}
									InputProps={{
										inputProps: {
											min: 1,
											max: value?.maxAmount ?? 9999,
										},
										endAdornment: (
											<InputAdornment position='end'>
												{t(`${AppFunction.Product}.box`)}
											</InputAdornment>
										),
									}}
									onChange={(event) => {
										let rawQuantity = parseInt(event.target.value);
										if (rawQuantity < 1 || isNaN(rawQuantity)) {
											rawQuantity = 1;
										}
										changeOrderProduct(
											{
												orderQty: orderQuantity(rawQuantity),
												weight: value.variable ? value.weight * rawQuantity : value.weight,
											},
											subOrderCode,
											index,
										);
									}}
								/>
							</InputGridField>
							<InputGridField flexGrow={1} flexBasis={0} type={ComponentType.TextField}>
								<TextField
									id={`order-quantity-pieces-idx-${index}`}
									fullWidth
									variant='outlined'
									size='small'
									label={t(`${AppFunction.Product}.quantity`)}
									type='number'
									value={data.orderQty ?? 0}
									disabled={!editPiecesMode}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{t('product.units.pieces', { count: data.orderQty ?? 0 })}
											</InputAdornment>
										),
									}}
									onChange={(event) => {
										let rawQuantity = parseInt(event.target.value);
										if (rawQuantity < 1 || isNaN(rawQuantity)) {
											rawQuantity = 1;
										}
										console.log(rawQuantity);
										changeOrderProduct({ orderQty: rawQuantity }, subOrderCode, index);
									}}
								/>
							</InputGridField>
							<Grid item xs='auto'>
								<IconButton
									onClick={() => setEditPiecesMode((oldValue) => !oldValue)}
									sx={{
										color: editPiecesMode ? theme.palette.error.main : theme.palette.primary.main,
										transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
										transform: 'scale(1)',
										'&:hover': {
											transform: 'scale(1.2)',
										},
									}}
								>
									<Edit />
								</IconButton>
							</Grid>
							{totalWeight()}
							{toType && toType === OrderDirectionType.customer && sellingPrice()}
							{toType && toType === OrderDirectionType.warehouse && buyingPrice()}
						</>
					)}
				</OrderProductRowWrapper>
			);
		}
		if (variantList.length === 0 && loaded === false) {
			return <>no variants</>;
		} else {
			return (
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</div>
			);
		}
	}, [variantList, data, availableVariants, editPiecesMode]);

	return (
		<Grid container spacing={2} item xs={12} sx={{ minHeight: '60px' }}>
			{ProductContent}
		</Grid>
	);
};

import { Grid, IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface CloseOrderFieldListItemAction {
	action: () => void;
	icon: ReactNode;
	label: string;
}

interface Props {
	actions?: CloseOrderFieldListItemAction[];
}

export const CloseOrderFieldListItemActions = ({ actions }: Props): JSX.Element | null => {
	const { t } = useTranslation();

	if (!actions || actions.length <= 0) {
		return null;
	}

	return (
		<Grid
			item
			flexShrink={1}
			flexBasis={0}
			container
			display='flex'
			flexDirection='column'
			justifyContent='space-evenly'
			alignItems='center'
			bgcolor='grey.400'
			borderRadius={1}
			p={0.4}
			sx={{
				opacity: 0.3,
				// minWidth: '3rem',
				transition: 'all 0.2s ease-in-out',
				'&:hover': {
					// minWidth: '6rem',
					backgroundColor: grey[300],
					opacity: 1,
				},
			}}
		>
			{actions.map((action, i) => (
				<Grid item key={i}>
					<Tooltip
						title={t(action.label)}
						placement='top'
						PopperProps={{
							sx: {
								zIndex: 9999,
							},
						}}
						arrow
						disableInteractive
					>
						<IconButton size='small' onClick={action.action} color='primary'>
							{action.icon}
						</IconButton>
					</Tooltip>
				</Grid>
			))}
		</Grid>
	);
};

import { GeneralInfo } from '@components/forms/products/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { FormContextProvider } from '@contexts/formContext/FormContext';
import { FormAction, FormRenderType, FormType } from '@contexts/formContext/types';
import { ProductFormContextProvider } from '@contexts/formContext/subContexts/ProductFormContext';
import useProducts from '@hooks/useProducts';
import {
	AppFunction,
	ProductResponse,
	ProductSchema,
	ProductType,
	StorageConditionType,
	TaxBracket,
} from 'common';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
	productType: ProductType;
	formAction: FormAction;
	renderType?: FormRenderType;
	forceId?: number;
}

export const ProductForm = ({ productType, formAction, renderType, forceId }: Props) => {
	const routeId = parseInt(useParams().id ?? '-1');
	const { productList, createProduct, editProduct } = useProducts();

	const [formValues, setFormValues] = useState({} as ProductResponse);

	// TODO: use an id state like in VariantFrom
	// we could actually bring it to the FormContextProvider

	useEffect(() => {
		const newValues = productList.find((p) => p.id === routeId || p.id === forceId);
		if (newValues && !_.isEqual(newValues, formValues)) {
			setFormValues(newValues);
		}
	}, [productList]);

	return (
		<FormContextProvider
			appFunction={AppFunction.Product}
			createFunction={createProduct}
			subFunction={productType}
			editFunction={editProduct}
			renderType={renderType ?? FormRenderType.standalone}
			entityId={forceId ?? routeId}
			formType={FormType.full}
			formAction={formAction}
			formValues={formValues}
			validationSchema={ProductSchema}
			formComponent={ProductFormContextProvider}
			hideMenu
			useFab
			sideFabs={[FormSidefabType.Info, FormSidefabType.Notes]}
			customAfterCreateLink='product/:id/edit'
			defaultValues={
				{
					id: routeId,
					productType: productType,
					isFresh: false,
					shelfLife: 0,
					taxBracket: TaxBracket.eight,
					storageType: StorageConditionType.ambient,
					strings: undefined,
				} as ProductResponse
			}
		>
			<TabbedElement title='product.generalInfo'>
				<GeneralInfo />
			</TabbedElement>
		</FormContextProvider>
	);
};

import { useEffect, useMemo, useState } from 'react';
import { useOrderEditor, useLayout } from '@contexts/index';
import { useOrders, useInventory } from '@hooks/index';

import { uniqueId } from 'lodash';

import { Box, Button, Container, Grid, Stack, useTheme } from '@mui/material';
import {
	OrderBaseRequest,
	OrderDirectionType,
	OrderProductRequest,
	OrderTemplateCreateSchema,
} from 'common';

import { OrderLeftMenu } from './OrderLeftMenu';
import { OrderHeader } from './header/OrderHeader';

import {
	OrderProductResponse,
	OrderStatus,
	OrderType,
	ShippingType,
} from 'common/interfaces/orders';
import { FadeWrapper } from '@components/common/FadeWrapper';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { useAppDispatch } from '@store/hooks';
import { TemplateNameDialog } from '@components/dialogs/TemplateNameDialog';

interface Props {
	inventoryProductId?: number;
}

export const OrderEditor = ({ inventoryProductId }: Props) => {
	const {
		renderType,
		popupId,
		orderType,
		subOrders,
		config,
		getValues,
		handleSubmit,
		loadTemplate,
		addSubOrder,
		infoElements,
		infoElementsStatus,
	} = useOrderEditor();
	const { setSideMenu, popPopup } = useLayout();
	const { pushComponentDialog } = useFeedbacks();
	const { t } = useTranslation();
	const { createTemplate, templateList, findContentById } = useOrders();
	const { findInventoryEntryById, inventories } = useInventory();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const theme = useTheme();

	const [showInfoElements, setShowInfoElements] = useState(false);
	const [sourceOrderProduct, setSourceOrderProduct] = useState<OrderProductResponse | null>(null);

	useEffect(() => {
		return () => {
			setSideMenu(null);
		};
	}, []);

	/**
	 * If inventoryProductId is set, we need to create a certain set of suborders.
	 * This is used when performing manual operations/modifications on inventory products.
	 * We feed the inventoryProductId to the editor and it will create the necessary suborders.
	 */
	// TODO: change "test" name and add proper types
	// document
	useEffect(() => {
		if (inventoryProductId) {
			const test = findInventoryEntryById(inventoryProductId);
			console.log('test', test);
			if (test) {
				// console.log(test.quantity);
				addSubOrder({
					code: 'temp',
					shippingType: ShippingType.handDelivered,
					fromId: test.warehouseId,
					toId: test.warehouseId,
					fromType: OrderDirectionType.warehouse,
					toType: OrderDirectionType.warehouse,
					content: [
						{
							...(test.orderProduct as OrderProductRequest),
							arrivedQty: test.quantity,
							expiresAt: test.actualExpiration,
							weight: test.weight,
							estimated: test.estimated,
							pickedWeight: test.remainingWeight ?? test.weight,
							unit: test.unit as 'pieces' | 'grams',
						},
					],
				});
				addSubOrder({
					code: uniqueId(`${orderType}-${test.id}`),
					shippingType: ShippingType.handDelivered,
					fromId: test.warehouseId,
					toId: test.warehouseId,
					fromType: OrderDirectionType.warehouse,
					toType: OrderDirectionType.warehouse,
					content: [
						{
							...(test.orderProduct as OrderProductRequest),
							arrivedQty: test.quantity,
							expiresAt: test.expiresAt,
							weight: test.weight,
							estimated: test.estimated,
							unit: test.unit as 'pieces' | 'grams',
						},
					],
				});
				setSourceOrderProduct(test.orderProduct ?? null);
			}
		}
	}, [inventoryProductId, inventories]);

	useEffect(() => {
		const filteredTemplates = templateList.filter((t) => t.orderType === orderType);
		if (filteredTemplates.length > 0) {
			setSideMenu(<OrderLeftMenu orderType={orderType} loadTemplate={loadTemplate} />);
		} else {
			setSideMenu(null);
		}
	}, [orderType, loadTemplate]);

	// TODO: bring inside context
	const cachedElements = useMemo(() => {
		if (
			Object.keys(infoElementsStatus).length === 0 ||
			Object.keys(infoElements).length !== Object.keys(infoElementsStatus).length ||
			(Object.keys(infoElementsStatus).length > 0 &&
				Object.values(infoElementsStatus).every((status) => status === false))
		) {
			setShowInfoElements(false);
		} else {
			setShowInfoElements(true);
		}
		return Object.values(infoElements);
	}, [infoElements, infoElementsStatus, orderType]);

	const saveTemplate = (data: OrderBaseRequest) => {
		const tempOrderProducts: OrderProductRequest[] = [];
		subOrders.map((so) => {
			so.content?.map((op) => {
				if (op.orderQty === undefined || op.orderQty === null || op.orderQty === 0) {
					return;
				}
				const existingProduct = tempOrderProducts.find((top) => top.variantId === op.variantId);
				if (existingProduct && existingProduct.orderQty !== undefined) {
					existingProduct.orderQty = existingProduct.orderQty + op.orderQty;
					return;
				}
				tempOrderProducts.push(op);
			});
		});
		data.content = tempOrderProducts;
		data.children = subOrders.map((so, it) => {
			return { ...so, code: `${data.code}-${it}`, shippingType: ShippingType.externalCarrier };
		});

		pushComponentDialog({
			title: t('operations.saveItem', { item: t('order.template') }),
			component: TemplateNameDialog,
			fullScreen: false,
			type: 'component',
		}).then((result) => {
			if (result !== false) {
				const validatedData = OrderTemplateCreateSchema.safeParse({ name: result.name, ...data });
				if (validatedData.success) {
					console.log(validatedData.data);
					createTemplate(validatedData.data);
				} else {
					console.error(validatedData.error);
				}
			}
		});
	};

	const saveOrder = async (data: OrderBaseRequest) => {
		if (!orderType) {
			return Promise.reject('Order type not set');
		}

		if (!config) {
			return Promise.reject('No config set');
		}

		data.status = OrderStatus.Pending;

		const sources: Record<number, OrderProductResponse> = {};
		subOrders.map((so) => {
			so.content?.map((op) => {
				if (op.sourceId) {
					let parsedSourceid = -1;
					if (typeof op.sourceId === 'string') {
						parsedSourceid = parseInt(op.sourceId);
					} else {
						parsedSourceid = op.sourceId;
					}
					const sourceOp = findContentById(parsedSourceid);
					if (sourceOp) {
						sources[parsedSourceid] = sourceOp;
					}
				}
			});
		});

		if (config?.executeOrderAction) {
			config
				.executeOrderAction({
					baseRequest: data,
					subOrders,
					inventories: orderType === OrderType.Processing ? inventories : undefined,
					sources,
					dispatch,
					sourceOrderProduct,
				})
				.then((res) => {
					if (popupId) {
						console.log(popupId);
						popPopup(popupId);
					} else {
						if (res.status !== OrderStatus.Booked && res.id) {
							navigate(`/orders/${res.id}`);
						} else {
							console.log('booked order');
							navigate(`/orders/${res.id}`);
						}
					}
				})
				.catch((err) => {
					console.error(err);
				});
			return;
		} else {
			console.error('No executeOrderAction set for this order type');
		}
	};

	const addSuborderDisabled = config?.isAddSuborderDisabled(getValues(), subOrders) ?? false;
	const createOrderDisabled = config?.isCreateOrderDisabled(subOrders) ?? false;

	// TODO: we might need to render the cachedElements differntly in the popup mode
	const orderContent = (
		<>
			<Container
				maxWidth={false}
				sx={{
					position: 'relative',
					maxWidth: renderType === 'standalone' ? 'calc(100% - 2rem)' : '100%',
					margin: renderType === 'standalone' ? '0 1rem 1rem 1rem !important' : '',
					padding: renderType === 'standalone' ? '0 !important' : '2rem 0 0 0 !important',
					borderRadius: renderType === 'standalone' ? '2rem' : '',
					minHeight: renderType === 'popup' ? '100%' : 'auto',
					background: renderType === 'standalone' ? theme.palette.gradient.primary : 'none',
				}}
			>
				<Box sx={{ height: 'auto' }}>
					<OrderHeader />
				</Box>
				<Box
					sx={{
						padding: '1rem',
					}}
				>
					<Grid
						container
						flex='vertical'
						justifyContent='center'
						sx={{
							backgroundColor: 'white',
							borderRadius: '2rem',
							padding: '1rem 0 1rem 0',
						}}
					>
						{subOrders.map((so, it) => {
							if (
								so.code &&
								config?.maxSubOrders &&
								(config.maxSubOrders === 'unlimited' || it < config.maxSubOrders)
							) {
								return <config.subOrderRow key={`${it}-${so.code}`} subOrderCode={so.code} />;
							}
						})}
						{config?.maxSubOrders &&
							(config.maxSubOrders === 'unlimited' || subOrders.length < config.maxSubOrders) && (
								<Grid item xs='auto'>
									<Button
										fullWidth
										variant='outlined'
										disabled={addSuborderDisabled}
										onClick={() => {
											if (orderType === OrderType.Processing) {
												addSubOrder({
													code: uniqueId('suborder-'),
													orderType: OrderType.Processing,
													fromId: -1,
													fromType: OrderDirectionType.warehouse,
													toId: -1,
													toType: OrderDirectionType.warehouse,
													shippingType: ShippingType.handDelivered,
													variantToCreate: -1,
												});
											} else if (orderType === OrderType.Manual) {
												addSubOrder({
													code: uniqueId('suborder-'),
													orderType: OrderType.Manual,
													fromId: undefined,
													fromType: OrderDirectionType.warehouse,
													toId: undefined,
													toType: OrderDirectionType.warehouse,
													shippingType: ShippingType.handDelivered,
												});
											} else {
												if (orderType) {
													addSubOrder({
														code: uniqueId('suborder-'),
														orderType: orderType,
														fromId: getValues('toId'),
														shippingType: ShippingType.externalCarrier,
													});
												}
											}
										}}
									>
										{t('order.addSuborder', {
											type: t(`order.orderTypes.${orderType?.toLowerCase()}`),
										})}
									</Button>
								</Grid>
							)}
					</Grid>
				</Box>
				<Box
					sx={{
						padding: '1rem',
					}}
				>
					<Grid
						container
						columnGap={1}
						justifyContent={'center'}
						sx={{
							padding: '24px 0 24px 0',
							backgroundColor: 'white',
							borderRadius: '2rem',
						}}
					>
						<Grid item xs={2}>
							<Button
								fullWidth
								type='submit'
								variant='contained'
								onClick={handleSubmit(saveOrder)}
								color='success'
								disabled={createOrderDisabled}
								sx={{
									borderRadius: '2rem',
								}}
							>
								{t('order.submitOrder')}
							</Button>
						</Grid>
						{renderType === 'standalone' && (
							<Grid item xs={2}>
								<Button
									fullWidth
									type='submit'
									variant='contained'
									onClick={handleSubmit(saveTemplate)}
									color='info'
									sx={{
										borderRadius: '2rem',
									}}
								>
									{t('order.saveAsTemplate')}
								</Button>
							</Grid>
						)}
					</Grid>
				</Box>
			</Container>
			{cachedElements && (
				<Box
					sx={{
						margin: renderType === 'standalone' ? '0 1rem 1rem 1rem !important' : '',
						padding: '1rem',
						borderRadius: renderType === 'standalone' ? '2rem' : '',
						minHeight: renderType === 'popup' ? '100%' : 'auto',
						background: theme.palette.gradient.primary,
						display: showInfoElements ? 'inherit' : 'none',
					}}
				>
					<Stack direction='column'>{cachedElements}</Stack>
				</Box>
			)}
		</>
	);

	return (
		<FadeWrapper fadeTime={500}>
			{renderType === 'popup' ? (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						width: '100%',
						background: theme.palette.gradient.primary,
					}}
				>
					<Stack
						direction='row'
						sx={{
							height: '100%',
							width: '100%',
						}}
					>
						{orderContent}
					</Stack>
				</Box>
			) : (
				<Stack direction='row'>{orderContent}</Stack>
			)}
		</FadeWrapper>
	);
};

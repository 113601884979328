import { ComponentType } from '@components/common/InputGridField/config/Index';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { TabInnerTitle } from '@components/tabs/TabInnerTitle';
import { useGenericForm } from '@contexts/formContext/FormContext';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';
import { Grid, Menu, MenuItem, Select, ToggleButton } from '@mui/material';
import {
	AppFunction,
	StorageConditionType,
	StorageType,
	TranslationTypes,
	WarehouseResponse,
} from 'common';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const GeneralInfo = () => {
	const { control, entityId, formAction, errors, getValues } = useGenericForm<WarehouseResponse>();
	const { warehouseList, getSubWarehouses } = useWarehouses();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const unavailableLocations = useMemo(() => {
		const subLocations = getSubWarehouses(entityId);
		const unavailableLocations = new Set<number>([entityId]);
		subLocations.forEach((location) => {
			unavailableLocations.add(location.id);
		});
		return unavailableLocations;
	}, [entityId]);

	const availableTypes = useMemo((): StorageType[] => {
		const parentId = getValues('parentId');
		if (parentId === null) {
			return [
				StorageType.main,
				StorageType.airport,
				StorageType.port,
				StorageType.store,
				StorageType.external,
			];
		} else {
			return [
				StorageType.subStorage,
				StorageType.processing,
				StorageType.trash,
				StorageType.office,
				StorageType.shipping,
			];
		}
	}, [getValues('parentId')]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<TabInnerTitle title='location.generalInfo' translate size='small' />
			</Grid>
			<Controller
				name='isPickingSite'
				control={control}
				render={({ field }) => (
					<InputGridField width={12} type={ComponentType.ToggleButton}>
						<ToggleButton
							value='check'
							color='success'
							disabled={formAction === 'view'}
							selected={field.value ?? false}
							onChange={() => field.onChange(!field.value)}
							fullWidth
						>
							{field.value
								? t(`${AppFunction.Location}.isPickingSite`)
								: t(`${AppFunction.Location}.notPickingSite`)}
						</ToggleButton>
					</InputGridField>
				)}
			/>
			<Controller
				name='storageType'
				control={control}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'location-storageType-label',
							label: t('location.storageType'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='location-storageType-label'
							id='location-storageType'
							fullWidth
							required
							error={!!errors.storageType}
							value={field.value ?? -1}
							label={t('location.storageType')}
							disabled={formAction === 'view'}
							size='small'
							onChange={(event) => field.onChange(event.target.value as StorageType)}
						>
							<MenuItem value={-1}>{t('common.none')}</MenuItem>
							{(Object.keys(StorageType) as (keyof typeof StorageType)[]).map((key, index) => {
								if (!availableTypes.includes(StorageType[key])) {
									return null;
								}
								return (
									<MenuItem value={StorageType[key]} key={index}>
										{t(`${AppFunction.Location}.storageTypes.${key}`)}
									</MenuItem>
								);
							})}
						</Select>
					</InputGridField>
				)}
			/>
			<Controller
				name='storageCondition'
				control={control}
				defaultValue={StorageConditionType.ambient}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'location-storageConditionType-label',
							label: t('location.storageConditionType'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='location-storageConditionType-label'
							id='location-storageConditionType'
							fullWidth
							value={field.value ?? StorageType.main}
							label={t('location.storageConditionType')}
							disabled={formAction === 'view'}
							size='small'
							onChange={(event) => field.onChange(event.target.value as StorageConditionType)}
						>
							{(Object.keys(StorageConditionType) as (keyof typeof StorageConditionType)[]).map(
								(key, index) => {
									return (
										<MenuItem value={StorageConditionType[key]} key={index}>
											{t(`${AppFunction.Location}.storageConditionTypes.${key}`)}
										</MenuItem>
									);
								},
							)}
						</Select>
					</InputGridField>
				)}
			/>
			<Controller
				name='parentId'
				control={control}
				defaultValue={null}
				render={({ field }) => (
					<InputGridField
						width={12}
						muiLabel={{
							labelId: 'location-parent',
							label: t('location.parent'),
						}}
						type={ComponentType.Select}
					>
						<Select
							labelId='location-parent'
							id='location'
							fullWidth
							value={field.value ?? '-1'}
							label={t('location.parent')}
							disabled={formAction === 'view'}
							size='small'
							onChange={(event) => {
								console.log(event.target.value);
								const value = event.target.value !== '-1' ? +event.target.value : undefined;
								field.onChange(value);
							}}
						>
							<MenuItem value='-1'>{t('common.none')}</MenuItem>
							{warehouseList
								.filter((warehouse) => !unavailableLocations.has(warehouse.id))
								.map((warehouse, index) => {
									return (
										<MenuItem value={warehouse.id} key={index}>
											{getTranslatedString(
												AppFunction.Location,
												warehouse.id,
												TranslationTypes.name,
											)}
										</MenuItem>
									);
								})}
						</Select>
					</InputGridField>
				)}
			/>
			<Grid item xs={12} sx={{ minHeight: '2rem' }} />
		</Grid>
	);
};

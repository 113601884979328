import { useMemo } from 'react';
import { useOrderEditor } from '@contexts/index';
import useLocale from '@hooks/useLocale';
import useWarehouses from '@hooks/useWarehouses';

import { AppFunction, StorageType, TranslationTypes, WarehousesResponse } from 'common';

import { Autocomplete, Grid, TextField } from '@mui/material';

import { EnhancedOrderBaseChildRequest } from '@components/orders/config/orderTypeConfig';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '@components/common/InputGridField/config/Index';
import { EnhancedVariant } from '@contexts/orderEditorContext/types';

/**
 * The properties of the component.
 * @prop {EnhancedVariant | null} value The value of the product.
 * @prop {EnhancedOrderBaseChildRequest} subOrder The suborder.
 */
interface Props {
	value: EnhancedVariant | null;
	subOrder: EnhancedOrderBaseChildRequest;
}

/**
 * This component is used to display the header of a suborder in a cut order.
 * It allows the user to select the processing location and the destination warehouse.
 * @param {Props} props The properties of the component.
 * @returns {JSX.Element | null} The rendered component.
 */
export const CutSuborderProductHeader = ({ value, subOrder }: Props): JSX.Element | null => {
	const { setSuborderProperties } = useOrderEditor();
	const { t } = useTranslation();

	const { getTranslatedString } = useLocale();
	const { warehouseList } = useWarehouses();

	// we should find which "main" warehouse we're in and shut out anything in different main warehouses
	const getOptions = useMemo((): WarehousesResponse => {
		return warehouseList.filter(
			(w) =>
				w.storageType !== StorageType.external &&
				w.storageType !== StorageType.airport &&
				w.storageType !== StorageType.port,
		);
	}, [warehouseList]);

	if (!value) {
		return null;
	}

	const recipe = value.recipe;

	if (!recipe) {
		return null;
	}

	return (
		<Grid item xs={12} container columnSpacing={2}>
			{/* select processing location */}
			{recipe.processingLocation.length > 0 && (
				<InputGridField flexGrow={1} type={ComponentType.Autocomplete}>
					<Autocomplete
						id='test-autocomplete'
						size='small'
						options={recipe.processingLocation}
						value={subOrder.through ?? -1}
						disabled={recipe.processingLocation.length === 1}
						fullWidth
						renderInput={(params) => (
							<TextField
								{...params}
								label={t(`${AppFunction.Order}.processing.location`)}
								variant='outlined'
								inputProps={{
									...params.inputProps,
								}}
							/>
						)}
						getOptionLabel={(option) => {
							return getTranslatedString(AppFunction.Location, option, TranslationTypes.name);
						}}
						onChange={(_, newValue) => {
							if (typeof newValue === 'number') {
								setSuborderProperties({
									subOrderCode: subOrder.code ?? '',
									through: newValue,
								});
							} else {
								setSuborderProperties({ subOrderCode: subOrder.code ?? '', through: -1 });
							}
						}}
					/>
				</InputGridField>
			)}
			{/* destination warehouse selection */}
			<Grid item flexGrow={1} justifyContent='center' alignItems='center' display='flex'>
				<Autocomplete
					options={getOptions}
					value={warehouseList.find((wh) => wh.id === subOrder.toId ?? -1) ?? null}
					size='small'
					fullWidth
					renderInput={(params) => (
						<TextField
							{...params}
							label='To'
							variant='outlined'
							inputProps={{
								...params.inputProps,
							}}
						/>
					)}
					getOptionLabel={(option) => {
						return getTranslatedString(AppFunction.Location, option.id, TranslationTypes.name);
					}}
					onChange={(_, newValue) => {
						if (typeof newValue === 'object' && newValue !== null) {
							setSuborderProperties({ subOrderCode: subOrder.code ?? '', toId: newValue.id });
						} else {
							setSuborderProperties({ subOrderCode: subOrder.code ?? '', toId: -1 });
						}
					}}
				/>
			</Grid>
		</Grid>
	);
};

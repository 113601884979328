import useOrders from '@hooks/useOrders';
import { OrderProductResponse, VariantResponse } from 'common';

interface TablePriceBodyProps {
	orderProduct: OrderProductResponse;
	variant: VariantResponse;
	sourceId: number;
}

export const TablePriceBody = <T extends TablePriceBodyProps>({
	orderProduct,
	variant,
	sourceId,
}: T) => {
	const { findContentById } = useOrders();

	if (
		!orderProduct ||
		!orderProduct?.actualPrice ||
		!orderProduct?.arrivedQty ||
		!variant?.unitsPerBox
	) {
		return <div>0</div>;
	}

	if (sourceId) {
		const source = findContentById(sourceId);
		if (!source) return <div>0</div>;
		if (source.actualPrice && source.arrivedQty) {
			return <div>{Math.ceil(source.actualPrice / source.arrivedQty / variant.unitsPerBox)}</div>;
		}
	}

	return (
		<div>
			{Math.ceil(orderProduct.actualPrice / orderProduct?.arrivedQty / variant.unitsPerBox)}
		</div>
	);
};

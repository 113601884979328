import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	AffectedRowsResponse,
	GenericIdRequest,
	WarehouseInventoriesResponse,
	WarehouseInventoryResponse,
} from 'common';
import _ from 'lodash';
import { doDeleteInventoryProduct, doFetchAllInventoryStates } from '../store/inventory';
import { PayloadAction } from '@reduxjs/toolkit';
import { WarehouseProductDeleteRequest } from 'common/interfaces/inventorystate';

function useInventory() {
	const dispatch = useAppDispatch();
	const opStatus = useAppSelector((state) => state.inventoryState.op_status);
	const inventorySlice = useAppSelector((state) => state.inventoryState.inventoryStates);

	const [inventories, setInventories] = useState<{ [key: number]: WarehouseInventoriesResponse }>(
		{},
	);
	const [accruedInventory, setAccruedInventory] = useState<WarehouseInventoriesResponse>([]);

	useEffect(() => {
		setInventories((oldInventories) => {
			if (!_.isEqual(oldInventories, inventorySlice)) {
				return inventorySlice;
			}
			return oldInventories;
		});

		setAccruedInventory((oldAccruedInventory) => {
			const result: { [key: number]: WarehouseInventoryResponse } = [];
			Object.values(inventorySlice)
				.flatMap((item) => item)
				.forEach((item) => {
					const key = item.variantId;
					if (!result[key]) {
						result[key] = item;
					} else {
						result[key] = {
							...result[key],
							quantity: result[key].quantity + item.quantity,
							remainingWeight: (result[key].remainingWeight ?? 0) + (item.remainingWeight ?? 0),
							warehouseId: -1,
						};
					}
				});

			if (
				!_.isEqual(
					oldAccruedInventory,
					Object.values(result).flatMap((item) => item),
				)
			) {
				return Object.values(result).flatMap((item) => item);
			}
			return oldAccruedInventory;
		});
	}, [inventorySlice]);

	const deleteInventoryProduct = async (
		data: GenericIdRequest,
	): Promise<AffectedRowsResponse | null> => {
		const warehouseProduct = findInventoryEntryById(+data.id);
		if (!warehouseProduct) {
			return null;
		}
		const deleteRequest: WarehouseProductDeleteRequest = {
			variantId: warehouseProduct.variantId,
			expiresAt: warehouseProduct.expiresAt,
			orderId: warehouseProduct.orderProduct?.id ? +warehouseProduct.orderProduct.id : -1,
			warehouseId: warehouseProduct.warehouseId,
			quantity: warehouseProduct.quantity,
			estimated: false,
			sourceOrderPrductId: warehouseProduct.id ?? -1,
		};

		// TODO: maybe validate the schema

		const response = (await dispatch(
			doDeleteInventoryProduct(deleteRequest),
		)) as PayloadAction<AffectedRowsResponse>;

		if (response.type === 'inventoryState/delete/fulfilled') {
			return response.payload;
		}
		return null;
	};

	// TODO: this is here temporarily, remove it when the inventory is properly implemented
	const fetchShit = () => dispatch(doFetchAllInventoryStates());

	function findInventoryEntryById(targetId: number) {
		for (const key of Object.keys(inventories)) {
			const castedKey = Number(key);
			const warehouseInventory = inventories[castedKey].find(
				(inventory) => inventory.orderProduct?.id === targetId,
			);
			if (warehouseInventory) {
				return warehouseInventory;
			}
		}
		return undefined;
	}

	return {
		opStatus,
		inventories,
		accruedInventory,
		fetchShit,
		findInventoryEntryById,
		deleteInventoryProduct,
	};
}

export default useInventory;

import { useOrderEditor } from '@contexts/index';
import { useAppFunctions, useInventory, useLocale, useProducts } from '@hooks/index';
import { Autocomplete, Chip, Grid, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import {
	AppFunction,
	IngredientVariants,
	OrderDirectionType,
	RecipeType,
	TranslationTypes,
	WarehouseInventoriesResponse,
} from 'common';
import { HTMLAttributes, ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { FadeWrapper } from '@components/common/FadeWrapper';
import { useTranslation } from 'react-i18next';
import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { QrCode } from '@mui/icons-material';
import { DivisionProduct } from './DivisionProduct';
import { CutSuborderProductHeader } from './CutSuborderProductHeader';
import { EnhancedVariant, EnhancedVariants } from '@contexts/orderEditorContext/types';

export const CutOrderProductRow = ({ subOrder }: SubOrderProductsContainerProps) => {
	const { setSuborderProperties, changeOrderProduct: changeVariant } = useOrderEditor();

	const { t } = useTranslation();
	const theme = useTheme();
	const { getIcon } = useAppFunctions();
	const { inventories } = useInventory();
	const { variantList } = useProducts();
	const { getTranslatedString } = useLocale();

	const [automateIngredientSelection, setAutomateIngredientSelection] = useState(true);
	const [lastUpdatedGrams, setLastUpdatedGrams] = useState(0);
	const [outputLimitReached, setOutputLimitReached] = useState(false);

	const availableVariants = useMemo((): EnhancedVariants => {
		const tempVariants: EnhancedVariants = [];
		variantList.map((vr) => {
			if (vr.recipe && vr.recipe.ingredients.length > 0) {
				tempVariants.push(vr);
			}
		});
		return tempVariants;
	}, [variantList]);

	const value = useMemo((): EnhancedVariant | null => {
		let foundVariant = null;

		const variant = availableVariants.find((vr) => vr.id === subOrder?.variantToCreate);
		if (variant) {
			foundVariant = variant;
		}

		return foundVariant;
	}, [availableVariants, subOrder]);

	// TODO: we need to debounce later on but in order to debounce correctly
	// an optimistic update on the number of pieces needs to be implemented
	// unless we implement the optimistic update it will take 300ms to update the
	// number of pieces in the input field

	// const debouncedSetSuborderProperties = useMemo(
	// 	() => debounce(setSuborderProperties, 300),
	// 	[setSuborderProperties]
	// );

	if (!subOrder) {
		return null;
	}

	const recipe = variantList.find((v) => v.id === subOrder.variantToCreate)?.recipe;
	const ingredients = recipe?.ingredients;

	/**
	 * Renders the {@link Autocomplete} options
	 * @param {HTMLAttributes<HTMLLIElement>} props - the props for the {@link MenuItem}
	 * @param {EnhancedVariant} option - the option to render
	 * @param {AutocompleteRenderOptionState} renderState - the render state of the option
	 * @returns {ReactNode} - the rendered option
	 */
	const renderOption = (
		props: HTMLAttributes<HTMLLIElement>,
		option: EnhancedVariant,
		// renderState: AutocompleteRenderOptionState,
	): ReactNode => {
		let productName = 'none';
		let variantName = 'none';
		if (option.id !== -1) {
			variantName = getTranslatedString(AppFunction.Variant, option.id, TranslationTypes.name);
			productName = getTranslatedString(
				AppFunction.Product,
				option.productId ?? -1,
				TranslationTypes.name,
			);
		}
		const label = `${productName} ${variantName}`;
		let key: string = option.id.toString();
		if (option.orderProductId) {
			key += `-${option.orderProductId}`;
		}
		const type = option.recipe?.recipeType;
		return (
			<MenuItem
				{...props}
				key={key}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: theme.spacing(1),
				}}
			>
				<Chip
					label={t(`${AppFunction.Product}.recipeTypes.${type}`)}
					size='small'
					icon={getIcon(AppFunction.Product, type) ?? <></>}
				/>
				<Chip label={option.sku} size='small' icon={<QrCode />} />
				<div
					style={{
						flexGrow: 1,
						flexBasis: 0,
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					{label}
				</div>
			</MenuItem>
		);
	};

	/**
	 * Returns the label for the {@link Autocomplete} options
	 * @param {EnhancedVariant} option - the option to get the label for
	 * @returns {string} - the label for the option
	 */
	const getOptionLabel = (option: EnhancedVariant): string => {
		if (typeof option === 'object' && option !== null) {
			const productName = getTranslatedString(
				AppFunction.Variant,
				option.id,
				TranslationTypes.name,
			);
			if (option.expiresAt) {
				return `[${option.sku}] - ${productName} - ${option.expiresAt.toString().slice(0, 10)}`;
			}
			return `[${option.sku}] - ${productName}`;
		} else {
			return option;
		}
	};

	/**
	 * Compares the option to the value
	 * @param {EnhancedVariant} option - the option to compare
	 * @param {EnhancedVariant} value - the value to compare
	 * @returns {boolean} - true if the option is equal to the value
	 */
	const isOptionEqualToValue = (option: EnhancedVariant, value: EnhancedVariant): boolean => {
		if (option.expiresAt && value.expiresAt) {
			return option?.id === value?.id && option?.expiresAt === value?.expiresAt;
		}
		return option?.id === value?.id;
	};

	/**
	 *
	 * @param _
	 * @param newValue
	 */
	const onChange = (_: SyntheticEvent<Element, Event>, newValue: EnhancedVariant | null) => {
		if (typeof newValue === 'object' && newValue !== null) {
			const variant = variantList.find((v) => v.id === newValue.id);
			const recipe = variant?.recipe;
			if (recipe) {
				if (recipe.processingLocation.length === 1) {
					setSuborderProperties({
						subOrderCode: subOrder.code ?? '',
						toType: OrderDirectionType.warehouse,
						through: recipe.processingLocation[0],
						variantToCreate: newValue.id,
					});
				}
			}
		} else {
			setSuborderProperties({
				subOrderCode: subOrder.code ?? '',
				toType: OrderDirectionType.warehouse,
				through: -1,
				variantToCreate: -1,
			});
		}
	};

	const renderProcessingProductOptions = (ingredients: IngredientVariants) => {
		return (
			<Grid item xs={5} container rowGap={2}>
				<Grid
					item
					xs={12}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						border: '1px solid black',
						borderRadius: '1rem',
						marginBottom: '1rem',
					}}
				>
					<Typography variant='body2'>
						{t('product.ingredient', { count: ingredients.length })}
					</Typography>
				</Grid>
				{ingredients.map((ing, it) => {
					const ingVariant = variantList.find((v) => v.id === ing.variantId);
					const ingredientVariants: WarehouseInventoriesResponse = [];
					Object.values(inventories).forEach((inv) => {
						const warehouseProducts = inv.filter((op) => op.variantId === ing.variantId);
						if (warehouseProducts && warehouseProducts.length > 0) {
							ingredientVariants.push(...warehouseProducts);
						}
					});
					const selectedIngredient = subOrder.content ? subOrder.content[it] : null;

					if (!ingVariant) {
						return null;
					}

					const productName = getTranslatedString(
						AppFunction.Product,
						ingVariant?.productId ?? -1,
						TranslationTypes.name,
					);

					const variantName = getTranslatedString(
						AppFunction.Variant,
						ingVariant.id,
						TranslationTypes.name,
					);

					return (
						<Grid item key={it} xs={12} display='flex' flexDirection='row'>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									border: '1px solid black',
									borderRadius: '0.3rem',
									padding: '0.5rem',
									marginRight: '0.5rem',
								}}
							>
								{`${productName} ${variantName}:`}
							</div>
							{ingredientVariants.length > it && (
								<Autocomplete
									id='test-autocomplete'
									size='small'
									sx={{ flexGrow: '1' }}
									options={ingredientVariants}
									value={
										ingredientVariants.find(
											(v) =>
												v.variantId === selectedIngredient?.variantId &&
												v.warehouseId === subOrder.fromId,
										) ?? null
									}
									getOptionLabel={(option) => {
										if (typeof option === 'object' && option !== null) {
											const warehouseName = getTranslatedString(
												AppFunction.Location,
												option.warehouseId,
												TranslationTypes.name,
											);
											if (option.expiresAt) {
												return `[${warehouseName}] - ${option.expiresAt.toString().slice(0, 10)}`;
											}
											return `[${warehouseName}]`;
										}
										return option;
									}}
									isOptionEqualToValue={(option, value) => {
										if (option.expiresAt && value.expiresAt) {
											return (
												option?.variantId === value?.variantId &&
												option?.expiresAt === value?.expiresAt &&
												option?.warehouseId === value?.warehouseId
											);
										}
										return option?.variantId === value?.variantId;
									}}
									renderOption={(props, option, { selected }) => {
										let warehouseName = 'none';
										if (option.warehouseId !== -1) {
											warehouseName = getTranslatedString(
												AppFunction.Location,
												option.warehouseId,
												TranslationTypes.name,
											);
										}
										let label = `${selected ? '> ' : ''}[${warehouseName}]`;
										if (option.expiresAt) {
											label += ` - ${option.expiresAt.toString().slice(0, 10)}`;
										}
										let key: string = option.variantId.toString();
										if (option.expiresAt) {
											key = `${option.variantId}-${option.expiresAt.toString().slice(0, 10)}`;
										}
										return (
											<MenuItem {...props} key={`${key}-${option.warehouseId}-${option.unit}`}>
												{label}
											</MenuItem>
										);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label='variant'
											variant='outlined'
											inputProps={{
												...params.inputProps,
											}}
										/>
									)}
									onChange={(_, newValue) => {
										// console.log(newValue);
										if (typeof newValue === 'object' && newValue !== null) {
											const expiresAt = newValue.expiresAt ?? null;
											const newUnit =
												newValue.variant?.purchaseUnitWeight === 0 ? 'pieces' : 'grams';
											changeVariant(
												{
													variantId: newValue.variantId,
													expiresAt: expiresAt,
													id: newValue.orderProduct?.id ?? 0,
													unit: newUnit,
												},
												subOrder.code ?? '',
												it,
											);
											setSuborderProperties({
												subOrderCode: subOrder.code ?? '',
												fromId: newValue.warehouseId,
											});
										} else {
											changeVariant({ variantId: -1 }, subOrder.code ?? '', it);
											console.log('noop');
										}
									}}
								/>
							)}
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									border: '1px solid black',
									borderRadius: '0.3rem',
									padding: '0.5rem',
									marginLeft: '0.5rem',
								}}
							>
								{selectedIngredient?.orderQty
									? `${selectedIngredient.orderQty} ${ing.unitWeight === 0 ? 'pcs' : 'g'}`
									: '0'}
							</div>
						</Grid>
					);
				})}
			</Grid>
		);
	};

	return (
		<FadeWrapper>
			<Grid container spacing={2} item xs={12} justifyContent='center' flex={'horizontal'}>
				<CutSuborderProductHeader value={value} subOrder={subOrder} />
				{/* select variant to create */}
				<Grid
					item
					flexGrow={1}
					flexBasis={0}
					justifyContent='center'
					alignItems='center'
					display='flex'
				>
					<Autocomplete
						id='test-autocomplete'
						size='small'
						fullWidth
						options={availableVariants}
						value={value}
						getOptionLabel={getOptionLabel}
						isOptionEqualToValue={isOptionEqualToValue}
						renderOption={renderOption}
						renderInput={(params) => (
							<TextField
								{...params}
								label='variant'
								variant='outlined'
								inputProps={{
									...params.inputProps,
								}}
							/>
						)}
						onChange={onChange}
					/>
				</Grid>
				{/* ingredients. Renders conditionally depending on the recipe type */}
				{value &&
					(ingredients ? (
						recipe.recipeType === 'division' ? (
							<DivisionProduct
								recipe={recipe}
								value={value}
								subOrder={subOrder}
								automate={automateIngredientSelection}
								setAutomate={setAutomateIngredientSelection}
								lastUpdatedGrams={lastUpdatedGrams}
								setLastUpdatedGrams={setLastUpdatedGrams}
								setOutputLimitReached={setOutputLimitReached}
							/>
						) : (
							renderProcessingProductOptions(ingredients)
						)
					) : (
						<Grid item xs={3} display='flex' justifyContent='center' alignItems='center'>
							Recipe has no ingredients.
						</Grid>
					))}
				{/* order quantity selection */}
				{value && (
					<Grid item xs={1} justifyContent='center' alignItems='center' display='flex'>
						<TextField
							fullWidth
							variant='outlined'
							size='small'
							label='Order Qty'
							InputProps={{
								inputProps: { min: 1, max: outputLimitReached ? lastUpdatedGrams : undefined },
								endAdornment: (
									<Typography>
										{recipe?.recipeType === RecipeType.division
											? 'g'
											: t(`${AppFunction.Product}.units.pieces`, {
													count: subOrder.targetQuantity ?? 0,
											  })}
									</Typography>
								),
							}}
							type='number'
							value={subOrder.targetQuantity ?? 1}
							onChange={(event) => {
								const newValue = parseInt(event.target.value) ?? 0;
								// this is not really needed since we set the max value in the inputProps
								// but I'll leave it here for good measure
								if (!outputLimitReached) {
									setSuborderProperties({
										subOrderCode: subOrder.code ?? '',
										targetQuantity: isNaN(newValue) ? 0 : newValue,
									});
								} else if (newValue < (subOrder?.targetQuantity ?? 0)) {
									setSuborderProperties({
										subOrderCode: subOrder.code ?? '',
										targetQuantity: isNaN(newValue) ? 0 : newValue,
									});
								}
							}}
						/>
					</Grid>
				)}
			</Grid>
		</FadeWrapper>
	);
};

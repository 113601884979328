import useProducts from '@hooks/useProducts';
import { CloseOrderFieldAction, CloseOrderValue } from '../../../config';
import { Grid, IconButton, useTheme } from '@mui/material';
import { AppFunction, OrderProductResponse } from 'common';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { CloseOrderFieldListItem } from '../../../shared/CloseOrderFieldListItem';
import { getParsedId } from '@components/taskworkflow/tasks/utils/utils';
import { ImportChildMainRow } from './ImportChildMainRow';
import { ImportChildSubRow } from './ImportChildSubRow';
import { KeyboardReturn, Lock, Science, Warning } from '@mui/icons-material';
import { CloseOrderFieldListItemAction } from '../../../shared/CloseOrderFieldListItemActions';
import { ReturnChildItem } from './ReturnChildItem';
import { InspectionChildItem } from './InspectionChildItem';

interface Props {
	content: OrderProductResponse;
	value: CloseOrderValue;
	iterator: number;
	updateField: (
		orderProductId: number,
		field: CloseOrderFieldAction,
		value: Moment | null | number | boolean,
		splitIndex?: number,
		variable?: boolean,
	) => void;
}

export interface SubItemProps extends Props {
	variable: boolean;
	handleQuantityChange: (newQty: number, idx: number) => void;
}

/**
 * Import child item component.
 */
export const ImportChildItem = ({ content, value, iterator, updateField }: Props) => {
	const { variantList } = useProducts();
	const theme = useTheme();

	const [isLocked, setIsLocked] = useState(false);

	const variant = variantList.find((v) => v.id === content.variantId);

	useEffect(() => {
		if (value.newValues?.length <= 1) {
			setIsLocked(false);
		}
	}, [value?.newValues]);

	/**
	 * Toggles the lock state of the quantity values.
	 */
	const toggleLock = () => {
		setIsLocked((prevLocked) => {
			const newLocked = !prevLocked;
			if (newLocked) {
				redistributeValues();
			}
			return newLocked;
		});
	};

	/**
	 * Redistributes the arrived quantities based on the first value
	 * when the quantity is locked.
	 */
	const redistributeValues = () => {
		const totalOrderQty = content.arrivedQty ?? 1;
		let remainingQty = totalOrderQty;

		const orderProductId = getParsedId(content.id);
		if (!orderProductId) {
			console.error('Invalid order product ID');
			return;
		}

		value.newValues.forEach((val, idx) => {
			if (idx === 0) {
				const adjustedQty = Math.min(val.arrivedQty ?? 0, totalOrderQty);
				remainingQty -= adjustedQty;
				updateField(orderProductId, 'arrivedQty', adjustedQty, idx);
			} else {
				const adjustedQty = remainingQty > 0 ? Math.min(val.arrivedQty ?? 0, remainingQty) : 0;
				remainingQty -= adjustedQty;
				updateField(orderProductId, 'arrivedQty', adjustedQty, idx);
			}
		});

		if (remainingQty < 0) {
			updateField(orderProductId, 'arrivedQty', totalOrderQty, 0);
		}
	};

	/**
	 * Updates the values of the arrived quantity based on the new quantity
	 * cascading through the other values.
	 * @param {number} newQty - The new quantity
	 * @param {number} idx - The index of the value to update
	 */
	const handleQuantityChange = (newQty: number, idx: number) => {
		const orderProductId = getParsedId(content.id);
		if (!orderProductId) {
			console.error('Invalid order product ID');
			return;
		}

		if (!isLocked) {
			updateField(orderProductId, 'arrivedQty', newQty, idx);
			return;
		}

		const totalOrderQty = content.orderQty ?? 1;
		let remainingQty = totalOrderQty - newQty;

		value.newValues.forEach((val, i) => {
			if (i === idx) {
				updateField(orderProductId, 'arrivedQty', newQty, i);
			} else {
				const adjustedQty = remainingQty > 0 ? Math.min(val.arrivedQty ?? 0, remainingQty) : 0;
				remainingQty -= adjustedQty;
				updateField(orderProductId, 'arrivedQty', adjustedQty, i);
			}
		});
	};

	if (!variant || !value.oldValues.expiresAt) {
		return null;
	}

	const actions = (): CloseOrderFieldListItemAction[] => {
		const notArrived = value.notArrived ? value.notArrived : false;
		if (notArrived) {
			return [
				{
					icon: <Warning />,
					action: () => {
						if (!content.id) return;
						updateField(+content.id, 'notArrived', false);
					},
					label: `${AppFunction.Order}.notArrived`,
				},
			];
		} else {
			return [
				{
					icon: <Warning />,
					action: () => {
						if (!content.id) return;
						updateField(+content.id, 'notArrived', true);
					},
					label: `${AppFunction.Order}.notArrived`,
				},
				{
					icon: <KeyboardReturn />,
					action: () => {
						if (!content.id) return;
						updateField(+content.id, value.returnValue ? 'removeReturn' : 'addReturn', true);
					},
					label: `${AppFunction.Order}.return`,
				},
				{
					icon: <Science />,
					action: () => {
						if (!content.id) return;
						updateField(
							+content.id,
							value.inspectionValue ? 'removeInspection' : 'addInspection',
							true,
						);
					},
					label: `${AppFunction.Order}.inspection`,
				},
			];
		}
	};

	return (
		<CloseOrderFieldListItem
			content={content}
			value={value}
			iterator={iterator}
			actions={actions()}
		>
			<Grid
				item
				flexShrink={1}
				sx={{
					opacity: value.notArrived ? 0.4 : 1,
					pointerEvents: value.notArrived ? 'none' : 'auto',
				}}
			>
				<IconButton onClick={() => toggleLock()}>
					<Lock color={isLocked ? 'primary' : 'inherit'} />
				</IconButton>
			</Grid>
			<Grid item flexGrow={1} flexBasis={0} sx={{ opacity: value.notArrived ? 0.4 : 1 }}>
				{value.newValues &&
					value.newValues.length > 0 &&
					value.newValues.map((_, subIt) => {
						if (subIt === 0) {
							return (
								<ImportChildMainRow
									key={subIt}
									content={content}
									value={value}
									iterator={subIt}
									locked={isLocked}
									variable={variant.variable}
									updateField={updateField}
									handleQuantityChange={handleQuantityChange}
								/>
							);
						}
						return (
							<ImportChildSubRow
								key={subIt}
								content={content}
								value={value}
								iterator={subIt}
								variable={variant.variable}
								updateField={updateField}
								handleQuantityChange={handleQuantityChange}
							/>
						);
					})}
				{value.returnValue && (
					<ReturnChildItem
						content={content}
						value={value}
						locked={isLocked}
						iterator={-1}
						variable={variant.variable}
						updateField={updateField}
						handleQuantityChange={handleQuantityChange}
					/>
				)}
				{value.inspectionValue && (
					<InspectionChildItem
						content={content}
						value={value}
						locked={isLocked}
						iterator={-2}
						variable={variant.variable}
						updateField={updateField}
						handleQuantityChange={handleQuantityChange}
					/>
				)}
			</Grid>
		</CloseOrderFieldListItem>
	);
};

import { SubOrderRowProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

export const CutSuborderRow = ({ subOrderCode }: SubOrderRowProps) => {
	const { subOrders, config, removeSubOrder } = useOrderEditor();

	const currentSubOrder = subOrders.find((s) => s.code === subOrderCode);

	if (!config || !config.subOrderProductsContainer) {
		return null;
	}

	return (
		<Grid
			item
			xs={12}
			container
			sx={{
				margin: '1rem 0 1rem 0',
				padding: '1rem',
				borderBottom: subOrders.length > 1 ? '1px solid #000000' : '',
			}}
			spacing={2}
			display='flex'
		>
			<Grid item container flexGrow={1} flexBasis={0} rowSpacing={2}>
				<config.subOrderProductsContainer subOrder={currentSubOrder} />
			</Grid>
			<Grid
				item
				flexShrink={1}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<IconButton
					onClick={() => removeSubOrder(subOrderCode)}
					sx={{
						transition: 'transform 0.4s cubic-bezier(0.34, 1.56, 0.84, 1)',
						transform: 'scale(1)',
						'&:hover': {
							transform: 'scale(1.2)',
						},
					}}
				>
					<Delete />
				</IconButton>
			</Grid>
		</Grid>
	);
};

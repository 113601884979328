import { Scale } from '@mui/icons-material';
import { Chip, SxProps, Theme } from '@mui/material';

interface QuantityWeightProps {
	quantity?: number;
	unit?: 'pieces' | 'grams';
	weight?: number;
	remainingWeight?: number | null;
	estimated?: boolean | null;
	sx?: SxProps<Theme>;
}

export const QuantityWeightChip = <T extends QuantityWeightProps>({
	quantity,
	unit,
	weight,
	remainingWeight,
	estimated,
	sx,
}: T): JSX.Element | null => {
	if (!quantity && !weight) {
		return null;
	}

	return (
		<Chip
			size='small'
			icon={<Scale />}
			label={`${quantity}個 ${(remainingWeight || (weight ?? 1000)) / 1000}Kg`}
			color={estimated ? 'warning' : 'default'}
			sx={sx}
		/>
	);
};

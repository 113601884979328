import _, { forEach } from 'lodash';
import {
	ComponentType,
	Context,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { ZodSchema, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Control,
	DeepPartial,
	FieldErrors,
	FieldValues,
	Path,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormWatch,
	useForm,
} from 'react-hook-form';
import { getI18n, useTranslation } from 'react-i18next';

import { useLayout } from '..';

import useLocale from '@hooks/useLocale';
import useFeedbacks from '@hooks/useFeedbacks';
import useAppFunctions from '@hooks/useAppFunctions';

import { CombinedIcon } from '@components/common/CombinedIcon';
import { FadeWrapper } from '@components/common/FadeWrapper';

import { AffectedRowsResponse, AppFunction, ProductType, TranslationTypes } from 'common';

import { Button, Container } from '@mui/material';
import { Add, Edit, Search } from '@mui/icons-material';

import { TabFooter } from '@components/forms/shared/TabFooter';
import processLocaleEntries from 'helpers/processLocaleEntries';
import { BreadCrumb } from '@contexts/layoutContext/types';
import { TabContextProvider } from '@contexts/tabContext/TabContext';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { useNavigate } from 'react-router-dom';
import { FormProperty, FormRenderType, FormType, FormAction } from './types';
import { hasStringsProperty } from './utils';

interface ElementPrefix {
	appFunction: AppFunction;
	subFunction?: string;
	route?: string;
	valueName: string;
}

function hasPropertyInZodSchema(schema: z.ZodTypeAny, property: string): boolean {
	const properties = schema._def.shape();
	if (Object.keys(properties).includes(property)) {
		return true;
	}
	return false;
}

interface FormContextType<T extends FieldValues> {
	entityId: number;
	appFunction?: AppFunction;
	subFunction?: string | ProductType;
	formProperties: Set<FormProperty>;
	renderType: FormRenderType;
	formType: FormType;
	formAction: FormAction;
	showEditButton: boolean;
	formValues: T;
	errors: FieldErrors<Partial<T>>;
	control: Control<T>;
	forcedDirty: boolean;
	sideFabs?: FormSidefabType[];
	watch: UseFormWatch<T>;
	getValues: UseFormGetValues<T>;
	setValue: UseFormSetValue<T>;
	register: UseFormRegister<T>;
	onSubmit?: () => void;
	setShowFooterButton?: (show: boolean) => void;
	setCustomSubmit: (submitFn?: (data: T) => T) => void;
	setBeforeCreate: (beforeCreateFn?: () => Promise<boolean>) => void;
	setAfterCreate: (afterCreateFn?: (data: T) => Promise<boolean>) => void;
	setAfterEdit: (afterEditFn?: (data: T) => Promise<boolean>) => void;
	setForcedDirtyState: (dirty: boolean, appFunction?: AppFunction) => void;
	getNext?: () => void;
	getPrev?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultContext: FormContextType<any> = {
	entityId: -1,
	renderType: FormRenderType.standalone,
	formProperties: new Set(),
	formType: FormType.full,
	formAction: 'create',
	showEditButton: true,
	formValues: {},
	errors: {},
	control: {} as Control<Partial<unknown>>,
	forcedDirty: false,
	watch: {} as UseFormWatch<Partial<unknown>>,
	getValues: {} as UseFormGetValues<Partial<unknown>>,
	setValue: {} as UseFormSetValue<Partial<unknown>>,
	register: {} as UseFormRegister<Partial<unknown>>,
	setCustomSubmit: () => console.warn('No form context provider'),
	setBeforeCreate: () => console.warn('No form context provider'),
	setAfterCreate: () => console.warn('No form context provider'),
	setAfterEdit: () => console.warn('No form context provider'),
	setForcedDirtyState: () => console.warn('No form context provider'),
};

interface FormContextProps<T> {
	children: ReactNode;
	appFunction: AppFunction;
	subFunction?: string | ProductType;
	createFunction?: (data: T) => Promise<T>;
	editFunction?: (data: T) => Promise<AffectedRowsResponse>;
	renderType: FormRenderType;
	formType: FormType;
	formAction: FormAction;
	entityId: number;
	formValues: T;
	pagePrefix?: string;
	prefixIcon?: JSX.Element;
	elementPrefixes?: ElementPrefix[];
	formComponent?: ComponentType<{ children: ReactNode; entityId: number; editDisabled?: boolean }>;
	validationSchema: ZodSchema<T>;
	defaultValues: Partial<T> extends FieldValues ? T : never;
	hideMenu?: boolean;
	useFab?: boolean;
	sideFabs?: FormSidefabType[];
	customAfterCreateLink?: string;
	customHeader?: JSX.Element;
	getNext?: () => void;
	getPrev?: () => void;
	onDataSaved?: (data: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormContext = createContext<FormContextType<any> | undefined>(defaultContext);

export const FormContextProvider = <T extends FieldValues>({
	children,
	appFunction,
	subFunction,
	createFunction,
	editFunction,
	renderType,
	formType,
	formAction,
	entityId,
	formValues,
	pagePrefix,
	prefixIcon,
	elementPrefixes,
	formComponent: FormComponent,
	validationSchema,
	defaultValues,
	hideMenu,
	useFab,
	sideFabs,
	customAfterCreateLink,
	customHeader,
	getNext,
	getPrev,
	onDataSaved,
}: FormContextProps<T>) => {
	const { getTranslatedString } = useLocale();
	const { pushLoadingData, popLoadingData, setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { showSnackBar } = useFeedbacks();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [customSubmit, setCustomSubmitState] = useState<((data: T) => T) | undefined>();
	const [beforeCreateFunction, setBeforeCreateFunction] = useState<
		(() => Promise<boolean>) | undefined
	>();
	const [afterEditFunction, setAfterEditFunction] = useState<
		((data: T) => Promise<boolean>) | undefined
	>();
	const [afterCreateFunction, setAfterCreateFunction] = useState<
		((data: T) => Promise<boolean>) | undefined
	>();
	const [showFooterButton, setShowFooterButton] = useState(true);
	const [formProperties, setFormProperties] = useState<Set<FormProperty>>(new Set());

	const [forcedDirtyMap, setForcedDirtyMap] = useState<Map<string, boolean>>(new Map());
	const [forcedDirty, setForcedDirty] = useState(false);

	const {
		control,
		handleSubmit,
		reset,
		watch,
		getValues,
		setValue,
		register,
		formState: { errors },
	} = useForm<T>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: defaultValues as unknown as DeepPartial<T>,
		resolver: zodResolver(validationSchema),
	});

	// console.log(getValues());

	const getSubIcon = (): JSX.Element | null => {
		switch (formAction) {
			case 'create': {
				if (entityId === -1) {
					return <Add fontSize='inherit' />;
				}
				return null;
			}
			case 'edit':
				return <Edit fontSize='inherit' />;
			case 'view':
				return <Search fontSize='inherit' />;
		}
	};

	const refreshBreadCrumbs = () => {
		const breadCrumbs: BreadCrumb[] = [];
		if (renderType === FormRenderType.standalone) {
			if (pagePrefix) {
				breadCrumbs.push({
					label: `breadCrumbs.${pagePrefix}`,
					icon: prefixIcon ?? <></>,
					route: `/${pagePrefix}`,
				});
			}

			const elementPrefixBreadCrumbs: BreadCrumb[] = [];
			if (elementPrefixes) {
				elementPrefixes.forEach((elementPrefix) => {
					const targetValue = getValues(elementPrefix.valueName as Path<T>);
					if (targetValue === undefined || targetValue === null) {
						return;
					}

					const parsedRoute = elementPrefix.route?.replace(':value', targetValue.toString());

					elementPrefixBreadCrumbs.push({
						label: getTranslatedString(
							elementPrefix.appFunction,
							targetValue,
							TranslationTypes.name,
						),
						icon: getIcon(elementPrefix.appFunction, elementPrefix.subFunction) ?? <></>,
						route: parsedRoute ?? '',
					});
				});
			}

			setBreadCrumbs([
				...breadCrumbs,
				{
					label: `appFunctions.${subFunction ? `${appFunction}s.${subFunction}` : appFunction}`,
					icon: getIcon(appFunction, subFunction) ?? <></>,
					route: `${pagePrefix ? `/${pagePrefix}` : ''}/${subFunction ?? appFunction}`,
				},
				...elementPrefixBreadCrumbs,
				{
					label:
						entityId === -1
							? t('operations.createItem', {
									item: t(
										`appFunctions.${subFunction ? `${appFunction}s.${subFunction}` : appFunction}`,
									),
							  })
							: getTranslatedString(appFunction, entityId, TranslationTypes.name),
					icon: (
						<CombinedIcon
							main={getIcon(appFunction, subFunction) ?? <></>}
							sub={getSubIcon() ?? <></>}
						/>
					),
				},
			]);
		}
	};

	useEffect(() => {
		const properties: FormProperty[] = [];
		Object.entries(FormProperty).forEach(([, value]) => {
			if (hasPropertyInZodSchema(validationSchema, value)) {
				properties.push(value);
			}
		});
		setFormProperties(new Set(properties));
	}, [validationSchema]);

	useEffect(() => {
		reset(formValues);
	}, [formValues]);

	useEffect(() => {
		if (entityId === -1) {
			reset(defaultValues);
		}
	}, [defaultValues]);

	useEffect(() => {
		if (formAction === 'create') {
			popLoadingData();
			refreshBreadCrumbs();
			return;
		}
		if (_.isEqual(formValues, {}) || _.isEqual(formValues, defaultValues)) {
			pushLoadingData({
				contentType: appFunction,
			});
		} else {
			popLoadingData();
			refreshBreadCrumbs();
		}
	}, [entityId, formValues, getI18n().language, formAction]);

	/**
	 * Submit the form
	 * @param {T} data - The form data
	 * @returns {void}
	 * - This function is called when the form is submitted
	 */
	const onSubmit = handleSubmit((data) => {
		let newData = data;
		if (customSubmit) {
			newData = customSubmit(data);
		}
		if (entityId === -1) {
			(beforeCreateFunction?.() ?? Promise.resolve(true))
				.then((canCreate) => {
					if (!canCreate) {
						return;
					}
					pushLoadingData({
						contentType: appFunction,
					});
					createFunction?.(newData)
						.then((response) => {
							(afterCreateFunction?.(response) ?? Promise.resolve(true)).then(() => {
								onDataSaved?.(response);
								showSnackBar({
									message: t('operations.success', {
										item: t(`appFunctions.${appFunction}`),
										action: t('operations.create'),
									}),
									severity: 'success',
								});
								let newUri = '';
								if (customAfterCreateLink) {
									const parts = customAfterCreateLink.split('/');
									forEach(parts, (part) => {
										if (part.startsWith(':')) {
											const key = part.slice(1);
											if (key === 'id') {
												newUri += `/${response.id}`;
											} else {
												newUri += `/${response[key]}`;
											}
										} else {
											newUri += `/${part}`;
										}
									});
								} else {
									if (pagePrefix) {
										newUri += `/${pagePrefix}`;
									}
									if (subFunction) {
										newUri += `/${subFunction}`;
									}
									newUri += `/${appFunction}/${response.id}/edit`;
								}
								popLoadingData();
								if (formType !== FormType.quick) {
									navigate(newUri);
								}
							});
						})
						.catch(() => {
							showSnackBar({
								message: t('operations.create.fail', {
									item: t(`appFunctions.${appFunction}`),
									action: t('operations.create'),
								}),
								severity: 'error',
							});
							popLoadingData();
						});
				})
				.catch((error) => {
					console.log('error', error);
					popLoadingData();
				});
		} else {
			if (hasStringsProperty(newData)) {
				const newStrings = processLocaleEntries(data.strings ?? [], formValues.strings ?? []);
				newData = {
					...newData,
					strings: newStrings,
				};
			}

			editFunction?.(newData)
				.then(() => {
					(afterEditFunction?.(newData) ?? Promise.resolve(true)).then(() => {
						onDataSaved?.(newData);
						showSnackBar({
							message: t('operations.success', {
								item: t(`appFunctions.${appFunction}`),
								action: t('operations.edit'),
							}),
							severity: 'success',
						});
					});
				})
				.catch((error) => {
					console.log(error);
					showSnackBar({
						message: t('operations.edit.fail', {
							item: t(`appFunctions.${appFunction}`),
							action: t('operations.edit'),
						}),
						severity: 'error',
					});
				});
		}
	});

	/**
	 * Set the custom submit function
	 * @param {(data: T) => T} [submitFn] - The custom submit function
	 * @returns {void}
	 * - The custom submit function
	 * - This is useful for cases where the form data needs to be modified before submission
	 */
	const setCustomSubmit = useCallback((submitFn?: (data: T) => T) => {
		setCustomSubmitState(() => submitFn);
	}, []);

	/**
	 * Set the function to run before creating the entity
	 * @param {() => Promise<boolean>} [beforeCreateFn] - The function to run before creating the entity
	 * @returns {void}
	 * - The function to run before creating the entity
	 */
	const setBeforeCreate = useCallback((beforeCreateFn?: () => Promise<boolean>) => {
		setBeforeCreateFunction(() => beforeCreateFn);
	}, []);

	/**
	 * Set the function to run after creating the entity
	 * @param {(data: T) => Promise<boolean>} [afterCreateFn] - The function to run after creating the entity
	 * @returns {void}
	 * - The function to run after creating the entity
	 */
	const setAfterCreate = useCallback((afterCreateFn?: (data: T) => Promise<boolean>) => {
		setAfterCreateFunction(() => afterCreateFn);
	}, []);

	/**
	 * Set the function to run after editing the entity
	 * @param {(data: T) => Promise<boolean>} [afterEditFn] - The function to run after editing the entity
	 * @returns {void}
	 * - The function to run after editing the entity
	 */
	const setAfterEdit = useCallback((afterEditFn?: (data: T) => Promise<boolean>) => {
		setAfterEditFunction(() => afterEditFn);
	}, []);

	/**
	 * Set the forced dirty state.
	 * - Forces the form to be considered dirty, even if no changes have been made.
	 * - This is used when we have sub forms that are not part of the main form.
	 * - If an app function is provided, the forced dirty state is set for that app function, otherwise it is set for the entire form.
	 * @param {boolean} dirty - The forced dirty state
	 * @param {AppFunction} [appFunction] - The app function
	 * @returns {void}
	 */
	const setForcedDirtyState = useCallback(
		(dirty: boolean, appFunction?: AppFunction) => {
			if (appFunction && forcedDirtyMap.get(appFunction) !== dirty) {
				const newMap = new Map(forcedDirtyMap);
				newMap.set(appFunction, dirty);
				setForcedDirtyMap(newMap);
				setForcedDirty(Array.from(newMap.values()).some((value) => value));
				return;
			}
			if (!dirty) {
				setForcedDirtyMap(new Map());
			}
			setForcedDirty(dirty);
		},
		[forcedDirtyMap],
	);

	/** Memoized form content */
	const FormContent = useMemo(() => {
		return (
			<TabContextProvider
				hideMenu={renderType === FormRenderType.popup || hideMenu}
				footerBody={
					// TODO: This should be a separate component OR we should
					// include it in the TabFooter component
					formType === FormType.quick ? (
						<div
							style={{
								padding: '1rem',
								width: '100%',
							}}
						>
							{formAction === 'create' && (
								<Button
									variant='contained'
									color='primary'
									type='submit'
									fullWidth
									style={{ marginTop: '1rem' }}
									onClick={onSubmit}
								>
									{t('operations.save')}
								</Button>
							)}
							{formAction === 'edit' && (
								<Button
									variant='contained'
									color='primary'
									type='submit'
									fullWidth
									style={{ marginTop: '1rem' }}
									onClick={onSubmit}
								>
									{t('operations.edit')}
								</Button>
							)}
						</div>
					) : (
						<TabFooter
							useFab={(formAction !== 'view' || renderType !== FormRenderType.popup) && useFab}
						/>
					)
				}
			>
				{children}
			</TabContextProvider>
		);
	}, [children, appFunction, formAction, entityId, t, formValues.strings]);

	const contextValue = {
		entityId,
		appFunction,
		subFunction,
		formProperties,
		renderType,
		formType,
		formAction,
		showEditButton: showFooterButton,
		formValues,
		sideFabs,
		forcedDirty,
		errors: errors as FieldErrors<T>,
		control: control as Control<T>,
		watch: watch as UseFormWatch<T>,
		getValues: getValues as UseFormGetValues<T>,
		setValue: setValue as UseFormSetValue<T>,
		register: register as UseFormRegister<T>,
		onSubmit,
		setCustomSubmit,
		setBeforeCreate,
		setAfterCreate,
		setAfterEdit,
		setShowFooterButton,
		setForcedDirtyState,
		getNext,
		getPrev,
	};

	return (
		<FormContext.Provider value={contextValue}>
			{formAction === 'create' ||
			(!_.isEqual(getValues(), {}) && !_.isEqual(getValues(), defaultValues)) ? (
				<FadeWrapper fadeTime={500}>
					<Container
						maxWidth={renderType === FormRenderType.popup ? false : 'md'}
						sx={{
							position: 'relative',
							margin: renderType === FormRenderType.popup ? '0' : 'auto',
							height: renderType === FormRenderType.popup ? '100%' : 'auto',
							padding: renderType === FormRenderType.popup ? '0 !important' : 'auto',
						}}
					>
						{customHeader && <>{customHeader}</>}
						{FormComponent ? (
							<FormComponent entityId={entityId} editDisabled={formAction === 'create'}>
								{FormContent}
							</FormComponent>
						) : (
							<>{FormContent}</>
						)}
						{renderType === FormRenderType.standalone ? <div style={{ height: '4rem' }} /> : <></>}
					</Container>
				</FadeWrapper>
			) : (
				<></>
			)}
		</FormContext.Provider>
	);
};

export function useGenericForm<T extends FieldValues>(): FormContextType<T> {
	const context = useContext(FormContext as Context<FormContextType<T> | undefined>);
	if (!context) {
		throw new Error('useGenericForm must be used within a FormContextProvider');
	}
	return context;
}

import { FadeWrapper } from '@components/common/FadeWrapper';
import { Container, Paper, useTheme } from '@mui/material';
import { AppFunction, GenericIdRequest, ProductType } from 'common';
import React, {
	useContext,
	createContext,
	useMemo,
	cloneElement,
	ReactNode,
	ReactElement,
	useState,
	useEffect,
	useCallback,
	useRef,
} from 'react';

import commonStyles from '@styles/Common.module.css';
import { DataTable, DataTablePFSEvent, DataTableRowEventParams } from 'primereact/datatable';
import { TableHeader } from '@components/common/TableHeader/TableHeader';
import { Column, ColumnProps } from 'primereact/column';
import useApp from '@hooks/useApp';
import _, { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@hooks/auth';
import i18n from 'i18n';
import { ContextualDropdownMenu, DropdownEntry } from '@components/ContextualDropdownMenu';
import {
	ArrowDownward,
	ArrowUpward,
	Delete,
	Edit,
	Pageview,
	Print,
	Send,
} from '@mui/icons-material';
import { useLayout } from '..';
import { FabData } from '@contexts/layoutContext/types';
import { DropDownActionType } from './types';

/**
 * @description - This interface is used to add an id to the values passed to the table.
 */
interface WithId {
	id?: number | string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}

interface CachedColumn {
	hidden: boolean;
	column: ReactElement<ColumnProps, typeof Column>;
}

const dropDownActionIcons: Record<DropDownActionType, JSX.Element> = {
	[DropDownActionType.edit]: <Edit />,
	[DropDownActionType.delete]: <Delete />,
	[DropDownActionType.details]: <Pageview />,
	[DropDownActionType.print]: <Print />,
	[DropDownActionType.export]: <ArrowDownward />,
	[DropDownActionType.import]: <ArrowUpward />,
	[DropDownActionType.send]: <Send />,
	[DropDownActionType.refresh]: <></>,
};

interface DropDownAction {
	type?: DropDownActionType;
	label?: string;
	customLink?: string;
	action?: (data: GenericIdRequest) => void;
	customIcon?: JSX.Element;
	disabled?: boolean;
}

/**
 * @description - This interface is used to define the context value for the table context.
 */
interface TableContextType<T extends WithId> {
	pageIdentifier: AppFunction | null;
	subIdentifier?: string;
	values: T[];
	hasFilters: boolean;
	hiddenColumns?: Record<string, boolean>;
	showFilters?: boolean;
	expanded?: boolean;
	setHiddenColumn: (column: string, value: boolean) => void;
	toggleFilters?: () => void;
	toggleExpanded?: () => void;
	customHeader?: ReactNode;
	tableVariant?: 'grouped' | 'ungrouped';
}

/**
 * @description - This is the default context value for the table context.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultContext: TableContextType<any> = {
	// Using 'any' for default context, as no specific type is inferred here
	pageIdentifier: null,
	hasFilters: false,
	values: [],
	setHiddenColumn: () => console.error('TableContextProvider not found'),
};

/**
 * @description This interface is used to define the props for the table context provider.
 * @template T The type of the values passed to the table. Must have an id property as stated in the {@link WithId} interface.
 * @property {AppFunction} pageIdentifier - The identifier of the page the table is on.
 * @property {string} subIdentifier - The sub identifier of the page the table is on.
 * @property {ReactNode} customHeader - The custom header of the table. It will be shown on the right of the table title.
 * @property {T[]} values - The values to be displayed in the table.
 * @property {(data: T) => ReactNode} rowExpansionTemplate - The row expansion template for the table.
 * @property {ReactNode} children - The children of the table context provider.
 * @property {boolean} hideCreate - A boolean to hide the create fab.
 * @property {'grouped' | 'ungrouped'} tableVariant - The variant of the table.
 * @property {() => void} customCreateAction - The custom create action for the create fab.
 * @property {number} highlightId - The id of the row to be highlighted.
 * @property {DropDownAction[]} actions - The actions to be displayed in the action column.
 * @property {actions} actions - The actions to be displayed in the action column.
 */
interface TableContextProps<T extends WithId> {
	pageIdentifier: AppFunction;
	values: T[];
	children: ReactNode;
	highlightId?: number;
	subIdentifier?: string | ProductType;
	customHeader?: ReactNode;
	hideCreate?: boolean;
	tableVariant?: 'grouped' | 'ungrouped';
	rowExpansionTemplate?: (data: T) => ReactNode;
	rowClassName?: (data: T) => string;
	customCreateAction?: (() => void) | Partial<FabData>[];
	actions?: DropDownAction[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableContext = createContext<TableContextType<any>>(defaultContext); // Context initialized with default context

/**
 * @description - This function checks if the element passed is a PrimeReact {@link Column} component.
 * This is a bit of a hack but the only way to check if a child is a {@link Column} component.
 * When updating primereact be careful and check that the type of the {@link Column} component and
 * the {@link ColumnProps} are still the same.
 * @param {ReactElement} child The child to check
 * @returns {boolean} True if the child is a {@link Column} component, false otherwise
 */
const isColumnComponent = (child: ReactElement): boolean => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return child.type === Column || (child.type as any).name === 'Column';
};

/**
 * @description - This component is used to provide the table context to the table and its children.
 * @param {T[]} props.values - The values to be displayed in the table. Takes an array of generic type T that extends {@link WithId} as a parameter.
 * @param {AppFunction} props.pageIdentifier - The identifier for the page the table is on. Takes {@link AppFunction} as a parameter.
 * @param {ReactNode} props.children - The children of the table context provider.
 * @returns
 */
export const TableContextProvider = <T extends WithId>({
	pageIdentifier,
	values,
	children,
	highlightId,
	subIdentifier,
	customHeader,
	hideCreate,
	tableVariant,
	rowExpansionTemplate,
	rowClassName,
	customCreateAction,
	actions,
}: TableContextProps<T>) => {
	const theme = useTheme();

	const { setFabs, visiblePadding, remToPixels } = useLayout();

	const { settings, editListSettings } = useApp();
	const { t } = useTranslation();
	const auth = useAuth();
	const columnsIdentifier = `${pageIdentifier}${tableVariant ? `_${tableVariant}` : ''}`;

	const [init, setInit] = useState(false);
	const [expandedRows, setExpandedRows] = useState<T[]>([]);
	const [cachedLanguage, setCachedLanguage] = useState<string>(i18n.language);
	const [cachedHiddenColumns, setCachedHiddenColumns] = useState<Record<string, boolean>>({});
	const [cachedColumns, setCachedColumns] = useState<Record<string, CachedColumn>>({});
	const [hiddenColumns, setHiddenColumns] = useState<Record<string, boolean> | undefined>(
		undefined,
	);

	const [expanded, setExpanded] = useState(
		settings?.listSettings?.[columnsIdentifier]?.expanded ?? false,
	);
	const [hasFilters, setHasFilters] = useState(false);
	const [showFilters, setShowFilters] = useState(
		settings?.listSettings?.[columnsIdentifier]?.showFilters ?? true,
	);
	const [rowsPerPage, setRowsPerPage] = useState(
		settings?.listSettings?.[columnsIdentifier]?.rowsPerPage ?? 10,
	);
	const [first, setFirst] = useState(0);

	const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
	const anchorRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		return () => setFabs([]);
	}, []);

	useEffect(() => {
		if (hideCreate) {
			setFabs([]);
			return;
		}

		let customAction: (() => void) | undefined = undefined;
		let subActions: FabData[] | undefined = undefined;
		if (!Array.isArray(customCreateAction)) {
			customAction = customCreateAction;
		} else {
			for (const subFab of customCreateAction) {
				const uuid = uniqueId('fab_sub_');
				if (!subActions) {
					subActions = [];
				}
				subActions.push({
					customAction: subFab.customAction,
					id: uuid,
					placement: 'topRight',
					...subFab,
				});
			}
		}

		setFabs({
			targetUrl: `/${
				subIdentifier ? subIdentifier.toLowerCase() : pageIdentifier.toLowerCase()
			}/create`,
			customAction,
			permission: pageIdentifier,
			subActions,
		});
	}, [hideCreate, pageIdentifier, subIdentifier]);

	useEffect(() => {
		if (pageIdentifier && auth.user?.id) {
			setInit(true);
		}
	}, [pageIdentifier, auth.user]);

	/**
	 * This effect is used to set the hidden columns in the context and in the settings.
	 * It also sets the showFilters state.
	 */
	useEffect(() => {
		setShowFilters(
			hasFilters ? settings?.listSettings?.[columnsIdentifier]?.showFilters ?? false : false,
		);

		if (!hiddenColumns) {
			console.log('hidden columns were undefined (inside useEffect)');
			return;
		}

		const settingsColumns = settings?.listSettings?.[columnsIdentifier]?.columns;

		let hasSameKeys = false;
		if (settingsColumns && hiddenColumns) {
			hasSameKeys = _.isEqual(Object.keys(hiddenColumns), Object.keys(settingsColumns));
		}
		if (!hasSameKeys && auth.user) {
			editListSettings({
				userId: auth.user?.id,
				functionName: pageIdentifier,
				tableVariant,
				columns: hiddenColumns,
			});
		} else if (!_.isEqual(hiddenColumns, settingsColumns)) {
			setHiddenColumns(settingsColumns);
		}
	}, [settings, auth.user, tableVariant]);

	const generateActionColumn = (): ReactNode => {
		if (actions && actions.length > 0) {
			const ActionsBodyTemplate = (rowData: T) => {
				const parseCustomLink = (link: string) => {
					const keys = link.match(/:[^/]+/g);
					if (keys) {
						keys.forEach((key) => {
							const keyName = key.slice(1);
							if (Object.prototype.hasOwnProperty.call(rowData, keyName)) {
								link = link.replace(key, rowData[keyName].toString());
							}
						});
					}
					return link;
				};

				const entries: DropdownEntry[] = [];
				actions.forEach((action) => {
					const id = rowData.id;
					if (id === null || id === undefined) return;
					let actionLabel = '';
					let actionIcon: JSX.Element = <></>;
					let entryLink: string | undefined = undefined;
					let actionAction: ((data: GenericIdRequest) => void) | undefined = undefined;

					if (action.type) {
						if (action.label) {
							actionLabel = t(action.label);
						} else if (action.type) {
							actionLabel = t(`tables.${action.type}`);
						}
						if (action.type !== DropDownActionType.delete) {
							if (action.customLink) {
								entryLink = parseCustomLink(action.customLink);
							} else {
								entryLink = `/${pageIdentifier.toLowerCase()}/${id}/${action.type}`;
							}
						} else if (action.type === DropDownActionType.delete && action.action) {
							actionAction = action.action;
						}
						actionIcon = dropDownActionIcons[action.type as DropDownActionType] || <></>;
					} else {
						actionLabel = t(action.label || '');
						actionIcon = action.customIcon || <></>;
						// TODO: action and entryLink should be exclusive
						if (action.action) {
							actionAction = action.action;
						}
						if (action.customLink) {
							entryLink = parseCustomLink(action.customLink);
						}
					}

					entries.push({
						entryLink,
						entryAction: () => actionAction?.({ id }),
						entryName: actionLabel,
						permission: pageIdentifier,
						entryIcon: actionIcon,
						disabled: action.disabled,
					});
				});

				return <ContextualDropdownMenu entries={entries} />;
			};

			return (
				<Column
					key={`${pageIdentifier}_action_column`}
					headerStyle={{ maxWidth: '3em', alignItems: 'right' }}
					filterHeaderStyle={{ maxWidth: '3em' }}
					bodyStyle={{
						maxWidth: '3em',
						overflow: 'visible',
						justifyContent: 'center',
						backgroundColor: 'white',
					}}
					field='actions'
					body={(data) => <ActionsBodyTemplate {...data} />}
					frozen
					alignFrozen='right'
				/>
			);
		}
		return null;
	};

	/**
	 * Parses the children of the table recursively and returns the parsed children.
	 * Only parses primereact {@link Column} components.
	 * - If no filters are found in the columns then we use {@link setHasFilters} to set the state to false
	 * and {@link setShowFilters} to set the state to false.
	 * - If the hidden columns' keys are not equal to the found columns' keys
	 * then we use {@link setHiddenColumns} to set the state to the found columns.
	 * - If the hidden columns haven't already been set in the context then we
	 * set them in the context.
	 * @returns {ReactNode} The parsed children
	 */
	const parseColumnsRecursively = (): ReactNode => {
		const newChildren: ReactNode[] = [];
		const newColumns: Record<string, boolean> = {};
		const tempCachedColumns: Record<string, CachedColumn> = {};
		let filters = false;

		const parseChildren = (children: ReactNode) => {
			React.Children.forEach(children, (child) => {
				if (React.isValidElement(child)) {
					if (isColumnComponent(child)) {
						let newChild =
							child.props.header !== null && child.props.header !== ''
								? cachedColumns[`${pageIdentifier}.${child.props.header}`]?.column
								: (child as ReactElement<ColumnProps, typeof Column>);
						if (
							cachedLanguage !== i18n.language ||
							!newChild ||
							(child.props.header &&
								child.props.header !== '' &&
								(cachedHiddenColumns?.[`${pageIdentifier}.${child.props.header}`] === undefined ||
									hiddenColumns?.[`${pageIdentifier}.${child.props.header}`] !==
										cachedHiddenColumns?.[`${pageIdentifier}.${child.props.header}`]))
						) {
							newChild = cloneElement(child, {
								...child.props,
								key: `${columnsIdentifier}.${child.props.header}`,
								hidden: hiddenColumns?.[`${pageIdentifier}.${child.props.header}`] ?? false,
								header: child.props.header ? t(`${pageIdentifier}.${child.props.header}`) : '',
								filter: child.props.filterElement,
								style: child.props.style,
							} as ColumnProps) as ReactElement<ColumnProps, typeof Column>;
						}
						newChildren.push(newChild);
						tempCachedColumns[`${pageIdentifier}.${child.props.header}`] = {
							hidden: hiddenColumns?.[`${pageIdentifier}.${child.props.header}`] ?? false,
							column: newChild,
						};
						if (child.props.header && child.props.header !== '') {
							newColumns[`${pageIdentifier}.${child.props.header}`] =
								hiddenColumns?.[`${pageIdentifier}.${child.props.header}`] ?? false;
						}
						if (child.props.filterElement !== null) {
							filters = true;
						}
					} else if (child.type === React.Fragment) {
						parseChildren(child.props.children);
					} else if (child.props && child.props.children) {
						parseChildren(child.props.children);
					}
				}
			});
		};

		parseChildren(children);
		setHasFilters(filters);
		if (!filters && auth.user) {
			setShowFilters(false);
		}
		setCachedHiddenColumns(newColumns);
		setCachedColumns(tempCachedColumns);
		setCachedLanguage(i18n.language);

		// TODO: look here, maybe we should see if the newColumns keys are not equal to the stored keys
		// and in that case set event the hidden keys in indexedDB
		if (!hiddenColumns || !_.isEqual(Object.keys(newColumns), Object.keys(hiddenColumns))) {
			setHiddenColumns(newColumns);
		}

		// TODO: we can add here the expansion column at the beginning of the table

		// Finally adding the action column (if actions are present)
		newChildren.push(generateActionColumn());

		return newChildren;
	};

	// TODO: this is a duplicate, since we have the values inside cachedColumns
	// I'm leaving this here for now, but we should remove it / decide what to do
	const parsedChildren = useMemo(() => {
		return parseColumnsRecursively();
	}, [children, hiddenColumns, i18n.language, auth.user]);

	const setHiddenColumn = useCallback(
		(column: string, value: boolean) => {
			// should we log the error?
			if (!pageIdentifier) return;
			if (hiddenColumns && auth.user?.id) {
				let newColumns = {};
				setHiddenColumns((oldColumns) => {
					newColumns = { ...oldColumns, [column]: value };
					return newColumns;
				});
				editListSettings({
					userId: auth.user.id,
					functionName: pageIdentifier,
					tableVariant,
					columns: newColumns,
				});
			}
		},
		[auth.user, pageIdentifier, tableVariant, hiddenColumns],
	);

	const toggleFilters = () => {
		if (!pageIdentifier) return;
		if (auth.user?.id) {
			setShowFilters(!showFilters);
			editListSettings({
				userId: auth.user.id,
				functionName: pageIdentifier,
				tableVariant,
				showFilters: !showFilters,
			});
		}
	};

	const toggleExpanded = () => {
		if (!pageIdentifier) return;
		if (auth.user?.id) {
			setExpanded(!expanded);
			editListSettings({
				userId: auth.user.id,
				functionName: pageIdentifier,
				tableVariant,
				expanded: !expanded,
			});
		}
	};

	const onPage = (event: DataTablePFSEvent) => {
		if (!pageIdentifier) return;
		if (auth.user?.id) {
			setFirst(event.first ?? 0);
			setRowsPerPage(event.rows ?? 10);
			editListSettings({
				userId: auth.user.id,
				functionName: pageIdentifier,
				tableVariant,
				rowsPerPage: event.rows ?? 10,
			});
		}
	};

	const contextValue = useMemo(
		() => ({
			pageIdentifier,
			subIdentifier,
			tableVariant,
			customHeader,
			hiddenColumns,
			setHiddenColumn,
			toggleFilters,
			toggleExpanded,
			showFilters,
			expanded,
			hasFilters,
			values,
		}),
		[
			pageIdentifier,
			subIdentifier,
			tableVariant,
			customHeader,
			hiddenColumns,
			setHiddenColumn,
			toggleFilters,
			showFilters,
			expanded,
			hasFilters,
			values,
		],
	);

	// TODO: remember to fetch the real menu width and apply it here (60px now)
	return (
		<>
			{/* TODO: this will be used in the custom context menu, do it later */}
			<div
				ref={anchorRef}
				style={{
					position: 'absolute',
					top: menuPosition ? menuPosition.top : -9999,
					left: menuPosition ? menuPosition.left : -9999,
					pointerEvents: 'none', // Make sure this div doesn’t interfere with clicks
				}}
			/>
			<TableContext.Provider value={contextValue} key={auth.user?.id ?? 'noId'}>
				<FadeWrapper fadeTime={500} forcedVisibility={init}>
					<Container maxWidth={false} sx={{ maxWidth: 'calc(100vw - 60px)' }}>
						<div
							className={`${commonStyles.baseDatatable} ${commonStyles.smallExpandedDatatable} ${
								showFilters ? '' : commonStyles.hideFilterColumns
							} ${expanded ? commonStyles.fullHeightDatatable : ''}`}
							style={{ perspective: 900, overflow: 'hidden' }}
						>
							<Paper
								sx={{
									paddingBottom: '20px',
									paddingTop: '20px',
									borderRadius: '20px',
									background: theme.palette.gradient.primary,
									transform: `scale(${init ? 1 : 0.98}) rotate3d(1, 0, 0, ${init ? 0 : 2}deg)`,
									transition: 'all 0.4s ease-out',
									height: expanded
										? `calc(100vh - ${visiblePadding.top}px - ${visiblePadding.bottom}px)`
										: '',
									display: expanded ? 'flex' : '',
									flexDirection: expanded ? 'column' : '',
								}}
							>
								<DataTable
									value={values}
									paginator
									removableSort
									filterDisplay='row'
									className='p-datatable-common'
									header={<TableHeader />}
									first={first}
									rows={rowsPerPage}
									expandedRows={expandedRows}
									onContextMenu={(e: DataTableRowEventParams) => {
										const event = e.originalEvent as unknown as MouseEvent;
										// console.log(event.screenX, event.screenY);
										return setMenuPosition({ top: event.pageY, left: event.pageX });
									}}
									onRowToggle={(e) => {
										rowExpansionTemplate && setExpandedRows(e.data);
									}}
									rowExpansionTemplate={(data) => rowExpansionTemplate?.(data)}
									paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
									rowsPerPageOptions={[10, 25, 50, 100]}
									rowClassName={(rowData) => {
										const highlight = rowData.id === highlightId ? 'highlight' : '';
										return `${highlight} ${rowClassName ? rowClassName(rowData) : ''}`;
									}}
									// alwaysShowPaginator={false}
									dataKey='id'
									rowHover
									responsiveLayout='scroll'
									scrollable
									size='small'
									emptyMessage={t('tables.empty', { listElement: t(`appBar.${pageIdentifier}`) })}
									currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
									filterLocale='ja-JP'
									onPage={onPage}
								>
									{parsedChildren}
								</DataTable>
							</Paper>
						</div>
					</Container>
				</FadeWrapper>
			</TableContext.Provider>
			{!expanded && (
				<div
					style={{
						height: `${visiblePadding.bottom !== 0 ? visiblePadding.bottom : remToPixels(4)}px`,
					}}
				/>
			)}
		</>
	);
};

export const useTable = <T extends WithId>() => useContext<TableContextType<T>>(TableContext);

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _, { max } from 'lodash';

import useLocale from '../../hooks/useLocale';
import useWarehouses from '../../hooks/useWarehouses';

import {
	TranslationTypes,
	WarehouseResponse,
	WarehousesResponse,
	AppFunction,
	GenericIdRequest,
} from 'common';

import { Grid, useTheme } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ButtonType, GuardedCreateButton } from '../../components/GuardedCreateButton';
import { WarehouseChip } from '../../components/WarehouseChip';

import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import {
	Create,
	Delete,
	Edit,
	Settings,
	SubdirectoryArrowRight,
	ViewAgenda,
} from '@mui/icons-material';
import { TableContextProvider } from '@contexts/index';
import { DropDownActionType } from '@contexts/tableContext/types';
import useAppFunctions from '@hooks/useAppFunctions';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { ContextualDropdownMenu } from '@components/ContextualDropdownMenu';
import { useNavigate } from 'react-router-dom';
import { CombinedIcon } from '@components/common/CombinedIcon';
import { TableBoolBody } from '@components/common/tablecells/TableBoolBody';

const appFunction = AppFunction.Location;

export const WarehouseList = () => {
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const { warehouseList, deleteWarehouse, getSubWarehouses } = useWarehouses();
	const { getI18NString, renderI18NField } = useLocale();
	const { setBreadCrumbs } = useLayout();
	const { pushDialog } = useFeedbacks();
	const theme = useTheme();
	const navigate = useNavigate();

	const [parentWarehouseList, setParentWarehouseList] = useState<WarehousesResponse>(
		warehouseList.filter((w) => w.parentId === null),
	);
	const [expandedRows, setExpandedRows] = useState<WarehouseResponse[]>();

	useEffect(() => {
		setBreadCrumbs([
			{
				label: 'breadCrumbs.settings',
				route: '/settings',
				icon: <Settings />,
			},
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	useEffect(() => {
		const newList = warehouseList.filter((w) => w.parentId === null);
		setParentWarehouseList((currentList) => {
			if (!_.isEqual(currentList, newList)) {
				return newList;
			}
			return currentList;
		});
	}, [warehouseList]);

	// #region DataTable Elements
	function callDeleteLocationDialog(data: GenericIdRequest) {
		pushDialog({
			title: t('dialog.areYouSure'),
			type: 'withActions',
			message: t('operations.delete') + ' ' + t('appBar.location', { count: 1 }),
			actions: [
				{
					label: t('dialog.cancel'),
				},
				{
					label: t('dialog.ok'),
					action: async () => {
						deleteWarehouse(data).then((response) => {
							if (response) {
								console.log('Warehouse deleted');
							} else {
								console.log('Warehouse not deleted');
							}
						});
					},
				},
			],
		});
	}

	const ActionsBodyTemplate = (rowData: WarehouseResponse) => {
		return (
			<ContextualDropdownMenu
				entries={[
					{
						entryName: t('operations.edit'),
						entryIcon: <Edit />,
						entryLink: `/${AppFunction.Location}/${rowData.id}/edit`,
					},
					{
						entryName: t('operations.view'),
						entryIcon: <ViewAgenda />,
						entryLink: `/${AppFunction.Location}/${rowData.id}/details`,
					},
					{
						entryName: t('operations.delete'),
						entryIcon: <Delete />,
						entryAction: () => callDeleteLocationDialog({ id: rowData.id }),
					},
				]}
			/>
		);
	};

	const nameBodyTemplate = (rowData: WarehouseResponse) => {
		return renderI18NField(getI18NString(AppFunction.Location, rowData.id, TranslationTypes.name));
	};

	const descriptionBodyTemplate = (rowData: WarehouseResponse) => {
		return renderI18NField(
			getI18NString(AppFunction.Location, rowData.id, TranslationTypes.description),
		);
	};

	const storeTypeBodyTemplate = (rowData: WarehouseResponse) => <WarehouseChip data={rowData} />;

	const rowExpansionTemplate = (rowData: WarehouseResponse, idx = 0) => {
		if (typeof idx !== 'number') {
			console.error('Invalid idx:', idx);
			idx = 0;
		}

		const subs = getSubWarehouses(rowData.id);
		const recursionIdx: number = idx + 1;
		const leftSpace: number = 0.3 * recursionIdx;

		return (
			<Grid
				container
				spacing={0}
				sx={{
					padding: '8px 8px 8px 8px',
					background: theme.palette.gradient.primaryAlpha,
					borderLeft: '2px solid white',
					borderRight: '2px solid white',
				}}
			>
				<Grid item xs={12} sx={{ borderRadius: '1rem', padding: '0.2rem', marginBottom: '0.5rem' }}>
					<WarehouseChip data={rowData} />
				</Grid>
				<Grid item xs={12}>
					<DataTable
						// className={'p-datatable-warehouse-sub'}
						dataKey='id'
						size='small'
						value={subs}
						rowClassName={(data) => {
							const subs = getSubWarehouses(data.id);
							if (subs.length > 0) {
								return '';
							}
							return 'no-expansion sub-icon';
						}}
						rowExpansionTemplate={(data) => rowExpansionTemplate(data, recursionIdx)}
						expandedRows={expandedRows}
						onRowToggle={(e) => setExpandedRows(e.data)}
						style={{ fontSize: theme.typography.fontSize }}
					>
						<Column
							expander
							frozen
							style={{
								maxWidth: '4rem',
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: 'transparent',
								transform: `translateX(${leftSpace}rem)`,
							}}
						/>
						<Column field='name' body={nameBodyTemplate} />
						<Column field='type' body={storeTypeBodyTemplate} />
						<Column
							field='storageConditionType'
							header='storageConditionType'
							body={(row: WarehouseResponse) => (
								<>{t(`${appFunction}.storageConditionTypes.${row.storageCondition}`)}</>
							)}
						/>
						<Column
							field='isPickingSite'
							header='isPickingSite'
							body={(row: WarehouseResponse) => (
								<TableBoolBody value={row.isPickingSite ?? false} />
							)}
						/>
						<Column field='description' body={descriptionBodyTemplate} />
						<Column
							headerStyle={{ maxWidth: '3em', alignItems: 'right' }}
							filterHeaderStyle={{ maxWidth: '3em' }}
							bodyStyle={{
								maxWidth: '3em',
								overflow: 'visible',
								justifyContent: 'center',
								backgroundColor: 'white',
							}}
							field='actions'
							body={(data) => <ActionsBodyTemplate {...data} />}
							frozen
							alignFrozen='right'
						/>
					</DataTable>
				</Grid>
				<Grid item xs={12} sx={{ borderRadius: '0 0 1rem 1rem', backgroundColor: 'white' }}>
					<GuardedCreateButton
						targetUrl={`/location/create/${rowData.id}`}
						permission={AppFunction.Location}
						type={ButtonType.FULLWIDTH}
					/>
				</Grid>
			</Grid>
		);
	};
	// #endregion

	// #region React Dom Render
	return (
		<TableContextProvider
			key={parentWarehouseList.length}
			values={parentWarehouseList}
			pageIdentifier={appFunction}
			rowExpansionTemplate={rowExpansionTemplate}
			// TODO: this is supposedly supported on the new version of primereact
			// modify after updating
			rowClassName={(data) => {
				const subs = getSubWarehouses(data.id);
				if (subs.length > 0) {
					return '';
				}
				return 'no-expansion';
			}}
			actions={[
				{
					type: DropDownActionType.edit,
				},
				{
					label: t('operations.createChildItem', { item: t(`appFunctions.${appFunction}`) }),
					customIcon: (
						<CombinedIcon
							main={<Create />}
							sub={<SubdirectoryArrowRight />}
							subSx={{
								bottom: '-30%',
								right: -2,
							}}
						/>
					),
					action: (data) => navigate(`/location/create/${data.id}`),
				},
				{
					type: DropDownActionType.details,
				},
				{
					type: DropDownActionType.delete,
					action: callDeleteLocationDialog,
				},
			]}
		>
			<Column
				expander
				style={{
					width: '4rem',
					maxWidth: '4rem',
					display: 'flex',
					justifyContent: 'center',
				}}
				frozen={true}
			/>
			<Column
				field='name'
				header='name'
				// style={{ maxWidth: '20rem' }}
				body={nameBodyTemplate}
			/>
			<Column
				field='type'
				header='type'
				// style={{ maxWidth: '20rem' }}
				body={storeTypeBodyTemplate}
			/>
			<Column
				field='storageConditionType'
				header='storageConditionType'
				// style={{ maxWidth: '20rem' }}
				body={(row: WarehouseResponse) => (
					<>{t(`${appFunction}.storageConditionTypes.${row.storageCondition}`)}</>
				)}
			/>
			<Column
				field='isPickingSite'
				header='isPickingSite'
				// style={{ maxWidth: '20rem' }}
				body={(row: WarehouseResponse) => <TableBoolBody value={row.isPickingSite ?? false} />}
			/>
			<Column field='description' header='description' body={descriptionBodyTemplate} />
		</TableContextProvider>
	);
	// #endregion
};

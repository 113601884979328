import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useProducts from '@hooks/useProducts';
import useImages from '@hooks/useImages';
import useLocale from '@hooks/useLocale';

import { AppFunction, InternalOrderSubType, OrderProductResponse, TranslationTypes } from 'common';
import { Avatar, Box, Grid, ListItem, ListItemAvatar } from '@mui/material';
import { SkuChip } from '@components/common/chips/SkuChip';
import ExpiresAtChip from '@components/common/chips/ExpiresAtChip';

import { Note } from '@mui/icons-material';
import { ModifyOrderFieldValue } from '@contexts/taskContext/config/modifyOrderFieldConfig';

/**
 * Props for the CloseOrderFieldListItem component.
 * @interface Props
 * @property {OrderProductResponse} content - The order product content.
 * @property {ModifyOrderFieldValue} values - The close order value.
 * @property {number} iterator - The iterator number.
 */
interface ModifyOrderFieldListItemProps {
	content: OrderProductResponse;
	children: ReactNode;
	values: ModifyOrderFieldValue;
	iterator: number;
	subType?: InternalOrderSubType | null;
}

/**
 * Close Order Field List Item component.
 * Used as base component for all close order field list items.
 * @param {ModifyOrderFieldListItemProps} props - CloseOrderFieldListItem component props
 * @property {OrderProductResponse} content - The order product content.
 * @property {ModifyOrderFieldValue} values - The close order value.
 * @property {number} iterator - The iterator number.
 * @returns {JSX.Element} CloseOrderFieldListItem component
 */
export const ModifyOrderFieldListItem = ({
	content,
	children,
	values,
	iterator,
	subType,
}: ModifyOrderFieldListItemProps): JSX.Element | null => {
	const { variantList, productList } = useProducts();
	const { imageList } = useImages();
	const { getTranslatedString } = useLocale();
	const { t } = useTranslation();

	const variant = variantList.find((v) => v.id === content.variantId);

	if (!variant) {
		return null;
	}

	const imageUrl = useMemo(() => {
		let image = imageList.find((image) => image.id === variant?.images?.[0]?.id ?? -1);
		if (!image) {
			image = imageList.find(
				(image) =>
					image.id === productList.find((p) => p.id === variant.productId)?.images?.[0]?.id ?? -1,
			);
		}

		return image?.url ?? '';
	}, [content.variantId, variantList, productList, imageList]);

	return (
		<ListItem
			sx={{
				margin: '1rem 0 1rem 0',
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				borderRadius: '1rem',
				transition: 'all 0.2s ease-in-out',
				boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
				'&:hover': {
					boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
				},
				'&:before':
					!subType ||
					subType === InternalOrderSubType.simple ||
					subType === InternalOrderSubType.afterImport
						? {
								content: `"${(iterator ?? 0) + 1}"`,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '0.8rem',
								position: 'absolute',
								top: 'calc(50% - 0.6rem)',
								backgroundColor: 'black',
								color: 'white',
								left: '-1.6rem',
								width: '1.2rem',
								height: '1.2rem',
								zIndex: 0,
								borderRadius: '1rem',
								opacity: 0.4,
						  }
						: {},
			}}
		>
			<ListItemAvatar>
				<Avatar
					src={imageUrl}
					imgProps={{
						style: {
							objectFit: 'cover',
						},
					}}
				/>
			</ListItemAvatar>
			<Grid container display='flex' rowGap={2} p={1}>
				<Grid
					item
					display='flex'
					gap={1}
					alignItems='center'
					justifyContent='flex-start'
					flexGrow={1}
					flexBasis={0}
				>
					<SkuChip {...variant} orderProductId={content.sourceId ? +content.sourceId : undefined} />
					<div>
						{getTranslatedString(
							AppFunction.Product,
							variant.productId ?? -1,
							TranslationTypes.name,
						)}
					</div>
					<div>
						{getTranslatedString(
							AppFunction.Variant,
							content.variantId ?? -1,
							TranslationTypes.name,
						)}
					</div>
				</Grid>
				<Grid item display='flex' gap={1} justifyContent='space-evenly' alignContent='flex-end'>
					{/* <ExpiresAtChip {...content} /> */}
				</Grid>
				<Grid item xs={12} container display='flex' gap={1} alignItems='center'>
					{children}
				</Grid>
				{content.notes && (
					<Grid item xs={12}>
						<Box
							sx={{
								padding: '1rem',
								backgroundColor: 'rgba(0, 0, 0, 0.05)',
								borderRadius: '1rem',
								transition: 'all 0.2s ease-in-out',
								boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
								'&:hover': {
									boxShadow: '0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.03)',
								},
							}}
							display='flex'
							alignItems='center'
							justifyContent='flex-start'
							columnGap={1}
						>
							<Note color='success' />
							<Box>{content.notes}</Box>
						</Box>
					</Grid>
				)}
			</Grid>
		</ListItem>
	);
};

import {
	InternalOrderSubType,
	OrderBaseChildRequest,
	OrderBaseChildrenRequest,
	OrderBaseCreateSchema,
	OrderBaseRequest,
	OrderBaseResponse,
	OrderDirectionType,
	OrderProductCreateSchema,
	OrderProductsResponse,
	OrderType,
	ProcessingOrderSubType,
	ShippingType,
	StorageType,
	WarehouseInventoryResponse,
} from 'common';
import {
	EnhancedOrderBaseChildRequest,
	OrderApiActionData,
	OrderTypeConfig,
} from './orderTypeConfig';
import { OrderElementConfigItem } from './OrderElementConfigItem';
import { CutOrderProductRow } from '../suborder/rows/cut/CutSuborderProductRow';
import { uniqueId } from 'lodash';
import { doCreateOrder } from '@store/order';
import { PayloadAction } from '@reduxjs/toolkit';
import { CutOrderHeader } from '../header/cut/CutOrderHeader';
import { CutSuborderRow } from '../suborder/rows/cut/CutSuborderRow';

/**
 * Converts the suborder created by the order editor into a processing division order.
 * @param {OrderApiActionData} data
 * @param {EnhancedOrderBaseChildRequest} so
 * @param {string} date
 * @returns {OrderBaseRequest | null} - the converted order or null if no order is created
 */
export const createDivisionSuborder = (
	data: OrderApiActionData,
	so: EnhancedOrderBaseChildRequest,
	date: string,
): OrderBaseRequest | null => {
	if (!so.content || so.content.length === 0) {
		return null;
	}

	const newId = uniqueId();
	const tempContent: OrderProductsResponse = [];
	const tempSubOrderContent: Record<number, OrderProductsResponse> = {};
	// We need to determine if we need to create move orders for the products
	// or if the products are already in the cutting room

	let orderSubType = ProcessingOrderSubType.simple;

	let productsToMove = 0;
	let productsToCut = 0;
	so.content.forEach((c) => {
		const parsedContent = OrderProductCreateSchema.safeParse(c);
		if (parsedContent.success === false) {
			return Promise.reject(parsedContent.error);
		}

		if (!data.inventories) {
			return;
		}

		const sourceId = c.id;
		const foundProduct: WarehouseInventoryResponse | undefined = Object.values(
			data.inventories,
		).reduce<WarehouseInventoryResponse | undefined>((result, inv) => {
			if (result) return result;
			return (inv as WarehouseInventoryResponse[]).find(
				(invProd: WarehouseInventoryResponse) =>
					(invProd.weight === c.weight || invProd.remainingWeight === c.weight) &&
					invProd.unit === c.unit &&
					invProd.expiresAt === c.expiresAt,
			);
		}, undefined);

		if (!foundProduct) {
			return;
		}

		// the product is not in the cutting room
		// now we need to know if this is a product that needs to be moved to be cut
		// or if this is a product already in the destination warehouse
		// we need a cut when the product is in "pieces" or when it is in "grams" but
		// its picked weight is different from the weight of the piece
		if (foundProduct.warehouseId !== so.through) {
			if (!tempSubOrderContent[foundProduct.warehouseId]) {
				tempSubOrderContent[foundProduct.warehouseId] = [];
			}
			// the move order product has the following differencies from the cut order product
			// - orderQty is always 1 (we need to move 1 unit in order to cut/prepare it)
			// - pickedWeight is the weight of the full product in the warehouse (not the weight of the piece in the cut order)
			//   the weight of the actual piece will be used in the cut order (so we can track the weight of the cut pieces)
			// We do not move products that are already in the destination warehouse or that do not need to be cut
			// (the picked weight is the same as the weight of the piece)
			if (
				(foundProduct.unit === 'grams' && foundProduct.weight !== c.pickedWeight) ||
				(foundProduct.unit === 'pieces' && !Number.isInteger(c.orderQty))
			) {
				// we need to have the product to cut at [0]
				// because reasons (it will be parsed incorrectly by ProcessingCloseOrderFieldContainer in closeOrderField)
				console.log(parsedContent.data);
				if (parsedContent.data.orderQty && parsedContent.data.orderQty > 1) {
					orderSubType = ProcessingOrderSubType.multiple;
				}
				tempSubOrderContent[foundProduct.warehouseId].unshift({
					...parsedContent.data,
					sourceId,
					orderQty: parsedContent.data.orderQty ?? 1,
					// The weight of the product in the warehouse is an identifier so we need to keep it as it is
					// if we do not change it here the system will pick the remaining weight
					weight: foundProduct.weight,
					prevOrderId: foundProduct.orderProduct?.orderId
						? +foundProduct.orderProduct?.orderId
						: null,
					pickedWeight:
						foundProduct.unit === 'pieces'
							? Math.floor(
									(foundProduct.remainingWeight ?? foundProduct.weight) / foundProduct.quantity,
							  )
							: parsedContent.data.weight,
					// This is always true since we are moving the product to the cutting room
					// and they don't bother weighting every product so we are de facto estimating the weight of the product
					estimated: true,
				});
				productsToMove++;
				productsToCut++;
			}
		} else {
			if (
				(foundProduct.unit === 'grams' && foundProduct.weight !== c.pickedWeight) ||
				(foundProduct.unit === 'pieces' && !Number.isInteger(c.orderQty))
			) {
				productsToCut++;
			}
		}

		// for the record we need to add the product to the cut order
		// this info will be useful later on when closing the cut order
		// TODO: check if we need to pass estimated here too
		tempContent.unshift({
			...parsedContent.data,
			orderQty: Number.isInteger(parsedContent.data.orderQty)
				? parsedContent.data.orderQty
				: Math.ceil(parsedContent.data.orderQty ?? 0),
			pickedWeight: Math.round(parsedContent.data.pickedWeight ?? 0),
			sourceId,
			// TODO: check if this is always true
			estimated: false,
		});
	});

	if (productsToMove === 0 && productsToCut === 0) {
		orderSubType = ProcessingOrderSubType.compound;
	} else if (so.content.length !== productsToCut) {
		orderSubType = ProcessingOrderSubType.compoundCut;
	}

	// We now have the products that are in the cutting room and the products that need to be moved
	// We can now create the move orders
	const moveBeforeCutOrders: OrderBaseChildrenRequest = [];
	Object.keys(tempSubOrderContent).forEach((key) => {
		const content = tempSubOrderContent[Number(key)];
		if (content.length > 0) {
			const moveOrder: OrderBaseChildRequest = {
				code: `move-cut-${date}-${newId}-${key}`,
				orderType: OrderType.Internal,
				orderSubType: InternalOrderSubType.beforeCut,
				fromType: OrderDirectionType.warehouse,
				fromId: Number(key),
				toType: OrderDirectionType.warehouse,
				toId: so.through,
				shippingType: ShippingType.handDelivered,
				targetQuantity: null,
				variantToCreate: null,
				content: tempSubOrderContent[Number(key)],
			};
			moveBeforeCutOrders.push(moveOrder);
		}
	});

	console.log(so);

	const cutOrder: OrderBaseRequest = {
		...so,
		code: `cut-${date}-${newId}`,
		orderType: OrderType.Processing,
		orderSubType: orderSubType,
		fromType: OrderDirectionType.warehouse,
		fromId: so.through,
		toType: OrderDirectionType.warehouse,
		toId: so.toId,
		shippingType: ShippingType.handDelivered,
		content: tempContent,
		subOrders: moveBeforeCutOrders.length > 0 ? moveBeforeCutOrders : undefined,
	};
	return cutOrder;
};

/**
 * This is the configuration for the inbound order type.
 */
export const config: OrderTypeConfig = {
	headerElements: {
		code: new OrderElementConfigItem(false),
		fromType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		shippingType: new OrderElementConfigItem([OrderDirectionType.warehouse]),
		toType: new OrderElementConfigItem((directionType: OrderDirectionType) => {
			switch (directionType) {
				case OrderDirectionType.warehouse:
					return [OrderDirectionType.warehouse];
				default:
					return null;
			}
		}),
		toSubType: new OrderElementConfigItem(
			(directionType: OrderDirectionType, shippingType: ShippingType): StorageType[] | null => {
				switch (directionType) {
					case OrderDirectionType.warehouse:
						switch (shippingType) {
							case ShippingType.handDelivered:
								return [StorageType.processing];
							default:
								return null;
						}
					default:
						return null;
				}
			},
		),
		toWarehouseType: new OrderElementConfigItem(true),
		subOrderTypes: new OrderElementConfigItem(
			(directionType: OrderDirectionType, storageType: StorageType) => {
				switch (directionType) {
					case OrderDirectionType.warehouse:
						switch (storageType) {
							case StorageType.airport:
								return [
									OrderDirectionType.branch,
									OrderDirectionType.warehouse,
									OrderDirectionType.customer,
								];
							case StorageType.port:
								return [OrderDirectionType.warehouse];
							default:
								return null;
						}
					default:
						return null;
				}
			},
		),
	},
	headerElement: CutOrderHeader,
	maxSubOrders: 'unlimited',
	subOrderRow: CutSuborderRow,
	subOrderProductsContainer: CutOrderProductRow,
	isAddSuborderDisabled: () => false,
	isCreateOrderDisabled: () => false,
	/**
	 * Executes cut order action.
	 * - Each subOrder is a move order from a warehouse to a warehouse (processing),
	 * a cut order (suborder), and a move order from a warehouse (processing) to a warehouse (suborder).
	 * - The first move order and its corresponding cut order are created in the same step.
	 * - The second move order is created upon cut order completion.
	 * @param {OrderApiActionData} data
	 * @returns {Promise<OrderBaseResponse>}
	 */
	executeOrderAction: async (data: OrderApiActionData): Promise<OrderBaseResponse> => {
		const newOrders: OrderBaseRequest[] = [];
		const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');

		// DIVISION
		// Loop through the suborders and create the move orders if necessary
		// at this point move orders should be "suborders" and not "children" in the orderbase
		// this should assure that we see both the move order and the cut order in the same view
		data.subOrders?.forEach((so) => {
			const cutOrder = createDivisionSuborder(data, so, date);
			if (!cutOrder) {
				return Promise.reject('No cut order to create');
			}
			newOrders.push(cutOrder);
		});

		// Create the request only for the first order (testing)
		if (newOrders.length === 0) {
			return Promise.reject('No orders to create');
		}

		const requestData = newOrders[0];

		const validatedData = OrderBaseCreateSchema.safeParse(requestData);
		if (!validatedData.success) {
			return Promise.reject(validatedData.error);
		}

		const cutOrderResponse = (await data.dispatch(
			doCreateOrder(validatedData.data),
		)) as PayloadAction<OrderBaseResponse>;

		if (cutOrderResponse.type !== 'orders/create/fulfilled') {
			return Promise.reject('Cut order creation failed');
		}
		return cutOrderResponse.payload;
	},
};

import { FadeWrapper } from '@components/common/FadeWrapper';
import { SubOrderProductsContainerProps } from '@components/orders/config/orderTypeConfig';
import { useOrderEditor } from '@contexts/index';
import { Grid, InputAdornment, TextField, ToggleButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AppFunction } from 'common';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LocationRow } from '../common/LocationRow';
import useInventory from '@hooks/useInventory';
import { InputGridField } from '@components/common/InputGridField/InputGridField';
import { InventoryItemBanner } from '@components/common/banners/InventoryItemBanner';
import { ComponentType } from '@components/common/InputGridField/config/Index';

export const ModifyOrderProductRow = ({ subOrder }: SubOrderProductsContainerProps) => {
	const { changeOrderProduct: changeVariant } = useOrderEditor();
	const { inventories } = useInventory();
	const { t } = useTranslation();

	if (!subOrder) return null;

	const subOrderCode = subOrder.code;
	const content = subOrder.content?.[0];
	const inventoryItem = inventories[subOrder.toId ?? 0]?.find(
		(item) => item.orderProduct?.id === content?.id,
	);
	const variant = inventoryItem?.variant;

	if (!content || !subOrderCode || !inventoryItem || !variant) return null;

	return (
		<FadeWrapper key={subOrder?.code ?? 'empty-suborder'}>
			<Grid
				xs={12}
				container
				item
				display='flex'
				spacing={2}
				sx={{
					padding: '0 1rem 0 0',
				}}
			>
				<InventoryItemBanner inventoryItem={inventoryItem} />
				{variant.variable && (
					<>
						<InputGridField width={12} type={ComponentType.ToggleButton}>
							<ToggleButton
								value='check'
								size='small'
								selected={content.estimated ?? false}
								fullWidth
								onChange={() =>
									changeVariant(
										{
											estimated: !content.estimated,
										},
										subOrderCode,
										0,
									)
								}
								sx={{
									borderColor: content.estimated ? 'error.main' : 'success.main',
								}}
								color='error'
							>
								{content.estimated
									? t(`${AppFunction.Order}.processing.notWeighted`)
									: t(`${AppFunction.Order}.processing.weighted`)}
							</ToggleButton>
						</InputGridField>
					</>
				)}
				<Grid xs={12} item container spacing={2}>
					<Grid item xs={variant.variable ? 4 : 6}>
						<TextField
							size='small'
							type='number'
							label={t(`${AppFunction.Inventory}.quantity`)}
							variant='outlined'
							fullWidth
							value={content.arrivedQty}
							onChange={(e) => {
								changeVariant(
									{
										arrivedQty: parseInt(e.target.value),
									},
									subOrderCode,
									0,
								);
							}}
						/>
					</Grid>
					{variant.variable && (
						<Grid item xs={4}>
							<TextField
								size='small'
								type='number'
								label={t(`${AppFunction.Inventory}.weight`)}
								variant='outlined'
								fullWidth
								value={
									variant && variant.variable ? content.pickedWeight ?? 0 : content.weight ?? 0
								}
								disabled={!(variant && variant.variable)}
								InputProps={{
									endAdornment: variant?.variable ? (
										<InputAdornment position='end'>g</InputAdornment>
									) : null,
								}}
								onChange={(e) => {
									changeVariant(
										{
											pickedWeight: parseInt(e.target.value),
										},
										subOrderCode,
										0,
									);
								}}
							/>
						</Grid>
					)}
					<Grid item xs={variant.variable ? 4 : 6}>
						<DatePicker
							label={t(`${AppFunction.Inventory}.expiration`)}
							value={moment(content.expiresAt).startOf('day')}
							disablePast
							onChange={(newValue) => {
								changeVariant(
									{
										expiresAt: newValue?.startOf('day').toDate(),
									},
									subOrderCode,
									0,
								);
							}}
							renderInput={(params) => (
								<TextField {...params} fullWidth size='small' variant='outlined' />
							)}
							PopperProps={{ style: { zIndex: 9999 } }}
						/>
					</Grid>
				</Grid>
				{/* 
				<Grid xs={12} item container spacing={2}>
				<Grid item xs={3}>
						<TextField
							size='small'
							label={t(`${AppFunction.Inventory}.currency`)}
							variant='outlined'
							fullWidth
							value={content.actualCurrency ?? 1}
							onChange={(e) => {
								changeVariant(
									{
										actualCurrency: parseInt(e.target.value),
									},
									subOrderCode,
									0,
								);
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							size='small'
							type='number'
							label={t(`${AppFunction.Inventory}.price`)}
							variant='outlined'
							fullWidth
							value={content.actualPrice ?? 0}
							onChange={(e) => {
								changeVariant(
									{
										actualPrice: parseFloat(e.target.value),
									},
									subOrderCode,
									0,
								);
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							size='small'
							type='number'
							label={t(`${AppFunction.Inventory}.importTaxes`)}
							variant='outlined'
							fullWidth
							value={content.importTaxes ?? 0}
							onChange={(e) => {
								changeVariant(
									{
										importTaxes: parseFloat(e.target.value),
									},
									subOrderCode,
									0,
								);
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							size='small'
							type='number'
							label={t(`${AppFunction.Inventory}.transportFees`)}
							variant='outlined'
							fullWidth
							value={content.transportFees ?? 0}
							onChange={(e) => {
								changeVariant(
									{
										transportFees: parseFloat(e.target.value),
									},
									subOrderCode,
									0,
								);
							}}
						/>
					</Grid>
				</Grid> */}
			</Grid>
		</FadeWrapper>
	);
};

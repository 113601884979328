import { WarehouseChip } from '@components/WarehouseChip';
import { EnhancedOrderBaseChildRequest } from '@components/orders/config/orderTypeConfig';
import useAppFunctions from '@hooks/useAppFunctions';
import useInventory from '@hooks/useInventory';
import useProducts from '@hooks/useProducts';
import useWarehouses from '@hooks/useWarehouses';
import { Chip, Grid, ToggleButton, Typography, useTheme } from '@mui/material';
import { AppFunction, OrderProductsResponse, Recipe, WarehouseInventoriesResponse } from 'common';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrderEditor } from '@contexts/index';
import { EnhancedVariant } from '@contexts/orderEditorContext/types';

/**
 * Division product properties.
 * @property {Recipe} recipe - the recipe
 * @property {EnhancedVariant} value - the variant
 * @property {EnhancedOrderBaseChildRequest} subOrder - the suborder
 * @property {boolean} automate - the automation flag
 * @property {function} setAutomate - the automation flag setter
 * @property {number} lastUpdatedGrams - the last updated grams
 * @property {function} setLastUpdatedGrams - the last updated grams setter
 * @property {function} setOutputLimitReached - the output limit reached setter
 */
interface Props {
	recipe: Recipe;
	value: EnhancedVariant;
	subOrder: EnhancedOrderBaseChildRequest;
	automate: boolean;
	setAutomate: (value: boolean) => void;
	lastUpdatedGrams: number;
	setLastUpdatedGrams: (value: number) => void;
	setOutputLimitReached: (value: boolean) => void;
}

/**
 * Division product component.
 * Reacts to the automation toggle.
 * If automation is on, it will automatically divide the product.
 * If automation is off, it will allow manual division.
 * @param {Props} props - component properties
 * @returns {JSX.Element | null} the component
 */
export const DivisionProduct = ({
	recipe,
	value,
	subOrder,
	automate,
	setAutomate,
	lastUpdatedGrams,
	setLastUpdatedGrams,
	// TODO: do we need this? it seems to work without it, please check
	setOutputLimitReached,
}: Props): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const { variantList } = useProducts();
	const { warehouseList } = useWarehouses();
	const { inventories } = useInventory();

	const [itemLocations, setItemLocations] = useState<Record<number, number>>([]);

	const { changeVariants, deleteVariant } = useOrderEditor();

	useEffect(() => {
		if (
			automate &&
			subOrder &&
			lastUpdatedGrams !== subOrder.targetQuantity &&
			subOrder.targetQuantity !== 0
		) {
			const ingredients = recipe.ingredients;
			if (ingredients.length <= 0) return;
			const ing = ingredients[0];

			const ingVariant = variantList.find((v) => v.id === ing.variantId);
			if (!ingVariant) return;

			const inventoryItems: WarehouseInventoriesResponse = [];
			Object.values(inventories).forEach((inv) => {
				const warehouseProducts = inv
					.filter((op) => op.variantId === ing.variantId)
					.sort((a, b) => {
						// First sort by unit
						if (a.unit === 'grams' && b.unit !== 'grams') return -1;
						if (a.unit !== 'grams' && b.unit === 'grams') return 1;
						if (a.unit === 'pieces' && b.unit !== 'pieces') return 1;
						if (a.unit !== 'pieces' && b.unit === 'pieces') return -1;

						// Then sort by expiration date
						const dateA = a.expiresAt instanceof Date ? a.expiresAt : new Date(a.expiresAt);
						const dateB = b.expiresAt instanceof Date ? b.expiresAt : new Date(b.expiresAt);
						return dateA.getTime() - dateB.getTime();
					});

				if (warehouseProducts && warehouseProducts.length > 0) {
					inventoryItems.push(...warehouseProducts);
				}
			});

			const outputGrams = subOrder.targetQuantity ?? 0;

			const variantsToUpdate: OrderProductsResponse = [];
			const tempItemLocations: Record<number, number> = {};
			let accumulatedGrams = 0;
			let remainingGrams = outputGrams;
			let outputLimitReached = false;

			for (let i = 0; i < inventoryItems.length; i++) {
				const inventoryItem = inventoryItems[i];
				const currentWeight = inventoryItem.remainingWeight ?? inventoryItem.weight;

				if (inventoryItem.unit === 'grams') {
					// For items in grams, use the actual weight
					const orderGrams = Math.min(remainingGrams, currentWeight);

					variantsToUpdate.push({
						variantId: inventoryItem.variantId ?? -1,
						expiresAt: inventoryItem.expiresAt ?? new Date(),
						id: inventoryItem.orderProduct?.id ?? 0,
						unit: 'grams',
						orderQty: orderGrams,
						weight: currentWeight,
						pickedWeight: orderGrams,
					});

					accumulatedGrams += orderGrams;
					remainingGrams -= orderGrams;
				} else {
					// For items in pieces, calculate the estimated piece weight
					const estimatedPieceWeight = currentWeight / inventoryItem.quantity;
					let orderQty = remainingGrams / estimatedPieceWeight;

					// Ensure orderQty is not negative and account for fractional pieces
					orderQty = Math.max(orderQty, 0);

					if (remainingGrams >= currentWeight) {
						orderQty = inventoryItem.quantity;
						accumulatedGrams += currentWeight;
					} else {
						accumulatedGrams += orderQty * estimatedPieceWeight;
					}

					variantsToUpdate.push({
						variantId: inventoryItem.variantId ?? -1,
						expiresAt: inventoryItem.expiresAt ?? new Date(),
						id: inventoryItem.orderProduct?.id ?? 0,
						unit: 'pieces',
						orderQty: orderQty,
						weight: currentWeight,
						pickedWeight: orderQty * estimatedPieceWeight,
					});

					remainingGrams -= orderQty * estimatedPieceWeight;
				}

				tempItemLocations[inventoryItem.orderProduct?.id ? +inventoryItem.orderProduct.id : 0] =
					inventoryItem.warehouseId;

				if (remainingGrams <= 0) {
					outputLimitReached = true;
					break;
				}
			}

			setItemLocations(tempItemLocations);
			changeVariants(variantsToUpdate, subOrder.code ?? '');
			// setOutputLimitReached(outputLimitReached);

			if (subOrder.content && variantsToUpdate.length < subOrder.content.length) {
				console.log('more selected variants than needed');
				for (let i = variantsToUpdate.length; i <= subOrder.content.length; i++) {
					deleteVariant(i, subOrder.code ?? '');
				}
			}

			setLastUpdatedGrams(outputGrams);
		}
	}, [automate, subOrder?.targetQuantity, value, variantList, inventories]);

	const ingredients = recipe?.ingredients ?? [];

	// we have only one ingredient for division
	// let's abort the rendering if there are zero ingredients
	// this should not happen since we checked upon rendering
	if (ingredients.length === 0) {
		// TODO: consider adding a 'no ingredients' message
		return null;
	}

	const ing = ingredients[0];
	const ingVariant = variantList.find((v) => v.id === ing.variantId);

	// no ingredient variant, no party
	if (!ingVariant) {
		// TODO: consider adding a 'no variant' message
		return null;
	}

	// find from the inventories which variants are available and decide which to use
	const ingredientVariants: WarehouseInventoriesResponse = [];
	Object.values(inventories).forEach((inv) => {
		const warehouseProducts = inv
			.filter((op) => op.variantId === ing.variantId)
			.sort((a, b) => {
				const dateA = a.expiresAt instanceof Date ? a.expiresAt : new Date(a.expiresAt);
				const dateB = b.expiresAt instanceof Date ? b.expiresAt : new Date(b.expiresAt);
				return dateA.getTime() - dateB.getTime();
			});
		if (warehouseProducts && warehouseProducts.length > 0) {
			ingredientVariants.push(...warehouseProducts);
		}
	});

	// no ingredient variants that can be cut, no party
	if (ingredientVariants.length === 0) {
		// TODO: consider adding a 'no ingredient variants' message
		return null;
	}

	return (
		<Grid item xs={5} container>
			<Grid
				item
				xs={12}
				style={{
					display: 'flex',
					justifyContent: 'center',
					gap: '1rem',
					background: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					alignItems: 'center',
					border: '1px solid black',
					borderRadius: '0.5rem',
					marginBottom: '1rem',
					padding: '0.3rem',
				}}
			>
				{getIcon(AppFunction.Product, recipe.recipeType)}
				<Typography variant='body1'>
					{t(`${AppFunction.Product}.recipeTypes.${recipe?.recipeType}`)}
				</Typography>
				<ToggleButton
					value='check'
					size='small'
					selected={automate}
					onChange={() => setAutomate(!automate)}
					sx={{
						padding: '0 0.5rem 0 0.5rem !important',
						backgroundColor: automate ? 'green !important' : 'red',
						color: 'white !important',
					}}
				>
					auto: {automate ? 'on' : 'off'}
				</ToggleButton>
			</Grid>
			{subOrder.content?.map((subOrderProduct, it) => {
				if (automate) {
					const warehouseId = itemLocations[subOrderProduct.id ? +subOrderProduct.id : 0] ?? -1;
					const warehouse = warehouseList.find((wh) => wh.id === warehouseId);
					let quantity = `${(subOrderProduct.orderQty ?? 0).toFixed(2)} ${t(
						'product.units.pieces',
						{ count: 1 },
					)}`;
					let needsCut = true;
					if (subOrderProduct.unit === 'grams') {
						quantity = `${Math.min(
							subOrderProduct.pickedWeight ?? 0,
							subOrder.targetQuantity ?? 0,
						)} ${t('product.units.grams')}`;
						needsCut = subOrderProduct.pickedWeight !== subOrderProduct.weight;
					} else {
						needsCut = !Number.isInteger(subOrderProduct.orderQty ?? 0);
					}
					return (
						<Grid
							item
							key={it}
							xs={12}
							display='flex'
							flexDirection='row'
							justifyContent='center'
							gap={2}
							alignItems='flex-start'
							position='relative'
							container
							sx={{
								padding: '0.5rem',
							}}
						>
							<Grid
								item
								flexGrow={1}
								display='flex'
								flexDirection='row'
								justifyContent='flex-start'
								alignItems='center'
								style={{
									alignSelf: 'flex-start',
									height: '100%',
								}}
							>
								{warehouse && <WarehouseChip data={warehouse} showFull />}
							</Grid>
							{!needsCut && (
								<Grid
									item
									display='flex'
									flexDirection='row'
									justifyContent='center'
									alignItems='center'
									sx={{
										height: '100%',
									}}
								>
									<Chip label='カット不要' color='lightWarning' size='small' />
								</Grid>
							)}
							<Grid
								item
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '100%',
								}}
							>
								{moment(subOrderProduct.expiresAt).format('YYYY/MM/DD')}
							</Grid>
							<Grid
								item
								flexShrink={1}
								style={{
									padding: '0.5rem',
									border: '1px solid black',
									borderRadius: '0.3rem',
									minWidth: '6rem',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									right: 0,
								}}
							>
								{quantity}
							</Grid>
						</Grid>
					);
				}
				return (
					<Grid item key={it} xs={12} display='flex' flexDirection='row'>
						manual
					</Grid>
				);
			})}
		</Grid>
	);
};

import { useEffect, useState } from 'react';
import { Box, Button, Chip, Grid, Stack, useTheme } from '@mui/material';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AppFunction, OrderBaseResponse, OrderStatus, OrderType, OrdersBaseResponse } from 'common';
import {
	AddBox,
	Blender,
	CalendarMonth,
	Delete,
	Edit,
	MoveUp,
	PictureAsPdf,
	PointOfSale,
} from '@mui/icons-material';
import { useLayout } from '../../contexts/layoutContext/LayoutContext';
import useAppFunctions from '@hooks/useAppFunctions';
import { TableContextProvider } from '@contexts/index';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OrderCodeBodyTemplate } from '@components/tables/fields/OrderCodeBodyTemplate';
import { SelectableUserRowBodyTemplate } from '@components/tables/fields/SelectableUserRowBodyTemplate';
import { execute } from '@store/externalApi/io/apiDownloadPdf';
import { useFeedbacks } from '@contexts/feedbacksContext/FeedbacksContext';
import { DownloadPdfDialog } from '@components/dialogs/DownloadPdfDialog';
import {
	ModifyOrderDialog,
	ModifyOrderDialogData,
	ModifyOrderDialogResult,
} from '@components/dialogs/orders/ModifyOrderDialog';
import useOrders from '@hooks/useOrders';
import moment from 'moment';
import { ContextualDropdownMenu } from '@components/ContextualDropdownMenu';

const appFunction = AppFunction.Order;

export const OrderList = () => {
	const routeId = useParams()?.id;
	const theme = useTheme();
	const [orderFilter, setOrderFilter] = useState<OrderType | 'all'>('all');
	const { t } = useTranslation();
	const { setBreadCrumbs } = useLayout();
	const { getIcon } = useAppFunctions();
	const { pushComponentDialog } = useFeedbacks();
	const { pushPopup, popLoadingData, pushLoadingData } = useLayout();
	const { orderList, modifyOrder } = useOrders();

	const [expandedRows, setExpandedRows] = useState<OrdersBaseResponse>();
	const [showBooked, setShowBooked] = useState(false);

	useEffect(() => {
		setBreadCrumbs([
			{
				label: `breadCrumbs.${appFunction}`,
				icon: getIcon(appFunction) ?? <></>,
			},
		]);
	}, []);

	const getChildrenOrders = (parentId: number | null) => {
		return orderList.filter((order) => order.parentId === parentId);
	};

	const getGroupOrders = (groupId?: number | null) => {
		return orderList.filter((order) => order.id === groupId);
	};

	const downloadPdfBody = (data: OrderBaseResponse) => {
		return (
			<Button
				size='small'
				variant='contained'
				color='primary'
				onClick={async () => callDownloadPdfDialog(data.id)}
				disabled={data.orderType !== OrderType.Inbound && data.orderType !== OrderType.Outbound}
				sx={{
					borderRadius: '2rem',
				}}
			>
				<PictureAsPdf />
			</Button>
		);
	};

	const callDownloadPdfDialog = async (orderId: number) => {
		pushComponentDialog({
			title: t('operations.downloadItem', { item: 'pdf' }),
			component: DownloadPdfDialog,
			data: {
				orderId: orderId,
			},
			fullScreen: false,
			type: 'component',
		}).then(async (result) => {
			if (result !== false) {
				pushLoadingData({ contentType: AppFunction.Order });
				const url = await execute({
					orderId: result.orderId,
					language: result.language ?? 'jp',
					fullOrder: result.fullOrder ?? false,
					type: result.pdfType,
				});
				popLoadingData();
				pushPopup(
					<Box
						sx={{
							position: 'relative',
							width: '100%',
							height: '80vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<iframe src={url} style={{ width: '100%', height: '100%' }} title='PDF Preview' />
					</Box>,
				);
			} else {
				console.log('Canceled');
			}
		});
	};

	const callModifyOrderDialog = async (orderId: number) => {
		pushComponentDialog<ModifyOrderDialogResult, ModifyOrderDialogData>({
			title: t('operations.editItem', { item: t(`appFunctions.${AppFunction.Order}`) }),
			component: ModifyOrderDialog,
			data: {
				orderId: orderId,
			},
			fullScreen: false,
			type: 'component',
		})
			.then(async (result) => {
				if (result) {
					const order = orderList.find((order) => order.id === orderId);
					if (order) {
						const newOrder = { ...order, ead: result.newDate?.toISOString() };
						const response = await modifyOrder(newOrder);
						if (response) {
							console.log('Modified order', response);
						} else {
							console.error('Failed to modify order', response);
						}
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const ActionsBodyTemplate = (rowData: OrderBaseResponse) => {
		return (
			<ContextualDropdownMenu
				entries={[
					{
						entryName: t('operations.edit'),
						entryIcon: <Edit />,
						entryAction: () => callModifyOrderDialog(+rowData.id),
					},
					{
						entryName: t('operations.delete'),
						entryIcon: <Delete />,
						entryAction: () => console.log('delete'),
					},
				]}
			/>
		);
	};

	const rowExpansionTemplate = (rowData: OrderBaseResponse, idx: number) => {
		const children = getChildrenOrders(rowData.id);
		const groups = getGroupOrders(rowData.groupId);
		const recursionIdx = idx + 1;
		const leftSpace = 5 * recursionIdx + 15;

		const childrenGroups = [...children, ...groups];

		return (
			<Grid container spacing={0} sx={{ p: 0 }}>
				<Grid item xs={12}>
					<DataTable
						className={'p-datatable-common'}
						dataKey='id'
						size='small'
						value={childrenGroups}
						rowClassName={(data) => {
							const subs = getChildrenOrders(data.id);
							if (subs.length > 0) {
								return '';
							}
							return 'no-expansion sub-icon';
						}}
						rowExpansionTemplate={(data) => rowExpansionTemplate(data, recursionIdx)}
						expandedRows={expandedRows}
						onRowToggle={(e) => setExpandedRows(e.data)}
						style={{ fontSize: theme.typography.fontSize }}
					>
						<Column
							expander
							frozen
							style={{
								maxWidth: '4rem',
								overflow: 'visible',
								backgroundColor: 'transparent',
								transform: `translateX(${leftSpace}px)`,
							}}
						/>
						<Column
							field='code'
							header='code'
							sortable
							body={(data) => <OrderCodeBodyTemplate rowData={data} useIcon />}
						/>
						<Column
							field='createdBy'
							header='createdBy'
							sortable
							body={(data) => <SelectableUserRowBodyTemplate userId={data.userId} />}
						/>
						<Column
							field='orderType'
							header='orderType'
							body={(data) => {
								return <>{t(`${appFunction}.orderTypes.${data.orderType.toLowerCase()}`)}</>;
							}}
						/>
						<Column
							field='shippingType'
							header='shippingType'
							body={(data) => {
								return <>{t(`${appFunction}.shippingTypes.${data.shippingType.toLowerCase()}`)}</>;
							}}
						/>
						<Column
							field='ead'
							header='estimatedTime'
							headerStyle={{ display: 'flex', justifyContent: 'center' }}
							bodyStyle={{ display: 'flex', justifyContent: 'center' }}
							body={eadBody}
						/>
						<Column
							field='status'
							header='Status'
							style={{ maxWidth: '8rem' }}
							body={(data) => {
								const castedData = data as OrderBaseResponse;
								let color: 'default' | 'error' | 'warning' | 'info' | 'success' = 'default';
								switch (castedData.status) {
									case OrderStatus.Done:
										color = 'success';
										break;
									case OrderStatus.Pending:
										color = 'warning';
										break;
								}
								return (
									<Chip
										size='small'
										color={color}
										label={t(`${appFunction}.statuses.${data.status.toLowerCase()}`)}
										sx={{ width: '100%' }}
									/>
								);
							}}
						/>
						<Column field='pdf' header='pdf' style={{ maxWidth: '6rem' }} body={downloadPdfBody} />
						<Column
							field='actions'
							header='actions'
							body={ActionsBodyTemplate}
							headerStyle={{ maxWidth: '3em', alignItems: 'right' }}
							filterHeaderStyle={{ maxWidth: '3em' }}
							bodyStyle={{
								maxWidth: '3em',
								overflow: 'visible',
								justifyContent: 'center',
								backgroundColor: 'white',
							}}
							frozen
							alignFrozen='right'
						/>
					</DataTable>
				</Grid>
			</Grid>
		);
	};

	const orderStatusBody = (rowData: OrderBaseResponse) => {
		const castedData = rowData as OrderBaseResponse;
		let color: 'default' | 'error' | 'warning' | 'info' | 'success' = 'default';
		switch (castedData.status) {
			case OrderStatus.Done:
				color = 'success';
				break;
			case OrderStatus.Pending:
				color = 'warning';
				break;
			case OrderStatus.Booked:
				color = 'error';
				break;
		}
		return (
			<Chip
				size='small'
				color={color}
				label={t(`${appFunction}.statuses.${rowData.status?.toLowerCase()}`)}
				sx={{ width: '100%' }}
			/>
		);
	};

	const eadBody = (rowData: OrderBaseResponse) => {
		if (rowData.ead === null || rowData.ead === undefined)
			return <>{moment().format('YYYY-MM-DD')}</>;
		return <>{moment(rowData.ead).format('YYYY-MM-DD')}</>;
	};

	const Header = () => {
		const buttons: JSX.Element[] = [];

		buttons.push(
			<Button
				key='showBooked'
				size='small'
				onClick={() => setShowBooked((oldValue) => !oldValue)}
				variant={showBooked ? 'contained' : 'outlined'}
				sx={{ borderRadius: '2rem' }}
			>
				{t(`${appFunction}.statuses.booked`)}
			</Button>,
		);
		buttons.push(
			<Button
				key='all'
				size='small'
				onClick={() => setOrderFilter('all')}
				variant={orderFilter === 'all' ? 'contained' : 'outlined'}
				sx={{ borderRadius: '2rem' }}
			>
				{t(`${appFunction}.orderTypes.all`)}
			</Button>,
		);
		Object.keys(OrderType).forEach((key) => {
			buttons.push(
				<Button
					key={key}
					size='small'
					onClick={() => setOrderFilter(key as OrderType)}
					variant={orderFilter === key ? 'contained' : 'outlined'}
					sx={{ borderRadius: '2rem' }}
				>
					{t(`${appFunction}.orderTypes.${key.toLowerCase()}`)}
				</Button>,
			);
		});
		return (
			<Stack
				direction='row'
				justifyContent='center'
				spacing={1}
				rowGap={1}
				sx={{
					minWidth: 0,
					flexWrap: 'wrap',
				}}
			>
				{buttons}
			</Stack>
		);
	};

	return (
		<TableContextProvider
			values={orderList
				.filter(
					(order) =>
						order.parentId === null &&
						(orderFilter === 'all' || order.orderType === orderFilter) &&
						(showBooked
							? order.status === OrderStatus.Booked
							: order.status !== OrderStatus.Booked),
				)
				.sort((a, b) => b.id - a.id)}
			rowExpansionTemplate={(data) => rowExpansionTemplate(data, 0)}
			pageIdentifier={appFunction}
			customHeader={<Header />}
			highlightId={routeId ? +routeId : undefined}
			actions={[
				{
					label: 'operations.editEad',
					action: (data) => callModifyOrderDialog(+data.id),
					customIcon: <CalendarMonth />,
				},
				{
					label: 'operations.edit',
					customLink: '/orders/modify/:id',
					customIcon: <Edit />,
				},
				{
					label: 'operations.delete',
					action: (data) => console.log('delete', data),
					customIcon: <Delete />,
				},
			]}
			customCreateAction={[
				{
					tooltip: 'order.orderTypes.cut',
					targetUrl: '/inventory/process',
					icon: <Blender />,
				},
				{
					tooltip: 'order.orderTypes.internal',
					targetUrl: '/inventory/move',
					icon: <MoveUp />,
				},
				{
					tooltip: 'order.orderTypes.outbound',
					targetUrl: '/orders/create/sales',
					icon: <PointOfSale />,
				},
				{
					tooltip: 'order.orderTypes.inbound',
					targetUrl: '/orders/create/import',
					icon: <AddBox />,
				},
			]}
		>
			<Column
				expander
				frozen
				style={{
					maxWidth: '4rem',
					display: 'flex',
					justifyContent: 'center',
				}}
			/>
			<Column
				field='code'
				header='code'
				sortable
				body={(data) => <OrderCodeBodyTemplate rowData={data} useIcon={orderFilter === 'all'} />}
			/>
			<Column
				field='createdBy'
				header='createdBy'
				sortable
				body={(data) => <SelectableUserRowBodyTemplate userId={data.userId} />}
			/>
			<Column
				field='orderType'
				header='orderType'
				body={(data) => {
					return <>{t(`${appFunction}.orderTypes.${data.orderType.toLowerCase()}`)}</>;
				}}
			/>
			<Column
				field='shippingType'
				header='shippingType'
				body={(data) => {
					return <>{t(`${appFunction}.shippingTypes.${data.shippingType.toLowerCase()}`)}</>;
				}}
			/>
			<Column
				field='ead'
				header='estimatedTime'
				headerStyle={{ display: 'flex', justifyContent: 'center' }}
				bodyStyle={{ display: 'flex', justifyContent: 'center' }}
				body={eadBody}
			/>
			<Column
				field='status'
				header='status'
				headerStyle={{ display: 'flex', justifyContent: 'center' }}
				style={{ maxWidth: '8rem' }}
				body={orderStatusBody}
			/>
			<Column
				field='pdf'
				header='pdf'
				headerStyle={{ display: 'flex', justifyContent: 'center' }}
				style={{ maxWidth: '6rem' }}
				body={downloadPdfBody}
			/>
		</TableContextProvider>
	);
};

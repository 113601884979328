import { Box, Grid, ToggleButton, Typography, alpha, useTheme } from '@mui/material';
import { useOrderEditor } from '@contexts/index';

import { useTranslation } from 'react-i18next';
import { AppFunction } from 'common';
import useAppFunctions from '@hooks/useAppFunctions';

// TODO: make a new one or anyway fix all the styles
import styles from '../../tabs/TabbedSection.module.css';
import { OrderHeaderCode } from './OrderHeaderCode';
import { OrderHeaderSourceSelect } from './OrderHeaderSourceSelect';
import { OrderHeaderShippingSelect } from './OrderHeaderShippingSelect';
import { OrderHeaderTargetSelect } from './OrderHeaderTargetSelect';

export const OrderHeader = () => {
	const { config, orderType, renderType } = useOrderEditor();
	const { t } = useTranslation();
	const { getIcon } = useAppFunctions();
	const theme = useTheme();

	if (!config || !orderType) {
		return null;
	}

	const icon = getIcon(AppFunction.Order, orderType, true);

	if (config.headerElement) {
		return (
			<Box
				className={styles.header}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: 2,
					height: 'auto',
				}}
			>
				<Grid
					container
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'white',
						padding: renderType === 'standalone' ? '4rem' : '0',
						borderRadius: '2rem',
						boxShadow: `2px 2px 5px 0 ${alpha(theme.palette.primary.main, 0.2)} inset`,
					}}
					rowGap={2}
				>
					<Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						{icon && (
							<div
								style={{
									fontSize: '2rem',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '1rem',
								}}
							>
								{icon}
							</div>
						)}
						<Typography
							variant='h4'
							className={styles.title}
							sx={{
								padding: '1rem',
							}}
						>
							{t(`order.orderTypes.${orderType?.toLowerCase()}`)}
						</Typography>
					</Grid>
					<Grid item xs={12} container spacing={2}>
						<config.headerElement />
					</Grid>
				</Grid>
			</Box>
		);
	} else {
		// TODO: delete the shit out of this
		return (
			<Box
				className={styles.header}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: 2,
					height: 'auto',
				}}
			>
				<Grid
					container
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'white',
						padding: renderType === 'standalone' ? '2rem' : '0',
						borderRadius: '2rem',
						boxShadow: `2px 2px 5px 0 ${alpha(theme.palette.primary.main, 0.2)} inset`,
					}}
				>
					<Grid item xs={12}>
						{/* {icon && (
								<div
									style={{
										fontSize: '3rem',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										padding: '1rem',
									}}
								>
									{icon}
								</div>
							)} */}
						<Typography
							variant='h4'
							className={styles.title}
							sx={{
								padding: '1rem',
							}}
						>
							{t(`order.orderTypes.${orderType?.toLowerCase()}`)}
						</Typography>
					</Grid>
					<Grid item xs={12} container spacing={2}>
						<OrderHeaderCode />
						<OrderHeaderSourceSelect />
						<OrderHeaderShippingSelect />
						<OrderHeaderTargetSelect />
					</Grid>
				</Grid>
			</Box>
		);
	}
};

import { withFormLogic } from '@components/common/hoc/withFormLogic';
import { GeneralInfo } from '@components/forms/locations/GeneralInfo';
import { TabbedElement } from '@components/tabs/TabbedElement';
import { FormSidefabType } from '@components/tabs/contextelements/tabfabs/config';
import { FormAction, FormType } from '@contexts/formContext/types';
import useWarehouses from '@hooks/useWarehouses';
import { Settings } from '@mui/icons-material';
import {
	AppFunction,
	StorageConditionType,
	StorageType,
	WarehouseResponse,
	WarehouseSchema,
} from 'common';
import { useParams } from 'react-router-dom';

interface Props {
	forcedId?: number;
	formAction: FormAction;
}

const LocationFormContent = withFormLogic<WarehouseResponse>(() => <></>);

export const LocationForm = ({ forcedId, formAction }: Props) => {
	const parentId = useParams().parentId ? parseInt(useParams().parentId ?? '-1') : undefined;
	const { warehouseList, createWarehouse, editWarehouse } = useWarehouses();

	return (
		<LocationFormContent
			appFunction={AppFunction.Location}
			forcedId={forcedId}
			formAction={formAction}
			list={warehouseList}
			createFunction={createWarehouse}
			editFunction={editWarehouse}
			formType={FormType.full}
			pagePrefix='settings'
			prefixIcon={<Settings />}
			validationSchema={WarehouseSchema}
			defaultValues={
				{
					id: -1,
					parentId,
					storageCondition: StorageConditionType.ambient,
				} as WarehouseResponse
			}
			hideMenu
			sideFabs={[
				FormSidefabType.Image,
				FormSidefabType.Info,
				FormSidefabType.Address,
				FormSidefabType.Notes,
			]}
			customAfterCreateLink='location/:id/edit'
		>
			{() => (
				<TabbedElement title='warehouse.generalInfo'>
					<GeneralInfo />
				</TabbedElement>
			)}
		</LocationFormContent>
	);
};

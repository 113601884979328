import { useEffect, useRef, useState } from 'react';
import { useOrderEditor } from '@contexts/index';
import useCustomers from '@hooks/useCustomers';
import useLocale from '@hooks/useLocale';
import useOrders from '@hooks/useOrders';
import useProducts from '@hooks/useProducts';
import { CheckBox, CheckBoxOutlineBlank, Info } from '@mui/icons-material';
import { Box, Stack, ToggleButton, Typography, useTheme } from '@mui/material';
import { AppFunction, OrderBaseResponse, OrderStatus, OrderType, TranslationTypes } from 'common';
import { useTranslation } from 'react-i18next';
import { InfoElementProps } from '../config/orderTypeConfig';
import _ from 'lodash';
import moment from 'moment';
import ExpiresAtChip from '@components/common/chips/ExpiresAtChip';

export const BookedOrders = ({ uuid }: InfoElementProps) => {
	const { subOrders, setInfoElementStatus } = useOrderEditor();
	const { variantList } = useProducts();
	const { orderList } = useOrders();
	const { customerList } = useCustomers();
	const { t } = useTranslation();
	const { getTranslatedString } = useLocale();
	const theme = useTheme();

	const [bookedOutboundOrders, setBookedOutboundOrders] = useState<OrderBaseResponse[]>([]);
	const [showAll, setShowAll] = useState(false);

	const ordersDataMap = useRef<Record<number, Record<number, number>>>({});

	useEffect(() => {
		// TODO: right way to do it:
		// 1) cycle through all the suborders
		// 2) for each suborder get the ead
		// 3) create a separate list (we should modify bookedOutboundOrders to be a map)
		// 4) for each suborder, if the ead is between now and ead, add it to the list
		const ead = subOrders?.[0]?.ead;

		const bookedOrders = orderList
			.filter((o) => {
				const show = (): boolean => {
					if (!ead || showAll) {
						return true;
					}

					const now = moment();
					const eadMoment = moment(ead);
					const orderEAD = moment(o.ead);

					// Check if the order ead is within the same week as the given ead
					const sameWeek = orderEAD.isSame(eadMoment, 'week');

					// Check if the ead is between now and the order's ead date
					const inRange = eadMoment.isBetween(now, orderEAD, 'day', '[]');

					return sameWeek && inRange;
				};

				return (
					o.orderType === OrderType.Outbound &&
					o.status === OrderStatus.Booked &&
					o.createdAt !== null &&
					o.createdAt !== undefined &&
					show()
				);
			})
			.sort((a, b) => new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime());

		setBookedOutboundOrders((oldOrders) => {
			if (_.isEqual(oldOrders, bookedOrders)) {
				return oldOrders;
			}
			return bookedOrders;
		});
		setInfoElementStatus(uuid, bookedOrders.length > 0);
	}, [orderList, subOrders, showAll]);

	return (
		<Box
			sx={{
				padding: '1rem',
				backgroundColor: 'white',
				borderRadius: '2rem',
			}}
		>
			<Stack direction='column' spacing={1}>
				<Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
					<ToggleButton
						value='check'
						color='success'
						selected={showAll}
						disabled={subOrders?.[0]?.ead === null || subOrders?.[0]?.ead === undefined}
						onChange={() => setShowAll(!showAll)}
						fullWidth
						size='small'
						sx={{
							mb: '0.5rem',
							opacity: subOrders?.[0]?.ead === null || subOrders?.[0]?.ead === undefined ? 0.5 : 1,
						}}
					>
						{showAll
							? t(`${AppFunction.Order}.infoBox.bookedOrders.showLess`)
							: t(`${AppFunction.Order}.infoBox.bookedOrders.showAll`)}
					</ToggleButton>
					<Box display='flex' alignItems='center' justifyContent='center'>
						<Info sx={{ marginRight: '0.5rem' }} /> {t(`${AppFunction.Order}.hasBookedOrders`)}
					</Box>
				</Box>
				{bookedOutboundOrders.map((o) => {
					const customer = customerList.find((c) => c.id === o.toId);
					if (!customer || !customer.id) {
						return null;
					}
					// TODO: Make into a separate component
					return (
						<Box key={o.id} sx={{ minWidth: '20rem' }}>
							<Box
								sx={{
									padding: '0.5rem',
									textAlign: 'center',
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.primary.contrastText,
									borderRadius: '0.5rem',
									marginBottom: '0.5rem',
								}}
							>
								<Typography>
									{`${getTranslatedString(
										AppFunction.Customer,
										customer.id,
										TranslationTypes.name,
									)}`}
								</Typography>
								<Typography display='flex' alignItems='center' justifyContent='center' gap={1}>
									{t(`${AppFunction.Order}.estimatedTime`)}: <ExpiresAtChip expiresAt={o.ead} />
								</Typography>
							</Box>
							{o.content?.map((op) => {
								const variant = variantList.find((v) => v.id === op.variantId);
								if (!variant || !op.variantId || !variant.productId) {
									return null;
								}

								const reduced = subOrders.reduce((acc, so) => {
									if (so.content) {
										so.content.forEach((soco) => {
											if (soco.variantId === op.variantId) {
												acc += soco.orderQty ?? 0;
											}
										});
									}
									return acc;
								}, 0);

								let previous = 0;
								for (const precedingOrder in ordersDataMap.current) {
									if (Object.prototype.hasOwnProperty.call(ordersDataMap.current, precedingOrder)) {
										if (parseInt(precedingOrder, 10) === o.id) {
											break;
										}
										const currentOrder = ordersDataMap.current[precedingOrder];
										if (currentOrder[op.variantId]) {
											previous += currentOrder[op.variantId];
										}
									}
								}

								const fulfilled = reduced - previous >= (op.orderQty ?? 0);

								ordersDataMap.current[o.id] = {
									...ordersDataMap.current[o.id],
									[op.variantId]: fulfilled ? op.orderQty ?? 0 : reduced,
								};

								return (
									<Box
										key={op.variantId}
										sx={{
											width: '100%',
											color: fulfilled ? theme.palette.success.main : theme.palette.error.main,
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											position: 'relative',
											'&:before': {
												content: '""',
												position: 'absolute',
												height: '2px',
												top: 'calc(50% - 1px)',
												left: '0',
												background: 'black',
												transition: 'width 0.5s',
												width: fulfilled ? '100%' : '0',
												backgroundColor: theme.palette.success.main,
											},
										}}
									>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}
										>
											{fulfilled ? (
												<CheckBox sx={{ marginRight: '0.5rem' }} />
											) : (
												<CheckBoxOutlineBlank sx={{ marginRight: '0.5rem' }} />
											)}
										</div>
										<div
											style={{
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												width: '13rem',
												display: 'flex',
												alignItems: 'left',
												justifyContent: 'flex-start',
											}}
										>
											{`${getTranslatedString(
												AppFunction.Product,
												variant.productId,
												TranslationTypes.name,
											)} ${getTranslatedString(
												AppFunction.Variant,
												op.variantId,
												TranslationTypes.name,
											)}`}
										</div>
										<div
											style={{
												display: 'flex',
												alignItems: 'right',
												justifyContent: 'flex-end',
											}}
										>
											{`${fulfilled ? op.orderQty : reduced - previous} / ${op.orderQty}`}
										</div>
									</Box>
								);
							})}
						</Box>
					);
				})}
			</Stack>
		</Box>
	);
};
